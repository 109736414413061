import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    EActivityModule,
    ECandidateSource,
    ESuggestionStatus,
    IVacancy,
    IVacancyStatistic,
} from '../../../../definitions/entities.definition';
import { ClientApi } from '../../../../requests/ClientApi';
import { getVacancyStatisticRouteConfig } from '../../../../requests/routes';
import { getToken } from '../../../../selectors/app.selectors';
import CustomDashboardTextPaper from '../../../../components/CustomPaper/CustomDashboardTextPaper';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import { EIcons } from '../../../../components/Icons/IconFactory';
import CustomDashboardWidgetPaper from '../../../../components/CustomPaper/CustomDashboardWidgetPaper';
import DateDiffCellRenderer from '../../../../components/CustomCellRenderer/DateDiffCellRenderer';
import { COLOR_GREEN, COLOR_RED } from '../../../../theme/theme';
import MinutesCellRenderer from '../../../../components/CustomCellRenderer/MinutesCellRenderer';
import SuggestionListView from '../../SuggestionListView';
import { setVacancyPageSuggestionListViewOpenAction } from '../../../../redux/vacancyPage/vacancyPage.actions';
import CustomBackdropLoading from '../../../../components/CustomBackdrop/CustomBackdropLoading';
import { Button, ButtonGroup, Link } from '@material-ui/core';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { setRecruitingPageRecruitingListViewOpenAction } from '../../../../redux/recruitingPage/recruitingPage.actions';
import Moment from 'moment';
import SuggestionsByCandidateSourceBarChart from './components/SuggestionsByCandidateSourceBarChart';
import SuggestionsByDeclineReasonBarChart from './components/SuggestionsByDeclineReasonBarChart';
import CandidateSourceDropDown from '../../../../components/CustomInput/CustomDropDownInput/CandidateSourceDropDown';
import EmployeeTimeCellRenderer from '../../../../components/CustomCellRenderer/EmployeeTimeCellRenderer';
import { setUIActivityListViewOpenAction } from '../../../../redux/ui/ui.actions';
import { translate } from '../../../../translation/translate.utils';
import ApplicationsByCandidateSourceBarChart from './components/ApplicationsByCandidateSourceBarChart';
import CustomDialogHeader from '../../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';

interface IProps {
    open: boolean;
    vacancy: IVacancy;
    onClose: () => void
}

const VacancyStatisticView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();

    const token = useSelector(getToken);
    const [loading, setLoading] = useState(false);
    const [statistic, setStatistic] = useState<IVacancyStatistic | undefined>();
    const [timeRange, setTimeRange] = useState<7 | 14 | 30 | undefined>();
    const [declineReasonSource, setDeclineReasonSource] = useState<ECandidateSource | undefined>();

    const reload = useSelector((store: IStore) => store.vacancyPage.reloadSuggestions || store.vacancyPage.reloadRecruitingsOfVacancy);

    const loadStatistic = () => {
        if (!props.open) {
            return;
        }
        setLoading(true);
        ClientApi.request(getVacancyStatisticRouteConfig, {
            token,
            vacancyId: props.vacancy.id,
            timeRange,
            declineReasonSource,
        }).then((result: IVacancyStatistic) => {
            setStatistic(result);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        loadStatistic();
    }, [dispatch, timeRange, declineReasonSource, props.open]);

    useEffect(() => {
        if (!reload) {
            return;
        }
        loadStatistic();
    }, [reload]);

    const today = Moment();
    const date = Moment(props.vacancy.activeSince);
    date.set({hour: 0, minute: 0, second: 0, millisecond: 0});
    const daysSinceCreated = today.diff(date, 'days');

    return (
        <>
            <CustomBackdropLoading open={loading}/>
            <CustomDialog fullWidth open={props.open} onClose={props.onClose}>
                <CustomDialogHeader string={'Statistik für Vakanz ' + props.vacancy.title} onClose={props.onClose}/>
                <SuggestionListView vacancyId={props.vacancy.id}/>
                {daysSinceCreated >= 7 &&
                <div style={{textAlign: 'right', padding: 5}}>
                    Zeitraum:&nbsp;
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Button
                            variant={timeRange === undefined ? 'contained' : 'outlined'}
                            onClick={() => setTimeRange(undefined)}
                        >
                            Gesamt
                        </Button>
                        <Button
                            variant={timeRange === 7 ? 'contained' : 'outlined'}
                            onClick={() => setTimeRange(7)}
                        >
                            7 Tage
                        </Button>
                        {daysSinceCreated >= 14 &&
                        <Button
                            variant={timeRange === 14 ? 'contained' : 'outlined'}
                            onClick={() => setTimeRange(14)}
                        >
                            14 Tage
                        </Button>
                        }
                        {daysSinceCreated >= 30 &&
                        <Button
                            variant={timeRange === 30 ? 'contained' : 'outlined'}
                            onClick={() => setTimeRange(30)}
                        >
                            30 Tage
                        </Button>
                        }
                    </ButtonGroup>
                </div>
                }
                <div style={{padding: 10, height: '100%', width: 'calc(100% - 20px)', overflow: 'auto'}}>
                    <h2>Allgemein</h2>
                    <PaperRow>
                        <div className={'flex flexCentered'}>
                            <CustomDashboardTextPaper
                                icon={props.vacancy.trend === 1 ? EIcons.TrendingUp : props.vacancy.trend === -1 ? EIcons.TrendingDown : EIcons.TrendingFlat}
                                headline={'7-Tage Trend'}
                                design={'light'}
                                iconBackgroundColor={props.vacancy.trend === 1 ? COLOR_GREEN : props.vacancy.trend === -1 ? COLOR_RED : undefined}
                            >
                                {statistic && statistic.suggestionTrend > 0 && '+'}{statistic?.suggestionTrend} %
                            </CustomDashboardTextPaper>
                        </div>
                        <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                            <CustomDashboardTextPaper
                                icon={EIcons.AvTimer}
                                headline={'Aufwand'}
                                design={'light'}
                                onClickAction={() => dispatch(setUIActivityListViewOpenAction({
                                    vacancyId: props.vacancy.id,
                                    timeRange,
                                }))}
                            >
                                <MinutesCellRenderer minutes={statistic?.timeEstimationTotal}/>
                            </CustomDashboardTextPaper>
                        </div>
                        <div className={'flex flexCentered'} style={{marginLeft: 5, marginTop: 30}}>
                            <CustomDashboardTextPaper
                                headline={translate('pages.company.properties.successRate')}
                                design={'light'}
                            >
                                {statistic?.companySuccessRate} %
                            </CustomDashboardTextPaper>
                        </div>
                        <div className={'flex flexCentered'} style={{marginLeft: 5, marginTop: 30}}>
                            <CustomDashboardTextPaper
                                headline={'Beteiligung nach Mitarbeiter'}
                                design={'light'}
                            >
                                <EmployeeTimeCellRenderer times={statistic ? statistic.timePerEmployee : []}
                                                          vacancyId={props.vacancy.id}/>
                            </CustomDashboardTextPaper>
                        </div>
                    </PaperRow>
                    <PaperRow>
                        <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                            <CustomDashboardTextPaper
                                headline={'Betreut seit'}
                                design={'light'}
                            >
                                <DateDiffCellRenderer date={props.vacancy.activeSince} withoutLeadingText/>
                            </CustomDashboardTextPaper>
                        </div>
                        {props.vacancy.webVacancy &&
                        <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                            <CustomDashboardTextPaper
                                headline={'Vom Kunden online seit'}
                                design={'light'}
                            >
                                <DateDiffCellRenderer date={props.vacancy.webVacancy.onlineSince} withoutLeadingText/>
                            </CustomDashboardTextPaper>
                        </div>
                        }
                        <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                            <CustomDashboardTextPaper
                                headline={'Letzte Aktivität'}
                                design={'light'}
                            >
                                <DateDiffCellRenderer date={props.vacancy.lastActivity}/>
                            </CustomDashboardTextPaper>
                        </div>
                    </PaperRow>
                    <h2>Favoriten <Link style={{fontSize: 12}} onClick={() => {
                        dispatch(setVacancyPageSuggestionListViewOpenAction({
                            status: ESuggestionStatus.OPEN,
                        }));
                    }}>[Anzeigen]</Link></h2>
                    <PaperRow>
                        <div className={'flex flexCentered'}>
                            <CustomDashboardTextPaper
                                headline={'Aufwand'}
                                design={'light'}
                                onClickAction={() => dispatch(setUIActivityListViewOpenAction({
                                    vacancyId: props.vacancy.id,
                                    module: EActivityModule.recruitingSuggestion,
                                    timeRange,
                                }))}
                            >
                            <span style={{fontSize: 14}}>
                                Ø: <MinutesCellRenderer minutes={statistic?.timeEstimationAveragePerSuggestion}/>
                            </span>
                                &nbsp;
                                <MinutesCellRenderer minutes={statistic?.timeEstimationTotalOfSuggestions}/>
                            </CustomDashboardTextPaper>
                        </div>
                        {timeRange === undefined ?
                            <>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Aktiv'}
                                        design={'light'}
                                        onClickAction={() => {
                                            dispatch(setVacancyPageSuggestionListViewOpenAction({
                                                status: ESuggestionStatus.OPEN
                                            }));
                                        }}
                                    >
                                        {statistic?.countSuggestionActive}
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Abgelehnt'}
                                        design={'light'}
                                        onClickAction={() => {
                                            dispatch(setVacancyPageSuggestionListViewOpenAction({
                                                status: ESuggestionStatus.DECLINED
                                            }));
                                        }}
                                    >
                                        {statistic?.countSuggestionDeclined}
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Angenommen/im Prozess'}
                                        design={'light'}
                                        onClickAction={() => {
                                            dispatch(setVacancyPageSuggestionListViewOpenAction({
                                                status: ESuggestionStatus.ACCEPTED
                                            }));
                                        }}
                                    >
                                        {statistic?.countRecruitingTotal}
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Gesamt'}
                                        design={'light'}
                                        onClickAction={() => {
                                            dispatch(setVacancyPageSuggestionListViewOpenAction({}));
                                        }}
                                    >
                                        {statistic?.countSuggestionTotal}
                                    </CustomDashboardTextPaper>
                                </div>
                            </>
                            :
                            <>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Hinzugefügt'}
                                        design={'light'}
                                    >
                                        {statistic?.countSuggestionAddedInTimeRange}
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Abgelehnt'}
                                        design={'light'}
                                    >
                                        {statistic?.countSuggestionDeclinedInTimeRange}
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Angenommen/Prozess gestartet'}
                                        design={'light'}
                                    >
                                        {statistic?.countSuggestionAcceptedInTimeRange}
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Gesamt bearbeitet oder kontaktiert'}
                                        design={'light'}
                                    >
                                        {statistic?.countSuggestionContactCountInTimeRange}
                                    </CustomDashboardTextPaper>
                                </div>
                            </>
                        }
                    </PaperRow>
                    <PaperRow>
                        {statistic && statistic.suggestionSources.length > 0 &&
                        <CustomDashboardWidgetPaper design={'light'} headline={'Quellen'}>
                            <SuggestionsByCandidateSourceBarChart
                                chartData={statistic ? statistic.suggestionSources : []}
                                vacancyId={props.vacancy.id}
                            />
                        </CustomDashboardWidgetPaper>
                        }
                        {statistic && statistic.suggestionDeclinedReasons.length > 0 &&
                        <CustomDashboardWidgetPaper
                            design={'light'}
                            headline={'Grund der Absage'}
                            style={{marginLeft: 5}}
                            headlineElement={
                                <div style={{marginTop: -10}}>
                                    <CandidateSourceDropDown
                                        size={'small'}
                                        validValues={statistic.declineReasonSources}
                                        value={declineReasonSource}
                                        onChange={(event) => setDeclineReasonSource(event.target.value)}
                                    />
                                </div>
                            }
                        >
                            <SuggestionsByDeclineReasonBarChart
                                chartData={statistic ? statistic.suggestionDeclinedReasons : []}
                                vacancyId={props.vacancy.id}
                            />
                        </CustomDashboardWidgetPaper>
                        }

                    </PaperRow>
                    <h2>Prozesse <Link style={{fontSize: 12}} onClick={() => {
                        dispatch(setRecruitingPageRecruitingListViewOpenAction({}));
                    }}>[Anzeigen]</Link></h2>
                    {timeRange === undefined ?
                        <>
                            <PaperRow>
                                <div className={'flex flexCentered'}>
                                    <CustomDashboardTextPaper
                                        headline={'Aktive Prozesse'}
                                        design={'light'}
                                    >
                                        {statistic?.countRecruitingActive}
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Vorgeschlagene Kandiaten'}
                                        design={'light'}
                                    >
                                        {statistic?.countRecruitingTotal}
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Interviewte Kandidaten'}
                                        design={'light'}
                                    >
                                        {statistic?.countRecruitingInterview}
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Vermittelte Kandidaten'}
                                        design={'light'}
                                    >
                                        {statistic?.countRecruitingContract}
                                    </CustomDashboardTextPaper>
                                </div>
                            </PaperRow>
                            <PaperRow>
                                <div className={'flex flexCentered'}>
                                    <CustomDashboardTextPaper
                                        headline={'Aufwand'}
                                        design={'light'}
                                        onClickAction={() => dispatch(setUIActivityListViewOpenAction({
                                            vacancyId: props.vacancy.id,
                                            module: EActivityModule.recruiting,
                                            timeRange,
                                        }))}
                                    >
                                <span style={{fontSize: 14}}>
                                    Ø: <MinutesCellRenderer minutes={statistic?.timeEstimationAveragePerRecruiting}/>
                                </span>
                                        &nbsp;
                                        <MinutesCellRenderer minutes={statistic?.timeEstimationTotalOfRecruitings}/>
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Ø-Dauer bis Kunden-Feedback nach Vorschlag'}
                                        design={'light'}
                                    >
                                        {statistic?.companyFeedbackDurationAfterSuggestion} Std.
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Abgelehnt nach Vorschlag'}
                                        design={'light'}
                                    >
                                        {statistic?.countRecruitingDeclinedAfterSuggestion}
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Abgelehnt nach Interview'}
                                        design={'light'}
                                    >
                                        {statistic?.countRecruitingDeclinedAfterInterview}
                                    </CustomDashboardTextPaper>
                                </div>
                            </PaperRow>
                        </>
                        :
                        <>
                            <PaperRow>
                                <div className={'flex flexCentered'}>
                                    <CustomDashboardTextPaper
                                        headline={'Aufwand'}
                                        design={'light'}
                                        onClickAction={() => dispatch(setUIActivityListViewOpenAction({
                                            vacancyId: props.vacancy.id,
                                            module: EActivityModule.recruiting,
                                            timeRange,
                                        }))}
                                    >
                                <span style={{fontSize: 14}}>
                                    Ø: <MinutesCellRenderer minutes={statistic?.timeEstimationAveragePerRecruiting}/>
                                </span>
                                        &nbsp;
                                        <MinutesCellRenderer minutes={statistic?.timeEstimationTotalOfRecruitings}/>
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Vorgeschlagene Kandiaten'}
                                        design={'light'}
                                    >
                                        {statistic?.countRecruitingAddedInTimeRange}
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Interviewte Kandidaten'}
                                        design={'light'}
                                    >
                                        {statistic?.countRecruitingInterviewedInTimeRange}
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Vermittelte Kandidaten'}
                                        design={'light'}
                                    >
                                        {statistic?.countRecruitingContractedInTimeRange}
                                    </CustomDashboardTextPaper>
                                </div>
                                <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                                    <CustomDashboardTextPaper
                                        headline={'Abgelehnte Kandidaten'}
                                        design={'light'}
                                    >
                                        {statistic?.countRecruitingDeclinedInTimeRange}
                                    </CustomDashboardTextPaper>
                                </div>
                            </PaperRow>
                        </>
                    }

                    <h2>Bewerbungen <Link style={{fontSize: 12}} onClick={() => {
                        dispatch(setRecruitingPageRecruitingListViewOpenAction({}));
                    }}>[Anzeigen]</Link></h2>
                    <PaperRow>
                        <div className={'flex flexCentered'}>
                            <CustomDashboardTextPaper
                                headline={'Bewerbungen'}
                                design={'light'}
                            >
                                {statistic?.countApplicationTotal}
                            </CustomDashboardTextPaper>
                        </div>
                        <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                            <CustomDashboardTextPaper
                                headline={'Aktive Bewerbungen'}
                                design={'light'}
                            >
                                {statistic?.countApplicationActive}
                            </CustomDashboardTextPaper>
                        </div>
                        <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                            <CustomDashboardTextPaper
                                headline={'Abgelehnte Bewerbungen'}
                                design={'light'}
                            >
                                {statistic?.countApplicationDeclined}
                            </CustomDashboardTextPaper>
                        </div>
                        <div className={'flex flexCentered'} style={{marginLeft: 5}}>
                            <CustomDashboardTextPaper
                                headline={'Akzeptierte Bewerbungen'}
                                design={'light'}
                            >
                                {statistic?.countApplicationAccepted}
                            </CustomDashboardTextPaper>
                        </div>
                    </PaperRow>
                    {statistic && statistic.applicationSources.length > 0 &&
                    <PaperRow>
                        <CustomDashboardWidgetPaper design={'light'} headline={'Quellen'}>
                            <ApplicationsByCandidateSourceBarChart
                                chartData={statistic ? statistic.applicationSources : []}
                                vacancyId={props.vacancy.id}
                            />
                        </CustomDashboardWidgetPaper>
                        <div className={'flex flexCentered'}>&nbsp;</div>
                    </PaperRow>
                    }
                </div>
                <CustomDialogActions
                    onClose={props.onClose}
                />
            </CustomDialog>
        </>
    );
};

export default VacancyStatisticView;

import { createStyles, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomMenu from '../../../CustomMenu/CustomMenu';
import CustomButton from '../../../CustomInput/CustomButton';
import ActivityTypeDropDown from '../../../CustomInput/CustomDropDownInput/ActivityTypeDropDown';
import {
    resetActivityPageFilterAction,
    setActivityPageFilterAction,
    setActivityPageFilterMenuAction,
} from '../../../../redux/activityOverviewPage/activityOverviewPage.actions';
import { translate } from '../../../../translation/translate.utils';
import LegacyEmployeeAutocompleteDropDown
    from '../../../CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import { IEmployee } from '../../../../definitions/entities.definition';
import CustomDatePicker from '../../../CustomInput/CustomDatePicker';
import moment from 'moment';

interface IProps {
    anchorEl: null | any;
    removeAnchor: VoidFunction;
}

const useStyles = makeStyles(
    createStyles({
        customWidth: {
            "& ul": {
                minWidth: "500px",
            },
        },
    })
);

const CustomFilterMenuActivity: React.FC<IProps> = (props) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((store: IStore) => store.activityPage.filter);
    const filterMenu = useSelector((store: IStore) => store.activityPage.filterMenu);

    const submit = () => {
        dispatch(
            setActivityPageFilterAction({
                fastSearch: filter.fastSearch,
                start: filter.start,
                limit: filter.limit,
                employeeId: filterMenu.employee?.id,
                ...filterMenu,
            })
        );
        props.removeAnchor();
    };

    return (
        <CustomMenu className={styles.customWidth} anchorEl={props.anchorEl} removeAnchor={props.removeAnchor}>
            <MenuItem className={"gap5"}>
                <CustomButton onClick={submit} label={translate("misc.buttons.applyFilters")} />
                <CustomButton
                    color="secondary"
                    onClick={() => {
                        dispatch(resetActivityPageFilterAction());
                    }}
                    label={translate("misc.buttons.clearFilters")}
                />
            </MenuItem>
            <MenuItem>
                <LegacyEmployeeAutocompleteDropDown
                    label={translate('pages.vacancy.properties.responsibleEmployee')}
                    value={filterMenu.employee}
                    onChange={(event, value: IEmployee) =>
                        dispatch(
                            setActivityPageFilterMenuAction({
                                employee: value
                            })
                        )
                    }
                />
            </MenuItem>
            <MenuItem>
                <ActivityTypeDropDown
                    value={filterMenu.type}
                    disableSystemInfo={false}
                    onChange={(event: any) =>
                        dispatch(
                            setActivityPageFilterMenuAction({
                                type: event.target.value,
                            })
                        )
                    }
                />
            </MenuItem>
            <MenuItem className={"gap5"}>
                <CustomDatePicker
                    flex
                    label={translate('pages.activity.filter.dateFrom')}
                    value={filterMenu.dateFrom}
                    onChange={(value) => dispatch(setActivityPageFilterMenuAction({ dateFrom: value ? moment(value).format('YYYY-MM-DD') : value }))}
                />
                <CustomDatePicker
                    flex
                    label={translate('pages.activity.filter.dateTo')}
                    value={filterMenu.dateTo}
                    onChange={(value) => dispatch(setActivityPageFilterMenuAction({ dateTo: value ? moment(value).format('YYYY-MM-DD') : value }))}
                />
            </MenuItem>
        </CustomMenu>
    );
};

export default CustomFilterMenuActivity;

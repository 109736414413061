import React, { useEffect, useState } from 'react';
import { EIcons } from '../../../Icons/IconFactory';
import CustomListSection from '../CustomSidePanelList/CustomListSection';
import CustomList from '../CustomSidePanelList/CustomList';
import { ISkill } from '../../../../definitions/entities.definition';
import { useDispatch } from 'react-redux';
import { ISorting } from '../../../../definitions/app.definitions';
import { ESortingOptions } from '../../../../definitions/components.definitions';
import { ICandidateFilterApiProperties, ICandidateFilterMenu } from '../../../../definitions/candidatePage.definitions';
import ComplexeSkillFilter from '../Filter/ComplexeSkillFilter';
import DistanceToCompanyFilter from '../Filter/DistanceToCompanyFilter';
import SalutationFilter from '../Filter/SalutationFilter';
import { getFilterByFilterMenu } from '../../../../utils/candidate.utils';
import SourceFilter from '../Filter/SourceFilter';
import WorkExperienceFilter from '../Filter/WorkExperienceFilter';
import BoolFilter from '../Filter/Abstract/BoolFilter';
import NumberRangeFilter from '../Filter/Abstract/NumberRangeFilter';
import { translate } from '../../../../translation/translate.utils';
import TextFilter from '../Filter/Abstract/TextFilter';
import EmployeeFilter from '../Filter/EmployeeFilter';
import { IDateRangeFilterApiProperties } from '../../../../definitions/filter.definitions';
import { objectsAreEqual } from '../../../../utils/application.utils';
import RecruitingInformationFilter from '../Filter/RecruitingInformationFilter';
import HomeOfficeDesireFilter from '../Filter/HomeOfficeDesireFilter';
import HasDocumentsFilter from '../Filter/HasDocumentsFilter';

interface IProps {
    filterMenuInStore: ICandidateFilterMenu;
    setFilterMenuInStore: (filterMenu: ICandidateFilterMenu) => void;
    currentSorting: ISorting;
    filter: ICandidateFilterApiProperties;
    setFilterAction: (filter: ICandidateFilterApiProperties, sorting?: ISorting) => void;
    companyId?: number;
    initialFilterMenu: ICandidateFilterMenu;
    initialDateRangeFilter: IDateRangeFilterApiProperties;
}

const BaseCandidateFilterPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const filterMenuInStore = props.filterMenuInStore;
    const currentSorting = props.currentSorting;
    const setFilterAction = props.setFilterAction;
    const filter = props.filter;
    const companyId = props.companyId;
    const initialFilterMenu = props.initialFilterMenu;
    const initialDateRangeFilter = props.initialDateRangeFilter;

    const [filterMenu, setFilterMenu] = useState(filterMenuInStore);
    const [executeSubmit, setExecuteSubmit] = useState(false);

    const submit = () => {
        let sorting: ISorting = currentSorting;

        if (filterMenu.optionalSkills && filterMenu.optionalSkills.length > 0) {
            sorting = {
                matchedOptionalSkillIds: ESortingOptions.Descending
            };
        }

        dispatch(props.setFilterMenuInStore(filterMenu));
        dispatch(
            setFilterAction({
                ...filter,
                ...getFilterByFilterMenu(filterMenu)
            }, sorting)
        );
    };

    useEffect(() => {
        if (executeSubmit) {
            submit();
            setExecuteSubmit(false);
        }
    }, [executeSubmit])

    const reset = () => {
        setFilterMenu(initialFilterMenu);
        setExecuteSubmit(true);
    };

    const resetDateRange = () => {
        setFilterMenu({
            ...filterMenu,
            dateRangeFilter: initialDateRangeFilter
        });
    };

    const dateRangeFilter = filter.dateRangeFilter;
    const resetEnabled = !objectsAreEqual(filterMenu, initialFilterMenu);
    const resetDateRangeEnabled = !objectsAreEqual(dateRangeFilter, initialDateRangeFilter);

    return (
        <>
            <CustomList
                filterSearchEnabled reset={reset}
                resetEnabled={resetEnabled}
                dateRangeConfig={{
                    setFilter: (dateRangeFilter: IDateRangeFilterApiProperties) => {
                        setFilterMenu({
                            ...filterMenu,
                            dateRangeFilter
                        });
                        setExecuteSubmit(true);
                    },
                    filter: dateRangeFilter,
                    filterByFields: [{
                        label: 'Erstellt am',
                        property: 'created'
                    }, {
                        label: 'Letzte Aktivität am',
                        property: 'lastActivity'
                    }],
                    resetEnabled: resetDateRangeEnabled,
                    reset: resetDateRange
                }}
            >
                <CustomListSection header={"Basis-Filter"}>
                    <HasDocumentsFilter value={filterMenu.hasDocuments} onChange={(hasDocuments) => {
                        setFilterMenu({
                            ...filterMenu,
                            hasDocuments
                        });
                        setExecuteSubmit(true);
                    }}/>
                    <ComplexeSkillFilter
                        skills={filterMenu.skills}
                        optionalSkills={filterMenu.optionalSkills}
                        onChange={(skills, optionalSkills) => {
                            setFilterMenu({
                                ...filterMenu,
                                skills: skills as ISkill[],
                                optionalSkills: optionalSkills as ISkill[]
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <DistanceToCompanyFilter
                        companyLocation={filterMenu.distanceSearchCompanyLocation}
                        distance={filterMenu.distanceSearchKm}
                        onChange={(companyLocation, distance) => {
                            setFilterMenu({
                                ...filterMenu,
                                distanceSearchCompanyLocation: companyLocation,
                                distanceSearchKm: distance
                            });
                            setExecuteSubmit(true);
                        }}
                        companyId={companyId}
                    />
                    {/*
                    <LanguageFilter
                        value={filterMenu.languages}
                        onChange={(languages) => {
                            setFilterMenu({
                                ...filterMenu,
                                languages
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    */}
                    <SalutationFilter
                        value={filterMenu.salutation}
                        onChange={(salutation) => {
                            setFilterMenu({
                                ...filterMenu,
                                salutation
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <NumberRangeFilter
                        title={"Alter"}
                        icon={EIcons.Today}
                        fromLabel={translate('pages.candidate.filter.ageFrom')}
                        toLabel={translate('pages.candidate.filter.ageTo')}
                        valueLabel={"Jahre"}
                        from={filterMenu.ageFrom}
                        to={filterMenu.ageTo}
                        onChange={(ageFrom, ageTo) => {
                            setFilterMenu({
                                ...filterMenu,
                                ageFrom,
                                ageTo
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <HomeOfficeDesireFilter
                        value={filterMenu.homeOfficeDesire}
                        onChange={(homeOfficeDesire) => {
                            setFilterMenu({
                                ...filterMenu,
                                homeOfficeDesire
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <EmployeeFilter
                        value={filterMenu.responsibleEmployee}
                        onChange={(responsibleEmployee) => {
                            setFilterMenu({
                                ...filterMenu,
                                responsibleEmployee,
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <WorkExperienceFilter
                        value={filterMenu.yearsOfWorkExperience}
                        onChange={(yearsOfWorkExperience) => {
                            setFilterMenu({
                                ...filterMenu,
                                yearsOfWorkExperience
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <SourceFilter
                        value={filterMenu.source}
                        onChange={(source) => {
                            setFilterMenu({
                                ...filterMenu,
                                source
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <NumberRangeFilter
                        title={"Gehaltsvorstellung"}
                        icon={EIcons.Money}
                        fromLabel={translate('pages.candidate.salaryFrom')}
                        toLabel={translate('pages.candidate.salaryTo')}
                        valueLabel={"k €"}
                        from={filterMenu.salaryFrom}
                        to={filterMenu.salaryTo}
                        onChange={(salaryFrom, salaryTo) => {
                            setFilterMenu({
                                ...filterMenu,
                                salaryFrom,
                                salaryTo
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <TextFilter
                        icon={EIcons.TextFields}
                        title={translate('pages.candidate.properties.currentJobDescription')}
                        value={filterMenu.jobDescription}
                        onChange={(value) => {
                            setFilterMenu({
                                ...filterMenu,
                                jobDescription: value,
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                </CustomListSection>
                <CustomListSection header={"Aktivitäten-Filter"}>
                    <RecruitingInformationFilter
                        filter={filterMenu.recruitingFilter || {}}
                        setFilter={(recruitingFilter) => {
                            setFilterMenu({
                                ...filterMenu,
                                recruitingFilter,
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                </CustomListSection>
                <CustomListSection header={"Ja/Nein-Filter"}>
                    <BoolFilter
                        title={"Nur Wiedervorlagen"}
                        icon={EIcons.AvTimer}
                        active={filterMenu.onlyFollowups}
                        setActive={(active) => {
                            setFilterMenu({
                                ...filterMenu,
                                onlyFollowups: active,
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <BoolFilter
                        title={"Nur Top-Kandidat"}
                        icon={EIcons.Star}
                        active={filterMenu.topCandidate || false}
                        setActive={(active) => {
                            setFilterMenu({
                                ...filterMenu,
                                topCandidate: active,
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <BoolFilter
                        title={"Nur mit guter Verbindung"}
                        icon={EIcons.EmojiEmotions}
                        active={filterMenu.topInCommunication || false}
                        setActive={(active) => {
                            setFilterMenu({
                                ...filterMenu,
                                topInCommunication: active,
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <BoolFilter
                        title={"Nur Freelancer"}
                        icon={EIcons.FolderShared}
                        active={filterMenu.isFreelancer === true}
                        setActive={(active) => {
                            setFilterMenu({
                                ...filterMenu,
                                isFreelancer: active ? true : undefined,
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <BoolFilter
                        title={"Nur nicht Freelancer"}
                        icon={EIcons.FolderShared}
                        active={filterMenu.isFreelancer === false}
                        setActive={(active) => {
                            setFilterMenu({
                                ...filterMenu,
                                isFreelancer: active ? false : undefined,
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <BoolFilter
                        title={"Nur mit Führerschein"}
                        icon={EIcons.DriveEta}
                        active={filterMenu.hasDriverLicense === true}
                        setActive={(active) => {
                            setFilterMenu({
                                ...filterMenu,
                                hasDriverLicense: active ? true : undefined,
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <BoolFilter
                        title={"Nur Führungskräfte"}
                        icon={EIcons.Supervisor}
                        active={filterMenu.leader === true}
                        setActive={(active) => {
                            setFilterMenu({
                                ...filterMenu,
                                leader: active ? true : undefined,
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                </CustomListSection>
            </CustomList>
        </>
    );
}

export default React.memo(BaseCandidateFilterPanel);

import {
    IEntitiesByCandidateId,
    IEntitiesByCompanyId,
    IEntitiesByEmployeeId,
    IEntitiesByEntityId,
    IEntitiesByRecruitingId,
    IEntitiesBySuggestionId,
    IEntitiesByTodoId,
    IEntitiesByVacancyId,
    TBrainzEntity,
} from '../definitions/entities.definition';

export const mapEntitiesToId = (entities: any[]) => {
    if (!entities || entities.length < 1) {
        return [];
    }

    return entities.reduce((accumulator, currentEntity) => {
        accumulator = {
            ...accumulator,
            [currentEntity.id]: currentEntity,
        };

        return accumulator;
    }, {});
};

export const getChildEntitiesOfParentEntityArray = (entities: any[], childProperty: string) => {
    return entities.reduce((accumulator: TBrainzEntity[], currentEntry) => {
        if (!currentEntry[childProperty]) {
            return [
                ...accumulator
            ];
        }
        return [
            ...accumulator,
            ...currentEntry[childProperty]
        ];
    }, []);
}
export const getChildTotalAndMapToParentId = (entities: any[], childProperty: string) => {
    if (!entities || entities.length < 1) {
        return {};
    }
    return entities.reduce((accumulator: {[entityId: number]: number}, currentEntity) => {
        if (!currentEntity[childProperty]) {
            return { ...accumulator};
        }

        const currentVacancyId = currentEntity.id;
        const result = {
            [currentVacancyId]: currentEntity[childProperty].length,
        };

        return { ...accumulator, ...result };
    }, {});
}


export const getChildIdsAndMapToParentId = (entities: any[], childProperty: string) => {
    if (!entities || entities.length < 1) {
        return {};
    }

    return entities.reduce((accumulator: IEntitiesByEntityId, currentEntity) => {
        if (!currentEntity[childProperty]) {
            return { ...accumulator};
        }

        const currentVacancyId = currentEntity.id;
        const previousIds = accumulator[currentVacancyId] || [];
        const result = {
            [currentVacancyId]: [
                ...previousIds,
                ...currentEntity[childProperty].map((child: TBrainzEntity) => child.id)
            ],
        };

        return { ...accumulator, ...result };
    }, {});
}

export const mapEntityIdsToCompanyId = (entities: any[]) => {
    if (!entities || entities.length < 1) {
        return {};
    }

    return entities.reduce((accumulator: IEntitiesByCompanyId, currentEntity) => {
        if (!currentEntity.company) {
            return { ...accumulator};
        }

        const currentCompanyId = currentEntity.company.id;
        const previousCompanyIds = accumulator[currentCompanyId] || [];
        const parentCompanyId = currentEntity.company.parentCompany ? currentEntity.company.parentCompany.id : undefined;
        const previousParentCompanyIds = currentEntity.company.parentCompany ? accumulator[parentCompanyId] || [] : [];

        if (parentCompanyId) {
            const result = {
                [currentCompanyId]: [...previousCompanyIds, currentEntity.id],
                [parentCompanyId]: [...previousParentCompanyIds, currentEntity.id]
            };

            return { ...accumulator, ...result };
        }

        const result = {
            [currentCompanyId]: [...previousCompanyIds, currentEntity.id],
        };

        return { ...accumulator, ...result };
    }, {});
};

export const mapEntityIdsToCompanyContactId = (entities: any[]) => {
    if (!entities || entities.length < 1) {
        return {};
    }

    return entities.reduce((accumulator: IEntitiesByCompanyId, currentEntity) => {
        if (!currentEntity.companyContact && !currentEntity.responsibleContact) {
            return { ...accumulator};
        }

        const currentCompanyId = currentEntity.companyContact ? currentEntity.companyContact.id : currentEntity.responsibleContact.id;
        const previousCompanyIds = accumulator[currentCompanyId] || [];

        const result = {
            [currentCompanyId]: [...previousCompanyIds, currentEntity.id],
        };

        return { ...accumulator, ...result };
    }, {});
};

export const mapEntityIdsToCandidateId = (entities: any[]) => {
    if (!entities || entities.length < 1) {
        return {};
    }

    return entities.reduce((accumulator: IEntitiesByCandidateId, currentEntity) => {
        if (!currentEntity.candidate) {
            return { ...accumulator};
        }

        const currentCandidateId = currentEntity.candidate.id;
        const previousActivityIds = accumulator[currentCandidateId] || [];
        const result = {
            [currentCandidateId]: [...previousActivityIds, currentEntity.id],
        };

        return { ...accumulator, ...result };
    }, {});
};

export const mapEntityIdsToVacancyId = (entities: any[]) => {
    if (!entities || entities.length < 1) {
        return {};
    }

    return entities.reduce((accumulator: IEntitiesByVacancyId, currentEntity) => {
        if (!currentEntity.vacancy) {
            return { ...accumulator};
        }

        const currentVacancyId = currentEntity.vacancy.id;
        const previousActivityIds = accumulator[currentVacancyId] || [];
        const result = {
            [currentVacancyId]: [...previousActivityIds, currentEntity.id],
        };

        return { ...accumulator, ...result };
    }, {});
};

export const mapEntityIdsToWebVacancyId = (entities: any[]) => {
    if (!entities || entities.length < 1) {
        return {};
    }

    return entities.reduce((accumulator: IEntitiesByVacancyId, currentEntity) => {
        if (!currentEntity.webVacancy) {
            return { ...accumulator};
        }

        const currentVacancyId = currentEntity.webVacancy.id;
        const previousActivityIds = accumulator[currentVacancyId] || [];
        const result = {
            [currentVacancyId]: [...previousActivityIds, currentEntity.id],
        };

        return { ...accumulator, ...result };
    }, {});
};

export const mapEntityIdsToEmployeeId = (entities: any[]) => {
    if (!entities || entities.length < 1) {
        return {};
    }

    return entities.reduce((accumulator: IEntitiesByEmployeeId, currentEntity) => {
        if (!currentEntity.employee && !currentEntity.responsibleEmployee) {
            return { ...accumulator};
        }

        const currentEmployeeId = currentEntity.employee ? currentEntity.employee.id : currentEntity.responsibleEmployee.id;
        const previousEntityIds = accumulator[currentEmployeeId] || [];
        const result = {
            [currentEmployeeId]: [...previousEntityIds, currentEntity.id],
        };

        return { ...accumulator, ...result };
    }, {});
};

export const mapEntityIdsToRecruitingId = (entities: any[]) => {
    if (!entities || entities.length < 1) {
        return {};
    }

    return entities.reduce((accumulator: IEntitiesByRecruitingId, currentEntity) => {
        if (!currentEntity.recruiting && !currentEntity.recruitingId) {
            return { ...accumulator};
        }

        const currentRecruitingId = currentEntity.recruiting ? currentEntity.recruiting.id : currentEntity.recruitingId;
        const previousIds = accumulator[currentRecruitingId] || [];
        const result = {
            [currentRecruitingId]: [...previousIds, currentEntity.id],
        };

        return { ...accumulator, ...result };
    }, {});
};

export const mapEntityIdsToSuggestionId = (entities: any[]) => {
    if (!entities || entities.length < 1) {
        return {};
    }

    return entities.reduce((accumulator: IEntitiesBySuggestionId, currentEntity) => {
        if (!currentEntity.recruitingSuggestion) {
            return { ...accumulator };
        }

        const currentSuggestionId = currentEntity.recruitingSuggestion.id;
        const previousActivityIds = accumulator[currentSuggestionId] || [];
        const result = {
            [currentSuggestionId]: [...previousActivityIds, currentEntity.id],
        };

        return { ...accumulator, ...result };
    }, {});
};

export const mapEntityIdsToApplicationId = (entities: any[]) => {
    if (!entities || entities.length < 1) {
        return {};
    }

    return entities.reduce((accumulator: IEntitiesBySuggestionId, currentEntity) => {
        if (!currentEntity.application) {
            return { ...accumulator };
        }

        const currentSuggestionId = currentEntity.application.id;
        const previousActivityIds = accumulator[currentSuggestionId] || [];
        const result = {
            [currentSuggestionId]: [...previousActivityIds, currentEntity.id],
        };

        return { ...accumulator, ...result };
    }, {});
};


export const mapEntityIdsToTodoId = (entities: any[]) => {
    if (!entities || entities.length < 1) {
        return {};
    }

    return entities.reduce((accumulator: IEntitiesByTodoId, currentEntity) => {
        if (!currentEntity.todo) {
            return { ...accumulator };
        }

        const currentTodoId = currentEntity.todo.id;
        const previousActivityIds = accumulator[currentTodoId] || [];
        const result = {
            [currentTodoId]: [...previousActivityIds, currentEntity.id],
        };

        return { ...accumulator, ...result };
    }, {});
};


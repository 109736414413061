import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomDeleteDialog from '../../../components/CustomDialog/CustomDeleteDialog';
import { getToken } from '../../../selectors/app.selectors';
import { deleteVacancyAction } from '../../../redux/entities/entities.actions';
import { setUiCloseView } from '../../../redux/ui/ui.actions';
import { EEntityView } from '../../../definitions/ui.definitions';
import { setVacancyPageVacancyToDeleteAction } from '../../../redux/vacancyPage/vacancyPage.actions';

const VacancyDeleteView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.vacancyPage.vacancyToDelete));
    const idToDelete = useSelector((store: IStore) => store.vacancyPage.vacancyToDelete);
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    if (!open) {
        return null;
    }

    return (
        <CustomDeleteDialog
            open={open}
            onClose={() => dispatch(setVacancyPageVacancyToDeleteAction(0))}
            onDelete={() => {
                dispatch(deleteVacancyAction(token, idToDelete));
                dispatch(setUiCloseView(EEntityView.vacancy, idToDelete));
                dispatch(setVacancyPageVacancyToDeleteAction(0));
            }}
            titleTranslation={"pages.vacancy.deleteDialog.title"}
            bodyTranslation={"pages.vacancy.deleteDialog.body"}
        />
    );
};

export default VacancyDeleteView;

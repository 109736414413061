import React, { useEffect, useState } from 'react';
import styles from './MailElement.module.css';
import { getFormattedTimeString } from '../../../../utils/date.utils';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../selectors/app.selectors';
import {
    getMailAction,
    markMailboxMailAsReadAction,
    putMailAction,
} from '../../../../redux/entities/entities.actions';
import MailParticipantRenderer from './MailParticipantRenderer';
import { Divider, IconButton, Tooltip } from '@material-ui/core';
import AssignMailView from './AssignMailView';
import { setShowMessageAction } from '../../../../redux/mailing/mailing.actions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomBackdropLoading from '../../../../components/CustomBackdrop/CustomBackdropLoading';
import { COLOR_RED } from '../../../../theme/theme';
import MailElementRelation from './MailElementRelation';

interface IProps {
    id: number;
    showMessage?: boolean;
}

const MailElement: React.FC<IProps> = (props) => {
    const id = props.id;
    const showMessage = props.showMessage || false;
    const mail = useSelector((store: IStore) => store.entities.mail.byId[id]);
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const [assignMailId, setAssignMailId] = useState(0);

    useEffect(() => {
        if (!mail || (mail && showMessage && !mail.message)) {
            dispatch(getMailAction(token, id));
        }
    }, [])

    const onClick = () => {
        if (showMessage) {
            dispatch(setShowMessageAction(0));
            return;
        }
        dispatch(setShowMessageAction(mail.id));
        if (!mail.read) {
            dispatch(markMailboxMailAsReadAction(token, mail.mailboxMailId));
        }
    }

    if (!mail) {
        return <><CustomBackdropLoading open/></>
    }

    return (
        <>
            <AssignMailView mailId={assignMailId} open={Boolean(assignMailId)} onClose={() => setAssignMailId(0)} />
            <div role="mail" className={styles.root}>
                <div className={clsx({
                    [styles.rootUnread]: !mail.read
                })} onClick={onClick}>
                    <div className={"flexContainerRow " + styles.header}>
                        <div className={"flexContainerColumn flex1"}>
                            <div className={"flexContainerRow flex1 alignVerticalCenter"}>
                                <span style={{width: 35}}>Von:</span>
                                <MailParticipantRenderer mailAddress={mail.from} />
                            </div>
                            {mail.to.length > 0 &&
                                <div className={"flexContainerRow flex1 alignVerticalCenter"}>
                                    <span style={{width: 35}}>An:</span>
                                    {mail.to.map((mailParticipant) => <MailParticipantRenderer
                                    key={mailParticipant.mailAddress} mailAddress={mailParticipant}/>)}
                                </div>
                            }
                            {mail.cc.length > 0 &&
                                <div className={"flexContainerRow flex1 alignVerticalCenter"}>
                                    <span style={{width: 35}}>CC:</span>
                                    {mail.cc.map((mailParticipant) => <MailParticipantRenderer key={mailParticipant.mailAddress} mailAddress={mailParticipant} />)}
                                </div>
                            }
                        </div>
                        <div className={styles.headerDate}>
                            {mail.hasAttachments && IconFactory.getIconComponent(EIcons.Attachment)}&nbsp;
                            {getFormattedTimeString(mail.dateTime)}
                        </div>
                    </div>
                    <Divider/>
                    <div className={styles.innerRoot} style={{userSelect: 'text' }}>
                        Betreff: {mail.subject}
                        {mail.flagForDebug && <> ({mail.id})</>}
                    </div>
                    {showMessage &&
                        <>
                            <Divider/>
                            <div className={styles.message} style={{userSelect: 'text', color: 'black' }}>
                                {(!mail.message) && <>Lädt Nachricht... </>}
                                <div className="content" dangerouslySetInnerHTML={{__html: mail.message}} />
                            </div>
                        </>
                    }
                    <Divider/>
                    <div className={"flexContainerRow margin5 padding5 gap5 alignVerticalCenter flexWrap"}>
                            Verknüpfungen: <br/>
                            {mail.relations.map((relation) => {
                                return <div key={relation.id} className={"flexContainerRow"} style={mail.flagForDebug && !relation.autoAssigned ? {fontWeight: 'bold'} : {}}>
                                    <MailElementRelation key={relation.id} relation={relation} />
                                </div>
                            })}
                        <div className={"alignHorizontalRight"}>
                        <Tooltip title={"E-Mail für Daniel zur Prüfung markieren"}>
                            <IconButton
                                onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    dispatch(putMailAction(token, mail.id, !mail.flagForDebug));
                                }}
                                size={"small"}
                                className={"alignHorizontalRight"}
                            >
                                {mail.flagForDebug ? IconFactory.getIconComponent(EIcons.BugReport, {color: COLOR_RED}) : IconFactory.getIconComponent(EIcons.BugReport)}
                            </IconButton>
                        </Tooltip>
                        <IconButton
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setAssignMailId(mail.id)
                            }}
                            size={"small"}
                            className={"alignHorizontalRight"}
                        >
                            {IconFactory.getIconComponent(EIcons.Add)}
                        </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MailElement;

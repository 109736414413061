import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { EEntities } from '../../../../definitions/entities.definition';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { translate } from '../../../../translation/translate.utils';
import DateCellRenderer from '../../../../components/CustomCellRenderer/DateCellRenderer';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { setUiEditView } from '../../../../redux/ui/ui.actions';
import { setCompanyPageCompanyContactToDeleteAction } from '../../../../redux/companyPage/companyPage.actions';
import { getCompanyContactsWithBirthdayFromStore } from '../../../../selectors/companyContacts.selectors';
import { getContactsWithBirthdayAction } from '../../../../redux/entities/entities.actions';
import { getToken } from '../../../../selectors/app.selectors';
import { ESortingOptions } from '../../../../definitions/components.definitions';
import { setDashboardPageSortingOptionAction } from '../../../../redux/dashboardPage/dashboardPage.actions';
import { EDashboardPageTable } from '../../../../definitions/dashboardPage.definitions';
import { buildRequestObject } from '../../../../selectors/entity.selectors';
import { getDefaultCompanyColumn, getDefaultCreatedColumn } from '../../../../utils/components.utils';
import { EEntityView } from '../../../../definitions/ui.definitions';

const BirthdayCompanyContactsTable: React.FC = () => {
    const dispatch = useDispatch();
    const sortedEntries = useSelector(getCompanyContactsWithBirthdayFromStore);
    const loading = useSelector((store: IStore) => store.ui.companyContactsBirthdayLoading);
    const token = useSelector(getToken);
    const sorting = useSelector((store: IStore) => store.dashboardPage.sortingContactPersonBirthdays);

    return (
        <CustomTable
            config={{
                entity: EEntities.CompanyContactBirthday,
                sortedEntries: sortedEntries,
                columnConfig: {
                    name: {
                        header: translate('pages.companyContact.properties.name'),
                        property: "name",
                    },
                    firstName: {
                        header: translate('pages.companyContact.properties.firstName'),
                        property: "firstName",
                    },
                    birthDate: {
                        header: translate('pages.candidate.properties.birthDate'),
                        property: "birthDate",
                        cellRenderer: (birthdate: string) => {
                            return <DateCellRenderer date={birthdate} />;
                        },
                    },
                    company: getDefaultCompanyColumn(),
                    phoneNumber: {
                        header: translate('misc.phoneNumber'),
                        property: "phoneNumber",
                    },
                    created: getDefaultCreatedColumn(),
                },
                loading: loading,
                count: sortedEntries.length,
                total: sortedEntries.length,
                onReload: () => {
                    dispatch(getContactsWithBirthdayAction(token, buildRequestObject(sorting)));
                },
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) => {
                        dispatch(setDashboardPageSortingOptionAction(
                            EDashboardPageTable.contactPersonBirthdays,
                            propertyToSort,
                            sortOption
                        ));
                        return;
                    }
                },
                listActionButtons: [
                    {
                        action: (contactId: number) => dispatch(setCompanyPageCompanyContactToDeleteAction(contactId)),
                        translationKey: "tables.openDeleteView",
                        icon: EIcons.Delete,
                    },
                ],
                onRowDoubleClick: (contactId: number) => dispatch(setUiEditView(EEntityView.companyContact, contactId)),
            }}
        />
    );
}

export default BirthdayCompanyContactsTable;


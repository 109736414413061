import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import uiReducer from './ui/ui.reducer';
import { preloadedState, preloadedStateExpire } from '../definitions/app.definitions';
import promise from 'redux-promise-middleware';
import actorReducer from './actor/actor.reducer';
import errorReducer from './error/error.reducer';
import entitiesReducer from './entities/entities.reducer';
import companyPageReducer from './companyPage/companyPage.reducer';
import candidatePageReducer from './candidatePage/candidatePage.reducer';
import vacancyPageReducer from './vacancyPage/vacancyPage.reducer';
import { IStore } from '../definitions/redux/store.definitions';
import { TReducerAction } from '../definitions/redux/actions.definition';
import { ACTOR_LOGOUT, ACTOR_LOGOUT_EXPIRE } from './actor/actor.actions';
import ReduxThunk from 'redux-thunk';
import recruitingPageReducer from './recruitingPage/recruitingPage.reducer';
import activityOverviewPageReducer from './activityOverviewPage/activityOverviewPage.reducer';
import calendarReducer from './calendar/calendar.reducer';
import dashboardPageReducer from './dashboardPage/dashboardPage.reducer';
import todoReducer from './todo/todo.reducer';
import newsReducer from './news/news.reducer';
import archivePageReducer from './archivePage/archivePage.reducer';
import administrationPageReducer from './administrationPage/administrationPage.reducer';
import webVacancyPageReducer from './webVacancyPage/webVacancyPage.reducer';
import billingPageReducer from './billingPage/billingPage.reducer';
import jobTemplatePageReducer from './jobTemplate/jobTemplatePage.reducer';
import skillPageReducer from './skillPage/skillPage.reducer';
import applicationPageReducer from './applicationPage/applicationPage.reducer';
import mailingReducer from './mailing/mailing.reducer';
import customerAppReducer from './customerApp/customerApp.reducer';

const rootReducer = (state: IStore, action: TReducerAction) => {
    if (action.type === ACTOR_LOGOUT) {
        state = preloadedState;
    }

    if (action.type === ACTOR_LOGOUT_EXPIRE) {
        state = preloadedStateExpire;
    }

    return appReducer(state as any, action);
};

const appReducer = combineReducers({
    ui: uiReducer,
    actor: actorReducer,
    error: errorReducer,
    entities: entitiesReducer,
    companyPage: companyPageReducer,
    candidatePage: candidatePageReducer,
    dashboardPage: dashboardPageReducer,
    recruitingPage: recruitingPageReducer,
    vacancyPage: vacancyPageReducer,
    activityPage: activityOverviewPageReducer,
    calendarPage: calendarReducer,
    archivePage: archivePageReducer,
    todo: todoReducer,
    news: newsReducer,
    mailing: mailingReducer,
    administrationPage: administrationPageReducer,
    webVacancyPage: webVacancyPageReducer,
    billingPage: billingPageReducer,
    jobTemplatePage: jobTemplatePageReducer,
    skillPage: skillPageReducer,
    applicationPage: applicationPageReducer,
    customerApp: customerAppReducer
});

export const store = configureStore({
    reducer: rootReducer as any,
    preloadedState,
    middleware: [ReduxThunk, promise],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type TAppDispatch = typeof store.dispatch;

import { useSelector } from 'react-redux';
import { IStore } from '../definitions/redux/store.definitions';
import moment, { Moment } from 'moment';
import { getUtcMoment } from './calendar.utils';

export const getDayDifference = (dateString: string) => {
    const today = moment();
    const date = moment(dateString);
    date.set({hour: 0, minute: 0, second: 0, millisecond: 0});
    return today.diff(date, 'days');
}

export const getHoursStringByMinutes = (minutes?: number) => {
    if (!minutes) {
        return '00:00';
    }

    let seconds = minutes * 60;
    const days = Math.floor(seconds / (3600*24));
    seconds  -= days*3600*24;
    const hrs   = Math.floor(seconds / 3600);
    seconds  -= hrs*3600;
    const mnts = Math.floor(seconds / 60);

    if (days > 0) {
        return days+" Tag/e, "+hrs.toString().padStart(2, '0')+":"+mnts.toString().padStart(2, '0');
    }

    return `${(Math.floor(minutes / 60)).toString().padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}`
}

export const getFormattedDateString = (date: string) => {
    const currentYear = (new Date()).getFullYear();
    const dateYear = (new Date(date).getFullYear());
    const formatString = currentYear === dateYear ? 'DD.MM' : 'DD.MM.Y';

    return moment(date).format(formatString);
}

export const getFormattedDateWithDayString = (date: string, showTime?: boolean) => {
    const currentYear = (new Date()).getFullYear();
    const dateYear = (new Date(date).getFullYear());
    const formatString = currentYear === dateYear ? 'ddd DD.MM' : 'ddd DD.MM.Y';

    if (showTime) {
        return moment(date).format(formatString) + ', ' + moment(date).format('H:mm') + ' Uhr';
    }
    return moment(date).format(formatString);
}

export const momentToDate = (value: Moment) => {
    return new Date(value.format('YYYY-MM-DD H:mm:ss'));
}

export const getFormattedTimeString = (date: string) => {
    return moment(date).format('HH:mm');
}

export const getDateStringForApi = (date: Date) => {
    return moment(date).format('Y-MM-DD H:mm:ss');
}

export const getCurrentMonthString = () => {
    const monthList = moment.months();
    const currentMoment = moment();
    return monthList[currentMoment.month()];
};

export const calculateAge = (birthdate: string) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age_now = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
    {
        age_now--;
    }
    return age_now;
}

export const getDaysArrayByMonth = () => {
    const currentDate = useSelector((store: IStore) => store.calendarPage.currentDate);
    const utc = getUtcMoment(currentDate);
    let daysInMonth = utc.daysInMonth();
    const arrDays = [];
    const split = currentDate.split("-");
    const monthYear = `${split[0]}-${split[1]}-`;

    while (daysInMonth) {
        let day = String(daysInMonth);

        if (day.length < 2) {
            day = `0${day}`;
        }
        const dateString = monthYear + day;

        const current = getUtcMoment(dateString);
        arrDays.push(current);
        daysInMonth--;
    }

    return arrDays.reverse();
};

import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { TCandidateUrlType } from '../../../definitions/entities.definition';

interface IProps {
    onChange: TMouseEventFunction;
    value?: TCandidateUrlType;
    size?: 'small'|'medium';
    disablePortal?: boolean;
}

const CandidateUrlTypeDropDown: React.FC<IProps> = (props) => {
    const sources: TCandidateUrlType[] = [
       'freelancerMap', 'further', 'github', 'homepage', 'linkedIn', 'xing'
    ];
    const menuItems = (sources).map((source: TCandidateUrlType, index: number) => (
        <MenuItem key={index + 1} value={source}>
            {translate('pages.vacancy.externalCandidateSourceValues.' + source)}
        </MenuItem>
    ));

    return (
        <CustomDropDownInput
            size={props.size}
            onChange={props.onChange}
            value={props.value || 0}
            required
            id={"url-type-type-dropdown"}
            label={translate('misc.source')}
            disablePortal={props.disablePortal}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default CandidateUrlTypeDropDown;

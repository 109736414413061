import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { setUiToggleTopDrawer } from '../../redux/ui/ui.actions';
import {
    Button,
    CircularProgress,
    Collapse,
    debounce,
    Divider,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
} from '@material-ui/core';
import { EIcons, IconFactory } from '../../components/Icons/IconFactory';
import {
    ICandidatePartial,
    ICompanyContactPartial,
    ICompanyPartial,
    IMailPartial,
    IRecruitingPartial,
    ISuggestionPartial,
    IVacancyPartial,
    IWebVacancyPartial,
    TBrainzEntity,
} from '../../definitions/entities.definition';
import { ClientApi } from '../../requests/ClientApi';
import { getGlobalSearchListRouteConfig } from '../../requests/routes';
import { getToken } from '../../selectors/app.selectors';
import {
    COLOR_BLUE,
    COLOR_GREEN,
    COLOR_ORANGE,
    COLOR_RED,
    COLOR_TEXT_SECONDARY,
    COLOR_YELLOW,
} from '../../theme/theme';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import CandidateCellRenderer from '../../components/CustomCellRenderer/CandidateCellRenderer';
import VacancyCellRenderer from '../../components/CustomCellRenderer/VacancyCellRenderer';
import SuggestionCellRenderer from '../../components/CustomCellRenderer/SuggestionCellRenderer';
import CustomHoverIcon from '../../components/CustomInput/CustomHoverIcon';
import { translate } from '../../translation/translate.utils';
import CompanyContactCellRenderer from '../../components/CustomCellRenderer/CompanyContactCellRenderer';
import WebVacancyCellRenderer from '../../components/CustomCellRenderer/WebVacancyCellRenderer';
import RecruitingCellRenderer from '../../components/CustomCellRenderer/RecruitingCellRenderer';
import CompanyCellRenderer from '../../components/CustomCellRenderer/CompanyCellRenderer';

const useStyles = makeStyles({
    fullList: {
        margin: 10,
        border: '1px solid rgba(233, 237, 239, 0.2)',
        maxWidth: 600
    },
    drawerPaper: {
        flexDirection: 'row'
    },
    drawer: {
        height: 80
    },
    input: {
        flex: 1,
        color: 'var(--brainz-color-app-bar-filter)',
        margin: 5
    },
    popper: {
        background: 'var(--brainz-darkest)',
        width: 600,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        marginTop: -5,
        border: '1px solid rgba(233, 237, 239, 0.2)',
        borderTop: 'unset',
        maxHeight: '60%',
        overflow: 'auto',
        zIndex: 1500
    },
    nested: {
        paddingLeft: 14,
    },
});

interface IGlobalSearchResultForEntity<E extends TBrainzEntity> {
    total: number;
    realTotal: number;
    records: {
        [entityId: string] : {
            entity: E,
            foundIn: string[]
        }
    }
}

interface IGlobalSearchResult {
    total: number;
    realTotal: number;
    vacancy?: IGlobalSearchResultForEntity<IVacancyPartial>;
    candidate?: IGlobalSearchResultForEntity<ICandidatePartial>;
    company?: IGlobalSearchResultForEntity<ICompanyPartial>;
    recruiting?: IGlobalSearchResultForEntity<IRecruitingPartial>;
    webVacancy?: IGlobalSearchResultForEntity<IWebVacancyPartial>;
    companyContact?: IGlobalSearchResultForEntity<ICompanyContactPartial>;
    recruitingSuggestion?: IGlobalSearchResultForEntity<ISuggestionPartial>;
    mail?: IGlobalSearchResultForEntity<IMailPartial>;
}

const propertyMap: {[property: string]: {label: string, icon: EIcons, color: string}} = {
    vacancy: {
        label: 'Vakanz/en',
        icon: EIcons.Work,
        color: COLOR_ORANGE
    },
    candidate: {
        label: 'Kandidat/en',
        icon: EIcons.Person,
        color: COLOR_GREEN
    },
    company: {
        label: 'Firma/Firmen',
        icon: EIcons.Apartment,
        color: COLOR_BLUE
    },
    recruiting: {
        label: 'Prozess/e',
        icon: EIcons.ThumbsUpDown,
        color: COLOR_ORANGE
    },
    webVacancy: {
        label: 'Stelle/n',
        icon: EIcons.CloudDownload,
        color: COLOR_RED
    },
    companyContact: {
        label: 'Ansprechpartner',
        icon: EIcons.Person,
        color: COLOR_BLUE
    },
    recruitingSuggestion: {
        label: 'Vorschlag/Vorschläge',
        icon: EIcons.Star,
        color: COLOR_ORANGE
    },
    mail: {
        label: 'E-Mail/s',
        icon: EIcons.EMail,
        color: COLOR_YELLOW
    }
}

const renderEntity = (entityIndex: string, entity: TBrainzEntity) => {
    switch (entityIndex) {
        case 'candidate':
            return <CandidateCellRenderer key={entity.id} candidate={entity as ICandidatePartial} />
        case 'vacancy':
            return <VacancyCellRenderer key={entity.id} vacancy={entity as IVacancyPartial} />
        case 'recruitingSuggestion':
            return <SuggestionCellRenderer key={entity.id} displayFullName recruitingSuggestion={entity as ISuggestionPartial} />
        case 'company':
            return <CompanyCellRenderer key={entity.id} company={entity as ICompanyPartial} />
        case 'companyContact':
            return <CompanyContactCellRenderer key={entity.id} companyContact={entity as ICompanyContactPartial} />
        case 'webVacancy':
            return <WebVacancyCellRenderer key={entity.id} id={entity.id} title={(entity as IWebVacancyPartial).title} />
        case 'recruiting':
            return <RecruitingCellRenderer key={entity.id} recruiting={entity as IRecruitingPartial} showCandidateName/>
        default:
            return <>Darstellung der Ergebnisse In Arbeit von Daniel</>;
    }
}

const TopDrawerSearch: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const styles = useStyles();
    const [innerValue, setInnerValue] = useState("");
    const [searchInComments, setSearchInComments] = useState(false);
    const [searchResult, setSearchResult] = useState<IGlobalSearchResult>({total: 0, realTotal: 0});
    const [loading, setLoading] = useState(false);

    const loadData = (filterFastSearch: string) => {
        if (filterFastSearch.length < 3) {
            setSearchResult({total: 0, realTotal: 0});
            return;
        }
        setLoading(true);
        ClientApi.request(getGlobalSearchListRouteConfig, {
            token,
            filterFastSearch,
            searchInComments
        }).then((result: IGlobalSearchResult) => {
            setSearchResult(result);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadData(innerValue)
    }, [searchInComments]);

    const changeHandler = (value: string) => {
        loadData(value);
    };
    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 300), []
    );
    const open = useSelector((store: IStore) => store.ui.topDrawerOpen);
    const toggleDrawer = () => {
        dispatch(setUiToggleTopDrawer());
    };
    const inputRef = useRef(null);

    useEffect(() => {
        setSearchResult({total: 0, realTotal: 0});
        setInnerValue('');
    }, [open]);

    const hasResults = searchResult.realTotal > 0;

    const renderSearchResultItem = (entityIndex: string) => {
        //@ts-ignore
        const itemResult: IGlobalSearchResultForEntity<any> = searchResult[entityIndex];
        //const [listOpen, setListOpen] = useState(true);
        const listOpen = true;
        const handleClick = () => {
            //setListOpen(!listOpen);
        };

        return <>
            <List aria-label="main mailbox folders">
                <ListItem button style={{padding: 4}}>
                    <ListItemIcon>
                        {IconFactory.getIconComponent(propertyMap[entityIndex].icon, {color: propertyMap[entityIndex].color})}
                    </ListItemIcon>
                    <ListItemText
                        style={{color: COLOR_TEXT_SECONDARY}}
                        primary={propertyMap[entityIndex].label + ' (' + itemResult.realTotal + ')'} />
                    {listOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            </List>
            <Collapse in={listOpen} timeout="auto" unmountOnExit>
                <div className={"flexContainerRow gap5"} style={{padding: 5, flexWrap: 'wrap'}}>
                    { Object.keys(itemResult.records).map((key) => {
                        const entity = itemResult.records[key].entity;
                        const foundIn = itemResult.records[key].foundIn;

                        return <>
                            {renderEntity(entityIndex, entity)}
                            <CustomHoverIcon icon={EIcons.Info} tooltip={"gefunden in: " + foundIn.map((value) => translate('misc.' + value)).join(', ')}/>
                        </>

                    })}
                </div>
            </Collapse>
            <Divider />
        </>;
    }

    const renderSearchResult = () => <>
        {Object.keys(searchResult).map((key) => {
            if (key === 'total' || key === 'realTotal') {
                return null;
            }
            return renderSearchResultItem(key);
        })}
    </>

    return (
        <>
            <Drawer
                anchor={"top"}
                open={open}
                onClose={toggleDrawer} className={styles.drawer}
                classes={{paper: styles.drawerPaper}}
            >
                { open &&
                    <div className={"flex flexCentered"}>
                        <Paper ref={inputRef} variant="elevation" elevation={3} className={styles.fullList + " flex flexCentered alignSelfCenter gap5"}>
                            &nbsp;
                            <Button
                                variant="contained"
                                color={searchInComments ? "default" : "secondary"}
                                onClick={() => setSearchInComments(!searchInComments)}
                                startIcon={IconFactory.getIconComponent(searchInComments ? EIcons.Check : EIcons.CheckboxOutlined)}
                            >
                                Kommentarfelder durchsuchen
                            </Button>
                            &nbsp;
                            <InputBase
                                autoFocus
                                className={styles.input}
                                placeholder={"Brainz global durchsuchen"}
                                value={innerValue}
                                onChange={(event) => {
                                    setInnerValue(event.target.value);
                                    debouncedChangeHandler(event.target.value);
                                }}
                                endAdornment={
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={30} /> : null}
                                    </>
                                }
                            />
                            { (inputRef !== null && innerValue.length >= 3 && hasResults) &&
                            <Popper open={true} anchorEl={inputRef.current} placement={'bottom'} className={styles.popper}>
                                {renderSearchResult()}
                            </Popper>
                            }
                            <IconButton aria-label="search">
                                {IconFactory.getIconComponent(EIcons.Search, {color: "var(--brainz-color-app-bar-filter)"})}
                            </IconButton>
                        </Paper>
                    </div>
                }
                <div className={"flex flexAutoGrow"}>
                    <IconButton
                        onClick={() => {
                            if (open) {
                                dispatch(setUiToggleTopDrawer());
                                return;
                            }
                        }}
                    >
                        {IconFactory.getIconComponent(EIcons.ChevronRight)}
                    </IconButton>
                </div>
            </Drawer>
        </>
    );
}

export default React.memo(TopDrawerSearch);

import React, { useCallback, useEffect, useState } from 'react';
import { debounce, Divider, IconButton, InputBase, Paper } from '@material-ui/core';
import { translate } from '../../../translation/translate.utils';
import styles from './CustomFastSearchPanel.module.css';
import CustomFastSearchPanelFilter from './CustomFastSearchPanelFilter';
import CustomFastSearchPanelActionButtons from './CustomFastSearchPanelActionButtons';
import { IAppBarConfig } from '../../../definitions/ui.definitions';
import { EIcons, IconFactory } from '../../Icons/IconFactory';
import CustomFastSearchPanelFurtherActionButton from './CustomFastSearchPanelFurtherActionButton';

interface IProps {
    appBarConfig: IAppBarConfig;
}

const CustomFastSearchPanel: React.FC<IProps> = (props) => {
    const [innerValue, setInnerValue] = useState("");
    const appBarConfig = props.appBarConfig;

    useEffect(() => {
        if (props.appBarConfig) {
            setInnerValue(props.appBarConfig.value);
        }
    }, [props.appBarConfig?.entity]);

    const changeHandler = (appBarConfig: IAppBarConfig, value: string) => {
        if (appBarConfig.fastSearchCallback) {
            appBarConfig.fastSearchCallback(value);
        }
    };

    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 300), []
    );

    if (!appBarConfig.fastSearchCallback) {
        return <>
            <div className={"flexContainerRow"}>
                {props.appBarConfig.actionButtons &&
                    <CustomFastSearchPanelActionButtons buttons={props.appBarConfig.actionButtons}/>
                }
                {props.appBarConfig.furtherActionButtons &&
                    <CustomFastSearchPanelFurtherActionButton buttons={props.appBarConfig.furtherActionButtons}/>
                }
            </div>
        </>
    }

    return <>
        <div id={"fastSearchContainer"} className={"flex1 alignVerticalCenter"}>
            <Paper variant="elevation" elevation={3} className={styles.root + " flex flexCentered gap5"}>
                {(props.appBarConfig.filterPanelType !== undefined) &&
                    <CustomFastSearchPanelFilter
                        entity={props.appBarConfig.entity}
                        filterType={props.appBarConfig.filterPanelType}
                    />
                }
                <InputBase
                    id={"fastSearch" + props.appBarConfig.entity}
                    role="presentation"
                    autoFocus
                    inputProps={{
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                        role: "presentation"
                    }}
                    className={styles.input}
                    placeholder={props.appBarConfig?.emptyValue || translate('misc.fastSearch')}
                    value={innerValue}
                    onChange={(event) => {
                        setInnerValue(event.target.value);
                        debouncedChangeHandler(props.appBarConfig, event.target.value);
                    }}
                />
                <IconButton className={styles.iconButton} aria-label="search">
                    {IconFactory.getIconComponent(EIcons.Search, {color: "var(--brainz-color-app-bar-filter)"})}
                </IconButton>
                <Divider className={styles.divider} orientation="vertical"/>
                {props.appBarConfig.actionButtons &&
                    <CustomFastSearchPanelActionButtons buttons={props.appBarConfig.actionButtons}/>
                }
                {props.appBarConfig.furtherActionButtons &&
                    <CustomFastSearchPanelFurtherActionButton buttons={props.appBarConfig.furtherActionButtons}/>
                }
            </Paper>
        </div>
    </>;
}

export default React.memo(CustomFastSearchPanel);

import { ESortingOptions } from './components.definitions';
import { IVacancyFilter } from './vacancyPage.definitions';
import { ISorting } from './app.definitions';

export interface IArchivePageState {
    vacanciesLoaded: boolean;
    sortingVacancyList: ISorting;
    filterVacancyList: IVacancyFilter;
}

export const preloadedStateArchivePage: IArchivePageState = {
    vacanciesLoaded: false,
    sortingVacancyList: {
        'vacancy.created': ESortingOptions.Descending
    },
    filterVacancyList: {
        fastSearch: '',
        start: 0,
        limit: 50,
        responsibleEmployeeId: 0
    }
};

import { createStyles, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomMenu from '../../../CustomMenu/CustomMenu';
import CustomButton from '../../../CustomInput/CustomButton';
import { translate } from '../../../../translation/translate.utils';
import LegacyEmployeeAutocompleteDropDown
    from '../../../CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import { EEntities, IEmployee } from '../../../../definitions/entities.definition';
import { IStore } from '../../../../definitions/redux/store.definitions';
import {
    setCandidatePageRecruitingListFilterAction,
    setCandidatePageRecruitingListFilterMenuAction,
} from '../../../../redux/candidatePage/candidatePage.actions';
import {
    setCompanyPageRecruitingListFilterAction,
    setCompanyPageRecruitingListFilterMenuAction,
} from '../../../../redux/companyPage/companyPage.actions';
import RecruitingStatusDropDown from '../../../CustomInput/CustomDropDownInput/RecruitingStatusDropDown';
import CustomNumberInput from '../../../CustomInput/CustomNumberInput';
import moment from 'moment';
import CustomDatePicker from '../../../CustomInput/CustomDatePicker';

interface IProps {
    anchorEl: null | any;
    removeAnchor: VoidFunction;
    entity: EEntities;
}

const useStyles = makeStyles(
    createStyles({
        customWidth: {
            "& ul": {
                minWidth: "500px",
            },
        },
    })
);

const CustomFilterMenuActivity: React.FC<IProps> = (props) => {
    const styles = useStyles();
    const dispatch = useDispatch();

    const {filter, filterMenu, setFilterAction, setFilterMenuAction} = useSelector((state: IStore) => {
        if (props.entity === EEntities.RecruitingOfCompany) {
            return {
                filter: state.companyPage.filterRecruitingList,
                filterMenu: state.companyPage.filterMenuRecruitingList,
                setFilterAction: setCompanyPageRecruitingListFilterAction,
                setFilterMenuAction: setCompanyPageRecruitingListFilterMenuAction
            }
        }

        return {
            filter: state.candidatePage.filterRecruitingList,
            filterMenu: state.candidatePage.filterMenuRecruitingList,
            setFilterAction: setCandidatePageRecruitingListFilterAction,
            setFilterMenuAction: setCandidatePageRecruitingListFilterMenuAction
        }
    })

    const submit = () => {
        dispatch(
            setFilterAction({
                fastSearch: filter.fastSearch,
                start: filter.start,
                limit: filter.limit,
                status: filterMenu.status,
                dateFrom: filterMenu.dateFrom,
                dateTo: filterMenu.dateTo,
                suggestedByEmployeeId: filterMenu.suggestedByEmployee ? filterMenu.suggestedByEmployee.id : undefined,
                employeeId: filterMenu.employee ? filterMenu.employee.id : undefined,
                possibleCommissionFrom: filterMenu.possibleCommissionFrom,
                possibleCommissionTo: filterMenu.possibleCommissionTo
            })
        );
        props.removeAnchor();
    };

    return (
        <CustomMenu className={styles.customWidth} anchorEl={props.anchorEl} removeAnchor={props.removeAnchor}>
            <MenuItem className={"marginBottom5 gap10"}>
                <div className={"flex1"}>
                    <CustomButton
                        fullWidth
                        onClick={submit}
                        label={translate("misc.buttons.applyFilters")}
                    />
                </div>
                <div className={"flex1"}>
                    <CustomButton
                        fullWidth
                        color="secondary"
                        onClick={() => {
                            dispatch(setFilterAction({start:0, limit:0, fastSearch: ''}));
                            dispatch(setFilterMenuAction({start:0, limit:0, fastSearch: ''}));
                        }}
                        label={translate("misc.buttons.clearFilters")}
                    />
                </div>
            </MenuItem>
            <MenuItem>
                <RecruitingStatusDropDown
                    value={filterMenu.status}
                    showAbortTrial
                    onChange={(event) =>
                        dispatch(
                            setFilterMenuAction({
                                status: event.target.value
                            })
                        )
                    }
                />
            </MenuItem>
            <MenuItem>
                <LegacyEmployeeAutocompleteDropDown
                    label={translate('pages.vacancy.properties.responsibleEmployee')}
                    value={filterMenu.employee}
                    onChange={(event, value: IEmployee) =>
                        dispatch(
                            setFilterMenuAction({
                                employee: value
                            })
                        )
                    }
                />
            </MenuItem>
            <MenuItem>
                <LegacyEmployeeAutocompleteDropDown
                    label={translate('pages.vacancy.suggestedByEmployeeFormLabel')}
                    value={filterMenu.suggestedByEmployee}
                    onChange={(event, value: IEmployee) =>
                        dispatch(
                            setFilterMenuAction({
                                suggestedByEmployee: value
                            })
                        )
                    }
                />
            </MenuItem>
            <MenuItem className={"marginBottom5 gap10"}>
                <CustomNumberInput
                    value={filterMenu.possibleCommissionFrom}
                    onChange={(possibleCommissionFrom) =>
                        dispatch(
                            setFilterMenuAction({
                                possibleCommissionFrom
                            })
                        )
                    }
                    label={translate('pages.recruiting.possibleCommissionFrom')}
                    max={999}
                />
                <CustomNumberInput
                    value={filterMenu.possibleCommissionTo}
                    onChange={(possibleCommissionTo) =>
                        dispatch(
                            setFilterMenuAction({
                                possibleCommissionTo
                            })
                        )
                    }
                    label={translate('pages.recruiting.possibleCommissionTo')}
                    max={999}
                />
            </MenuItem>
            <MenuItem className={"marginBottom5 gap10"}>
                <CustomDatePicker
                    flex
                    label={translate('pages.activity.filter.dateFrom')}
                    value={filterMenu.dateFrom}
                    onChange={(value) => dispatch(setFilterMenuAction({ dateFrom: value ? moment(value).format('YYYY-MM-DD') : value }))}
                />
                <CustomDatePicker
                    flex
                    label={translate('pages.activity.filter.dateTo')}
                    value={filterMenu.dateTo}
                    onChange={(value) => dispatch(setFilterMenuAction({ dateTo: value ? moment(value).format('YYYY-MM-DD') : value }))}
                />
            </MenuItem>
        </CustomMenu>
    );
};

export default CustomFilterMenuActivity;

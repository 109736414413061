import React from 'react';
import { ENewsType, ICandidate, INews, IRecruiting } from '../../../../definitions/entities.definition';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import styles from './NewsElement.module.css';
import { getNewsColor, getNewsIcon, getNewsText } from '../../../../utils/news.utils';
import { useDispatch } from 'react-redux';
import { translate } from '../../../../translation/translate.utils';
import { Tooltip } from '@material-ui/core';
import { COLOR_YELLOW } from '../../../../theme/theme';
import { setUiEditView } from '../../../../redux/ui/ui.actions';
import { EEntityView } from '../../../../definitions/ui.definitions';


interface IProps {
    news: INews
}

const NewsElement: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const news = props.news;

    return (
        <>
            <div className={styles.root} onClick={() => {
                switch (news.newsType) {
                    case ENewsType.NEWS_TYPE_CANDIDATE_BIRTHDAY:
                        dispatch(setUiEditView(EEntityView.candidate, (news.candidate as ICandidate).id));
                        break;
                    case ENewsType.NEWS_TYPE_CONTACT_PERSON_BIRTHDAY:
                        if (news.companyContact) {
                            const company = news.companyContact.company;
                            dispatch(setUiEditView(EEntityView.company, company.id));
                        }
                        break;
                    case ENewsType.NEWS_TYPE_VACANCY_OFFLINE:
                        //@ts-ignore
                        dispatch(setUiEditView(EEntityView.vacancy, news.vacancy.id));
                        break;
                    default: {
                        const recruiting = news.recruiting as IRecruiting;
                        dispatch(setUiEditView(EEntityView.recruiting, recruiting.id));
                        break;
                    }
                }
            }}>
                <div className={styles.innerRoot}>
                    <div className={styles.icon} style={{ background: getNewsColor(news.newsType) }}>
                        {IconFactory.getIconComponent(getNewsIcon(news.newsType), {
                            color: "white",
                            width: "1em",
                            height: "1em",
                        })}
                    </div>
                    {news.highlight &&
                    <Tooltip title={translate(news.newsType === ENewsType.NEWS_TYPE_CONTACT_PERSON_BIRTHDAY ? 'pages.news.inContactWithContactPerson' : 'pages.news.inContactWithCandidate')} placement="top-start">
                        <div className={styles.icon} style={{ background: COLOR_YELLOW }}>
                            {IconFactory.getIconComponent(EIcons.Person, {
                                color: "white",
                                width: "1em",
                                height: "1em",
                            })}
                        </div>
                    </Tooltip>
                    }
                    <p>
                        {getNewsText(news)}
                    </p>
                </div>
            </div>
        </>
    );
}

export default NewsElement;

import { Drawer, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { IStore } from '../../../definitions/redux/store.definitions';
import { closeUiRightDrawerAction } from '../../../redux/ui/ui.actions';
import { getTodoListOfEmployeeAction, putTodosReadTimestampAction } from '../../../redux/entities/entities.actions';
import { translate } from '../../../translation/translate.utils';
import TopBarPanel from '../../../components/ListAndPreview/TopBarPanel';
import { ERightDrawerViews } from '../../../definitions/ui.definitions';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import { setTodoAddDialogOpenAction } from '../../../redux/todo/todo.actions';
import TopBarButton from '../../../components/ListAndPreview/TopBarButton';
import EmployeeTodoView from './Todo/EmployeeTodoView';
import { useDrawerStyles } from './Drawer.styles';

const RightDrawerTodoView = () => {
    const classes = useDrawerStyles();
    const dispatch = useDispatch();
    const drawerOpen = useSelector((store: IStore) =>store.ui.rightDrawerTodoOpen);
    const reloadTodos = useSelector((store: IStore) => store.todo.reloadTodos);
    const token = useSelector((store: IStore) => store.actor.token);
    const loading = useSelector((state: IStore) => state.ui.todosOfEmployeeLoading);

    useEffect(() => {
        if (drawerOpen) {
            dispatch(putTodosReadTimestampAction(token));
            dispatch(getTodoListOfEmployeeAction(token));
            return;
        }
    }, [drawerOpen]);

    useEffect(() => {
        if (drawerOpen && !loading && reloadTodos) {
            dispatch(getTodoListOfEmployeeAction(token));
        }
    }, [reloadTodos]);

    if (!drawerOpen) {
        return null;
    }

    const getHeader = () => {
        return <>
            <h2>Todos</h2>
            <TopBarPanel>
                <TopBarButton
                    onClick={() => dispatch(setTodoAddDialogOpenAction(true))}
                    icon={EIcons.Add}
                    label={translate('pages.todo.add')}
                />
            </TopBarPanel>
        </>
    }

    const renderContent = () => {
        return <>
            <div className={classes.toolbar}>
                <div className={"flex flexCentered"}>
                    {getHeader()}
                </div>
                <IconButton
                    onClick={() => {
                        if (drawerOpen) {
                            dispatch(closeUiRightDrawerAction(ERightDrawerViews.viewTodos));
                            return;
                        }
                    }}
                >
                    {IconFactory.getIconComponent(EIcons.ChevronRight)}
                </IconButton>
            </div>
            <EmployeeTodoView />
        </>;
    }

    return (
        <Drawer
            anchor={"right"}
            open={drawerOpen}
            variant={'persistent'}
            classes={{paper: classes.drawerOpen + ' ' + classes.defaultWidth + ' ' + classes.boxShadow}}
        >
            {drawerOpen &&
                <>{renderContent()}</>
            }
        </Drawer>
    )
}

export default RightDrawerTodoView;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerError, registerInfo } from '../../../../../../redux/error/error.actions';
import { EIcons } from '../../../../../../components/Icons/IconFactory';
import { deleteCandidatePhoneRouteConfig, putCandidatePhoneRouteConfig } from '../../../../../../requests/routes';
import { IListActionButtonConfig } from '../../../../../../definitions/components.definitions';
import {
    setCandidatePageDeletePhoneFromFormAction,
    setCandidatePageUpdatePhoneInFormAction,
} from '../../../../../../redux/candidatePage/candidatePage.actions';
import CustomEditableValue from '../../../../../../components/CustomInput/CustomEditableValue';
import CustomHoverIcon from '../../../../../../components/CustomInput/CustomHoverIcon';
import CustomTextField from '../../../../../../components/CustomInput/CustomTextField';
import { getToken } from '../../../../../../selectors/app.selectors';
import CustomContextMenu from '../../../../../../components/CustomContextMenu/CustomContextMenu';
import { ICandidateMailAddress, ICandidatePhoneNumber } from '../../../../../../definitions/entities.definition';
import { ClientApi } from '../../../../../../requests/ClientApi';
import Nl2brCellRenderer from '../../../../../../components/CustomCellRenderer/Nl2brCellRenderer';
import { translate } from '../../../../../../translation/translate.utils';
import { getSuggestionAction } from '../../../../../../redux/entities/entities.actions';
import { InputAdornment } from '@material-ui/core';
import CustomClickableIcon from '../../../../../../components/CustomInput/CustomClickableIcon';

interface IProps {
    index: number;
    candidateId?: number;
    suggestionId?: number;
    phoneNumbers: ICandidatePhoneNumber[];
    phoneNumber: ICandidatePhoneNumber;
}

const CandidatePhoneItemView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const candidateId = props.candidateId;
    const phoneNumber = props.phoneNumber;
    const phoneNumbers = props.phoneNumbers;
    const suggestionId = props.suggestionId;
    const index = props.index;

    const [phoneNumberText, setPhoneNumberText] = useState(phoneNumber.phoneNumber);
    const [comment, setComment] = useState(phoneNumber.comment);

    const onDelete = () => {
        if (!candidateId) {
            dispatch(setCandidatePageDeletePhoneFromFormAction(index));
            return;
        }

        ClientApi.request(deleteCandidatePhoneRouteConfig, {
            token,
            candidateId,
            id: phoneNumber.id,
        }).then(() => {
            dispatch(registerInfo('Datensatz wurde gelöscht.'));
            if (suggestionId) {
                dispatch(getSuggestionAction(token, suggestionId));
                return;
            }
            dispatch(setCandidatePageDeletePhoneFromFormAction(index));
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht gelöscht werden'));
        });
    };

    const onUpdate = (setDefault?: boolean) => {
        if (!setDefault && phoneNumberText === phoneNumber.phoneNumber && comment === phoneNumber.comment) {
            return Promise.resolve();
        }
        const updateInStore = (index: number, phone: ICandidatePhoneNumber) => {
            if (setDefault) {
                phoneNumbers.forEach((phoneNumber, index) => {
                    if (!phoneNumber.default) {
                        return;
                    }
                    dispatch(setCandidatePageUpdatePhoneInFormAction(index, {...phone, default: false}));
                });
            }
            dispatch(setCandidatePageUpdatePhoneInFormAction(index, phone));
        };

        const putPhone: ICandidatePhoneNumber = {
            id: phoneNumber.id,
            phoneNumber: phoneNumberText,
            default: setDefault || phoneNumber.default,
            comment: comment
        }

        if (!candidateId) {
            updateInStore(index, putPhone);
            return Promise.resolve();
        }

        return ClientApi.request(putCandidatePhoneRouteConfig, {
            token,
            candidateId,
            ...putPhone,
        }).then((phone: ICandidatePhoneNumber) => {
            if (suggestionId) {
                dispatch(getSuggestionAction(token, suggestionId));
                return;
            }
            updateInStore(index, phone);
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht aktualisiert werden'));
        });
    };
    const contextMenu: IListActionButtonConfig[] = [{
        icon: EIcons.Remove,
        action: () => onDelete(),
        translationKey: 'tables.openDeleteView',
    }, {
        icon: EIcons.CheckCircle,
        action: () => onUpdate(true),
        translationKey: 'misc.markAsDefault',
        condition: (entity) => {
            const entityCast = entity as ICandidatePhoneNumber;
            return phoneNumbers.length > 1 && !entityCast.default;
        },
    }, {
        icon: EIcons.Copy,
        action: () => {
            navigator.clipboard.writeText(phoneNumber.phoneNumber);
            dispatch(registerInfo('Telefon-Nummer wurde kopiert'));
        },
        translationKey: 'misc.copy',
    }];

    return <>
        <CustomContextMenu
            index={index}
            entityId={phoneNumber.id}
            entity={phoneNumber}
            menuHeading={(entity) => {
                const entityCast = entity as ICandidateMailAddress;
                return entityCast.mailAddress;
            }}
            menuItems={contextMenu}
        >
            <CustomEditableValue
                key={phoneNumber.id}
                theme={'light'}
                input={<div className={"flexContainerColumn flex1 gap5"}>
                    <CustomTextField
                        required
                        autoFocus
                        size={'small'}
                        value={phoneNumberText}
                        onChange={(value) => setPhoneNumberText(value)}
                        label={translate('misc.phoneNumber')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <CustomClickableIcon onClick={() => {
                                    navigator.clipboard.writeText(phoneNumberText);
                                    dispatch(registerInfo('Telefonnummer Adresse wurde kopiert'));
                                }} icon={EIcons.Copy}/>
                            </InputAdornment>
                        }}
                    />
                    <CustomTextField
                        required
                        size={'small'}
                        multiline
                        rows={5}
                        label={translate('misc.comment')}
                        value={comment}
                        onChange={(value) => setComment(value)}
                    />
                </div>}
                value={phoneNumber.phoneNumber}
                onSave={onUpdate}
            >
                <div className={'flexContainerRow gap5 alignVerticalCenter'}>
                    {phoneNumber.phoneNumber}
                    {phoneNumbers.length > 1 && phoneNumber.default &&
                        <CustomHoverIcon iconStyle={{width:'0.8em', height:'0.8em'}} icon={EIcons.CheckCircle} tooltip={'ist Standard-Nummer'}/>
                    }
                </div>
                {phoneNumber.comment &&
                    <div className={'formContent marginLeft5'}>
                        <Nl2brCellRenderer value={phoneNumber.comment}/>
                    </div>
                }
            </CustomEditableValue>
        </CustomContextMenu>
    </>;
};

export default CandidatePhoneItemView;

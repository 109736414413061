import { IStore } from '../definitions/redux/store.definitions';
import { IGetRequestObject } from '../definitions/requests.definitions';
import { ICompany } from '../definitions/entities.definition';
import { buildRequestObject } from './entity.selectors';

export const getCompanyPageRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    const sorting = store.companyPage.sorting;
    const filter = store.companyPage.filter;

    return buildRequestObject(sorting, filter);
};

export const getCompanyPageRequestObjectForCompanyContactsInEditView = (store: IStore) => {
    const companyContactSorting = store.companyPage.companyContactSorting;
    const companyContactFilter = { start: 0, limit: 0, fastSearch: "" };

    return buildRequestObject(companyContactSorting, companyContactFilter);
};

export const getCompanyPageRequestObjectForCompanyLocationsInEditView = (store: IStore) => {
    const companyLocationSorting = store.companyPage.companyLocationSorting;

    return buildRequestObject(companyLocationSorting, {});
};

export const getCompanyPageRequestObjectForVacanciesInEditView = (store: IStore) => {
    const companyVacancySorting = store.companyPage.companyVacancySorting;
    const companyVacancyFilter = store.companyPage.companyVacancyFilter;

    return buildRequestObject(companyVacancySorting, companyVacancyFilter);
};

export const getCompanyPageRequestObjectForVacanciesInContactEditView = (store: IStore) => {
    const companyVacancySorting = store.companyPage.companyContactVacancySorting;
    const companyVacancyFilter = store.companyPage.companyContactVacancyFilter;

    return buildRequestObject(companyVacancySorting, companyVacancyFilter);
};

export const getCompaniesForCompanyTable = (store: IStore) => {
    const order = store.entities.companies.order;
    const companiesById = store.entities.companies.byId;

    return order.reduce((accumulator: ICompany[], companyId) => {
        accumulator.push(companiesById[companyId]);

        return accumulator;
    }, []);
};

export const getCompaniesForAutocomplete = (store: IStore) => {
    const order = store.entities.companies.orderAll;
    const companiesById = store.entities.companies.byId;

    return order.reduce((accumulator: ICompany[], companyId) => {
        accumulator.push(companiesById[companyId]);

        return accumulator;
    }, []);
};

import { useEffect, useState } from 'react';
import { getAllVacanciesOfCompanyContactAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import { getCompanyContactVacanciesForCompany } from '../../../../../selectors/vacancy.selectors';
import { getCompanyPageRequestObjectForVacanciesInContactEditView } from '../../../../../selectors/company.selectors';

export const useCompanyContactVacancyListHook = (companyContactId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.companyContactVacanciesLoading);

    const [initialLoading, setInitialLoading] = useState(true);
    const requestObject = useSelector(getCompanyPageRequestObjectForVacanciesInContactEditView);
    const sorting = useSelector((store: IStore) => store.companyPage.companyContactVacancySorting);
    const filter = useSelector((store: IStore) => store.companyPage.companyContactVacancyFilter);
    const vacancies = useSelector((store: IStore) => getCompanyContactVacanciesForCompany(store, companyContactId));

    useEffect(() => {
        dispatch(getAllVacanciesOfCompanyContactAction(token, companyContactId, requestObject));
        setInitialLoading(false);
    }, [companyContactId]);

    useEffect(() => {
        if (!loading && !initialLoading) {
            dispatch(getAllVacanciesOfCompanyContactAction(token, companyContactId, requestObject));
        }
    }, [JSON.stringify(sorting)]);

    useEffect(() => {
        if (!loading && !initialLoading) {
            dispatch(getAllVacanciesOfCompanyContactAction(token, companyContactId, requestObject));
        }
    }, [JSON.stringify(filter)]);

    return {loading, vacancies, total: vacancies.length};
}

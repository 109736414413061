import React from 'react';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { ESuggestionStatus, ISuggestion } from '../../../definitions/entities.definition';
import {
    groupSuggestionsByProgressStatus,
    groupSuggestionsByStatus,
} from '../../../selectors/recruitingSuggestion.selectors';
import RenderSuggestionListGroup from './RenderSuggestionListGroup';
import CustomTableFooter from '../../../components/CustomTable/CustomTableFooter';
import { DropResult } from 'react-beautiful-dnd';
import { EEntityView } from '../../../definitions/ui.definitions';

interface IProps {
    total: number;
    suggestions: ISuggestion[];
    loading: boolean;
    parentView: EEntityView;
    onReload: () => void;
    onDragEnd?: (result: DropResult) => void;
    hideGroupHeader?: boolean;
    groupBy?: 'status'|'progressStatus';
}

const SuggestionList: React.FC<IProps> = (props) => {
    const groupBy = props.groupBy || 'status';
    const total = props.total;
    const suggestionGroups = groupBy === 'status' ? groupSuggestionsByStatus(props.suggestions) : groupSuggestionsByProgressStatus(props.suggestions);
    const loading = props.loading;
    const parentView = props.parentView;
    const hideGroupHeader = props.hideGroupHeader || false;

    return <>
        <div className={"flexColumn flex1"}>
            <div className={"overflow flex1 marginTop10 flexContainerColumn gap5 paddingRight5"}>
                <CustomBackdropLoadingInsideDiv open={loading} />
                {total === 0 &&
                    <>Keine Daten vorhanden</>
                }
                {Object.keys(suggestionGroups).map((status) => {
                    const groupIdentifier = status as ESuggestionStatus;
                    const suggestions = suggestionGroups[status];
                    return <>
                        <RenderSuggestionListGroup
                            key={status}
                            groupIdentifier={groupIdentifier}
                            suggestions={suggestions}
                            parentView={parentView}
                            hideGroupHeader={hideGroupHeader}
                            groupBy={groupBy}
                            onDragEnd={Object.keys(suggestionGroups).length === 1 && groupIdentifier === ESuggestionStatus.OPEN ? props.onDragEnd : undefined}
                        />
                    </>
                })}
            </div>
            <CustomTableFooter
                style={{height: 35}}
                text={'Favoriten: ' + total}
                onReload={props.onReload}
            />
        </div>
    </>;
}

export default SuggestionList;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { ICandidatePhoneNumber } from '../../../../../definitions/entities.definition';
import CustomEditableValue from '../../../../../components/CustomInput/CustomEditableValue';
import { EIcons, IconFactory } from '../../../../../components/Icons/IconFactory';
import { ClientApi } from '../../../../../requests/ClientApi';
import { postCandidatePhoneRouteConfig } from '../../../../../requests/routes';
import { setCandidatePageAddPhoneToFormAction } from '../../../../../redux/candidatePage/candidatePage.actions';
import { registerError } from '../../../../../redux/error/error.actions';
import CustomTextField from '../../../../../components/CustomInput/CustomTextField';
import { translate } from '../../../../../translation/translate.utils';
import CandidatePhoneItemView from './components/CandidatePhoneItemView';
import { getSuggestionAction } from '../../../../../redux/entities/entities.actions';

interface IProps {
    candidateId?: number;
    records: ICandidatePhoneNumber[];
    suggestionId?: number;
}

const CandidatePhoneListView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const candidateId = props.candidateId;
    const phoneNumbers = props.records;
    const suggestionId = props.suggestionId;

    const [phoneNumber, setPhoneNumber] = useState('');
    const [comment, setComment] = useState('');

    const onAdd = () => {
        if (!candidateId) {
            dispatch(setCandidatePageAddPhoneToFormAction({
                id: 0,
                phoneNumber,
                comment: '',
                default: phoneNumbers.length === 0
            }));
            return Promise.resolve();
        }

        return ClientApi.request(postCandidatePhoneRouteConfig, {
            token,
            candidateId,
            phoneNumber,
            comment,
            default: phoneNumbers.length === 0
        }).then((phone: ICandidatePhoneNumber) => {
            setComment('');
            setPhoneNumber('');

            if (suggestionId) {
                dispatch(getSuggestionAction(token, suggestionId));
                return;
            }

            dispatch(setCandidatePageAddPhoneToFormAction(phone));
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht hinzugefügt werden'));
        });
    }

    return <>
        <div className={"flexContainerColumn"} style={{overflow: 'auto', maxHeight: 300}}>
            {phoneNumbers.map((phoneNumber, index) => {
                return <CandidatePhoneItemView
                    key={phoneNumber.id}
                    index={index}
                    candidateId={candidateId}
                    phoneNumbers={phoneNumbers}
                    phoneNumber={phoneNumber}
                    suggestionId={suggestionId}
                />
            })}
        </div>
        <CustomEditableValue
            theme={'light'}
            input={
                <div className={"flexContainerColumn flex1 gap5"}>
                    <CustomTextField
                        required
                        size={'small'}
                        autoFocus
                        value={phoneNumber}
                        onChange={(value) => setPhoneNumber(value)}
                        label={translate('misc.phoneNumber')}
                    />
                    <CustomTextField
                        multiline
                        size={'small'}
                        rows={5}
                        label={translate('misc.comment')}
                        value={comment}
                        onChange={(value) => setComment(value)}
                    />
                </div>
            }
            emptyValue={
                <div className={"flexContainerRow alignVerticalCenter"}>
                    {IconFactory.getIconComponent(EIcons.Add)}
                    Telefon-Nummer hinzufügen
                </div>
            }
            onSave={onAdd}
        />
    </>
}

export default CandidatePhoneListView;

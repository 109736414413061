import { IStore } from '../definitions/redux/store.definitions';
import { ICandidateListModel } from '../definitions/entities.definition';
import { IGetRequestObject } from '../definitions/requests.definitions';
import { buildRequestObject } from './entity.selectors';

export const getAllCandidatesFromStore = (store: IStore): ICandidateListModel[] => {
    const order = store.entities.candidates.order;
    const candidatesById = store.entities.candidates.listModelById;

    return order.reduce((accumulator: ICandidateListModel[], candidateId) => {
        accumulator.push(candidatesById[candidateId]);

        return accumulator;
    }, []);
};

export const getAllCandidatesForWebVacancyFromStore = (store: IStore): ICandidateListModel[] => {
    const order = store.entities.candidates.orderWebVacancySearch;
    const candidatesById = store.entities.candidates.listModelById;

    return order.reduce((accumulator: ICandidateListModel[], candidateId) => {
        accumulator.push(candidatesById[candidateId]);

        return accumulator;
    }, []);
};

export const getAllCandidatesForVacancyFromStore = (store: IStore): ICandidateListModel[] => {
    const order = store.entities.candidates.orderVacancySearch;
    const candidatesById = store.entities.candidates.listModelById;

    return order.reduce((accumulator: ICandidateListModel[], candidateId) => {
        accumulator.push(candidatesById[candidateId]);

        return accumulator;
    }, []);
};

export const getCandidatesWithBirthdayFromStore = (store: IStore): ICandidateListModel[] => {
    const order = store.entities.candidates.orderBirthday;
    const candidatesById = store.entities.candidates.listModelById;

    return order.reduce((accumulator: ICandidateListModel[], candidateId) => {
        accumulator.push(candidatesById[candidateId]);

        return accumulator;
    }, []);
};

export const getCurrentCandidateIdFromStore = (store: IStore) => 0;

export const getCandidatePageRequestObject = (store: IStore, append: boolean): Partial<IGetRequestObject> => {
    const sorting = store.candidatePage.sorting;
    const filter = store.candidatePage.filter;

    if (!append) {
        const newFilter = {
            ...filter,
        };

        newFilter.start = 0;
        //@ts-ignore-next-line
        newFilter.limit = filter.start + filter.limit;
        newFilter.loadHasContact = true;

        return buildRequestObject(sorting, newFilter);
    }

    return buildRequestObject(sorting, filter);
};

export const getWebVacancyCandidateSearchListRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    const sorting = store.webVacancyPage.sortingCandidateList;
    const filter = store.webVacancyPage.filterCandidateList;

    return buildRequestObject(sorting, filter);
};

export const getVacancyCandidateSearchListRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    const sorting = store.vacancyPage.sortingCandidateList;
    const filter = store.vacancyPage.filterCandidateList;

    return buildRequestObject(sorting, filter);
};

import React, { memo } from 'react';
import { ICandidateProfiles, TCandidateUrlType } from '../../definitions/entities.definition';
import { API_URL } from '../../requests/routes';
import { useSelector } from 'react-redux';
import { getToken } from '../../selectors/app.selectors';
import { fixUrl } from '../../utils/application.utils';
import { COLOR_TEXT } from '../../theme/theme';
import { EIcons, IconFactory } from '../Icons/IconFactory';

interface IProps {
    profiles: ICandidateProfiles;
}

const getUrlTypeNode = (urlType: TCandidateUrlType) => {
    switch (urlType) {
        case 'freelancerMap':
            return <img width={22} src={'/freelancer_map.jpg'} title={'Freelancermap'}/>;
        case 'github':
            return <img width={22} src={'/github.png'} title={'GitHub'}/>;
        case 'further':
            return IconFactory.getIconComponent(EIcons.Public, {color: 'white'});
        case 'homepage':
            return <img width={22} src={'/homepage.png'} title={'Homepage'}/>
        case 'linkedIn':
            return <img width={22} src={'/linkedIn.png'} title={'LinkedIn'}/>;
        case 'xing':
            return <img width={22} src={'/xing.png'} title={'Xing'}/>;

    }
}

const CandidateProfilesRenderer: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const profiles = props.profiles;

    return <>
        {profiles.cvId &&
            <div className={'profileItemContainer'} onClick={(event) => {
                event.stopPropagation();
                window.open(`${API_URL}/candidate-document/${profiles.cvId}/preview?Authorization=${token}`);
            }}>
                <img width={22} src={'/lebenslauf.png'} style={{cursor: 'pointer'}} title={'Lebenslauf'}/>
            </div>
        }
        {profiles.internalProfileId &&
            <div className={'profileItemContainer'} onClick={(event) => {
                event.stopPropagation();
                window.open(`${API_URL}/candidate-document/${profiles.internalProfileId}/preview?Authorization=${token}`);
            }}>
                <img width={22} src={'/peagle.png'} style={{cursor: 'pointer'}} title={'Internes Profil'}/>
            </div>
        }
        {profiles.arbeitsamtUrl &&
            <div className={'profileItemContainer'} onClick={(event) => {
                event.stopPropagation();
                window.open(profiles.arbeitsamtUrl);
            }}>
                <img width={22} src={'/arbeitsagentur.png'} style={{cursor: 'pointer'}} title={'Profil der Arbeitsagentur'}/>
            </div>
        }
        {profiles.urls.map((url ) =>
            <div key={url.id} className={'profileItemContainer'}>
                <a target="new" href={fixUrl(url.url)} className={'alignVerticalCenter'} onClick={(event) => event.stopPropagation()}>
                    {getUrlTypeNode(url.type)}
                </a>
            </div>
        )}
    </>;
};

export default memo(CandidateProfilesRenderer);

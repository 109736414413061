import React from 'react';
import { useDispatch } from 'react-redux';
import { ArgumentAxis, Chart, Tooltip, ValueAxis } from '@devexpress/dx-react-chart-material-ui';
import { IGroupedSuggestionWithSourceEvaluationData } from '../../../../../definitions/dashboardPage.definitions';
import { Animation, Axis, BarSeries, EventTracker, ValueScale } from '@devexpress/dx-react-chart';
import { translate } from '../../../../../translation/translate.utils';
import { setVacancyPageSuggestionListViewOpenAction } from '../../../../../redux/vacancyPage/vacancyPage.actions';
import { ESuggestionDeclineReason, ESuggestionStatus } from '../../../../../definitions/entities.definition';
import { COLOR_YELLOW } from '../../../../../theme/theme';

interface IProps {
    vacancyId: number;
    chartData: IGroupedSuggestionWithSourceEvaluationData[];
}
const SuggestionsByDeclineReasonBarChart: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const chartData = props.chartData;
    const barWidth = 1/10 * chartData.length;

    return (
        <>
            <Chart data={chartData} height={400}>
                <ValueScale name={"count"} />
                <ValueAxis
                    showGrid={false}
                    showLine
                    labelComponent={(labelProps: Axis.LabelProps) => {
                        if (labelProps.text.toString().indexOf('.') !== -1 && labelProps.text.toString().indexOf('.0') === -1) {
                            return null;
                        }

                        return <ValueAxis.Label
                            {...labelProps}
                            text={labelProps.text.toString().replace('.0', '')}
                            style={{fill: 'var(--brainz-color-text-secondary)'}}
                        />
                    }}
                />
                <ArgumentAxis
                    labelComponent={(labelProps: Axis.LabelProps) =>
                        <ValueAxis.Label
                            {...labelProps}
                            text={translate('pages.vacancy.declineSuggestionReasonValues.' + labelProps.text)}
                            style={{fill: 'var(--brainz-color-text-secondary)'}}
                        />
                    }
                />
                <BarSeries
                    valueField={"count"}
                    argumentField={"groupTitle"}
                    barWidth={barWidth}
                    color={COLOR_YELLOW}
                />
                <Animation />
                <EventTracker onClick={(targetData) => {
                    if (targetData.targets.length === 0) {
                        return;
                    }
                    const data = props.chartData[targetData.targets[0].point];

                    dispatch(setVacancyPageSuggestionListViewOpenAction({
                        status: ESuggestionStatus.DECLINED,
                        declineReason: data.groupTitle as ESuggestionDeclineReason
                    }));
                }}/>
                <Tooltip contentComponent={(props) => {
                    const data = chartData[props.targetItem.point];

                    return (
                        <>
                            <div>{translate('pages.vacancy.declineSuggestionReasonValues.' + data.groupTitle)}</div>
                            <div>Anzahl: {data.count}</div>
                            {Object.keys(data.countSources).map((value, index) => {
                                return (
                                    <div key={index}>{translate('pages.vacancy.externalCandidateSourceValues.' + value) + ': ' + data.countSources[value]}</div>
                                );
                            })}
                        </>
                    )
                }}/>
            </Chart>
        </>

    );
}

export default SuggestionsByDeclineReasonBarChart;

import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { IStore } from '../../../definitions/redux/store.definitions';
import { closeUiRightDrawerAction } from '../../../redux/ui/ui.actions';
import {
    getMailboxListAction,
    getMailListByChannelAction,
    getMailListByFolderAction,
} from '../../../redux/entities/entities.actions';
import { ERightDrawerViews } from '../../../definitions/ui.definitions';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import MailList from './Mailing/MailList';
import { useDrawerStyles } from './Drawer.styles';
import MailboxView from './Mailing/MailboxView';
import { setMailingFilterAction } from '../../../redux/mailing/mailing.actions';

const RightDrawerMailView = () => {
    const classes = useDrawerStyles();
    const dispatch = useDispatch();
    const drawerOpen = useSelector((store: IStore) => store.ui.rightDrawerMailOpen);
    const token = useSelector((store: IStore) => store.actor.token);
    const loading = useSelector((state: IStore) => state.ui.mailsLoading);
    const filter = useSelector((state: IStore) => state.mailing.filter);
    const paging = useSelector((state: IStore) => state.mailing.paging);
    const selectedMailboxId = useSelector((state: IStore) => state.mailing.selectedMailboxId);

    const loadMails = (syncFolder:boolean) => {
        if (filter.folderId && selectedMailboxId) {
            dispatch(getMailListByFolderAction(token, filter.folderId, selectedMailboxId, paging.start, paging.limit, syncFolder));
            return;
        }
        if (filter.channel) {
            dispatch(getMailListByChannelAction(token, filter.channel));
            return;
        }
    }

    useEffect(() => {
        dispatch(getMailboxListAction(token));
    }, [dispatch])

    useEffect(() => {
        if (drawerOpen && !loading) {
            loadMails(false);
        }
    }, [drawerOpen]);

    useEffect(() => {
        if (drawerOpen && !loading) {
            loadMails(false);
        }
    }, [JSON.stringify(filter)])

    useEffect(() => {
        if (drawerOpen && !loading) {
            loadMails(false);
        }
    }, [JSON.stringify(paging)])

    const renderContent = () => {
        return <>
            <div style={{display: "flex", flexDirection: "column", borderRight: '1px solid #444', width: 200}}>
                <MailboxView/>
                <List dense subheader={<ListSubheader style={{backgroundColor:'var(--brainz-darkest)'}}>Kanäle</ListSubheader>}>
                    <ListItem
                        button
                        selected={filter.channel === 'debug'}
                        onClick={() => dispatch(setMailingFilterAction({channel: 'debug'}))}
                    >
                        <ListItemIcon>{IconFactory.getIconComponentUnstyled(EIcons.BugReport)}</ListItemIcon>
                        <ListItemText primary={"Debug"} />
                    </ListItem>
                    <ListItem button disabled>
                        <ListItemIcon>{IconFactory.getIconComponentUnstyled(EIcons.History)}</ListItemIcon>
                        <ListItemText primary={"Aktuelles"} />
                    </ListItem>
                    <ListItem button disabled>
                        <ListItemIcon>{IconFactory.getIconComponentUnstyled(EIcons.AccountBox)}</ListItemIcon>
                        <ListItemText primary={"Bewerbungen"} />
                    </ListItem>
                    <ListItem button disabled>
                        <ListItemIcon>{IconFactory.getIconComponentUnstyled(EIcons.ThumbsUpDown)}</ListItemIcon>
                        <ListItemText primary={"Prozesse (Aktiv)"} />
                    </ListItem>
                    <ListItem button disabled>
                        <ListItemIcon>{IconFactory.getIconComponentUnstyled(EIcons.LinkedIn)}</ListItemIcon>
                        <ListItemText primary={"LinkedIn"} />
                    </ListItem>
                    <ListItem button disabled>
                        <ListItemIcon>{IconFactory.getIconComponentUnstyled(EIcons.LinkedIn)}</ListItemIcon>
                        <ListItemText primary={"Xing"} />
                    </ListItem>
                </List>
                <List dense subheader={<ListSubheader style={{backgroundColor:'var(--brainz-darkest)'}}>Suche</ListSubheader>}>
                    <ListItem button disabled>
                        <ListItemIcon>{IconFactory.getIconComponentUnstyled(EIcons.Search)}</ListItemIcon>
                        <ListItemText primary={"Schnellsuche"} />
                    </ListItem>
                    <ListItem button disabled>
                        <ListItemIcon>{IconFactory.getIconComponentUnstyled(EIcons.Person)}</ListItemIcon>
                        <ListItemText primary={"Kandidaten"} />
                    </ListItem>
                    <ListItem button disabled>
                        <ListItemIcon>{IconFactory.getIconComponentUnstyled(EIcons.Apartment)}</ListItemIcon>
                        <ListItemText primary={"Firma | Ansprechpartner"} />
                    </ListItem>
                </List>
            </div>
            <div className={"flexColumn"}>
                <div className={classes.toolbar}>
                    <div className={"flex alignVerticalCenter"}>
                        <ListSubheader className={"alignVerticalCenter"} disableGutters>
                            E-Mails
                            <Tooltip title={"E-Mails synchronisieren"}>
                                <IconButton onClick={() => loadMails(true)}>
                                    {IconFactory.getIconComponent(EIcons.CloudDownload)}
                                </IconButton>
                            </Tooltip>
                        </ListSubheader>
                    </div>
                    <IconButton
                        onClick={() => {
                            if (drawerOpen) {
                                dispatch(closeUiRightDrawerAction(ERightDrawerViews.viewMails));
                                return;
                            }
                        }}
                    >
                        {IconFactory.getIconComponent(EIcons.ChevronRight)}
                    </IconButton>
                </div>
                <MailList/>
            </div>
        </>
    }

    return (
        <>
            <Drawer
                anchor={"right"}
                open={drawerOpen}
                variant={'persistent'}
                classes={{paper: classes.drawerOpen + ' ' + classes.defaultWidth + ' ' + classes.boxShadow}}
            >
                {drawerOpen &&
                    <div className={"flexContainerRow"} style={{overflow: 'hidden'}}>
                        {renderContent()}
                    </div>
                }
            </Drawer>
        </>
    )
}

export default RightDrawerMailView;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import SuggestionEditForm from './components/SuggestionEditForm';
import SuggestionActivityChatPanel from './components/SuggestionActivityChatPanel';
import { getSuggestionAction } from '../../../redux/entities/entities.actions';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import CustomTabContainer from '../../../components/CustomTabContainer/CustomTabContainer';
import { setCandidateEditViewActiveTabAction } from '../../../redux/candidatePage/candidatePage.actions';
import { themeOrange } from '../../../theme/theme';

interface IProps {
    id: number;
    initialTab?: number;
}

const SuggestionView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const suggestionId = props.id;

    const suggestion = useSelector((store: IStore) => store.entities.recruitingSuggestion.byId[suggestionId]);
    const [activeTab, setActiveTab] = useState(props.initialTab || 0);
    const suggestionLoaded = suggestionId > 0 && suggestion !== undefined && suggestionId === suggestion.id;

    useEffect(() => {
        if (suggestionId > 0) {
            dispatch(getSuggestionAction(token, suggestionId));
        }
    }, [suggestionId]);

    if (!open) {
        return null;
    }

    if (!suggestionLoaded || !suggestion) {
        return <>
            <div className={"flexContainerRow flex"}>
                <CustomBackdropLoadingInsideDiv open={true} />
            </div>
        </>;
    }

    return <>
        <div className={"flexContainerRow flex"} style={{width: 900}}>
            <CustomTabContainer
                activeTab={activeTab}
                roundBorder
                color={themeOrange.palette.primary.main}
                onTabChange={(tab) => {
                    setActiveTab(tab);
                    dispatch(setCandidateEditViewActiveTabAction(tab))
                }}
                tabs={[{
                    label: 'Historie',
                    component: <>
                        <SuggestionActivityChatPanel suggestionId={suggestionId}/>
                    </>
                }, {
                    label: 'Stammdaten',
                    component: <>
                        <SuggestionEditForm suggestionId={suggestionId}/>
                    </>
                }]}
            />
        </div>
    </>;
};

export default SuggestionView;

import React, { ReactNode, useState } from 'react';
import { COLOR_BLUE, COLOR_GREEN, COLOR_ORANGE, COLOR_RED } from '../../../../theme/theme';
import { translate } from '../../../../translation/translate.utils';
import { ICustomerRecruitingListModel } from '../../../../definitions/entities.customer.definitions';
import DateTimeCellRenderer from '../../../../components/CustomCellRenderer/DateTimeCellRenderer';
import DateCellRenderer from '../../../../components/CustomCellRenderer/DateCellRenderer';
import {
    EActivityModule,
    ERecruitingAbortedBy,
    ERecruitingStatus,
    IRecruitingListModel,
} from '../../../../definitions/entities.definition';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from '@material-ui/lab';
import DateDiffCellRenderer from '../../../../components/CustomCellRenderer/DateDiffCellRenderer';
import { getDayDifference, getFormattedDateString } from '../../../../utils/date.utils';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import { IconButton, Link } from '@material-ui/core';
import DisplayCustomerInquiriesView from './DisplayCustomerInquiriesView';
import { setUiActivityAddDataAction, setUiActivityToEditAction } from '../../../../redux/ui/ui.actions';
import { useDispatch } from 'react-redux';
import YesNoCellRenderer from '../../../../components/CustomCellRenderer/YesNoCellRenderer';

interface ITimelineEntry {
    content: ReactNode;
    title: null|ReactNode;
    dotColor: 'blue'|'orange'|'grey'|'green'|'red';
    showInInterviewOverview?: boolean;
}

const getTimelineElementsOfRecruiting = (recruiting: ICustomerRecruitingListModel|IRecruitingListModel, allowOpenInterview: boolean): ITimelineEntry[] => {
    const dispatch = useDispatch();
    const interviews = recruiting.timeline;
    const elements: ITimelineEntry[] = [];
    const interviewAndOfferElements: ITimelineEntry[] = interviews.map((timelineRecord, index) => {
        const actionRequired = index === 0 && recruiting.actionRequired;
        const warning = recruiting.status === ERecruitingStatus.STATUS_INTERVIEW && actionRequired;

        if (timelineRecord.offerOrInterview === 'offer') {
            return {
                dotColor: 'grey',
                content: <div className={"flexContainerColumn"}>
                    {allowOpenInterview
                        ? <Link style={{color: warning ? COLOR_ORANGE : undefined}} onClick={(event) => {
                            if (timelineRecord.id === 0) {
                                dispatch(setUiActivityAddDataAction({
                                    add: true,
                                    module: EActivityModule.recruiting,
                                    companyId: (recruiting as IRecruitingListModel).company.id,
                                    vacancyId: (recruiting as IRecruitingListModel).vacancy ? (recruiting as IRecruitingListModel).vacancy?.id : undefined,
                                    candidateId: (recruiting as IRecruitingListModel).candidate.id,
                                    recruitingId: recruiting.id,
                                    preSelectedEmployee: (recruiting as IRecruitingListModel).responsibleEmployee,
                                    isInterview: true,
                                    contactId: (recruiting as IRecruitingListModel).nextInterviewContact?.id,
                                    interviewType: recruiting.nextInterviewType
                                }));
                                return;
                            }
                            dispatch(setUiActivityToEditAction(timelineRecord.id));
                            event.stopPropagation();
                        }}>Vertragsangebot</Link>
                        : "Vertragsangebot"
                    }
                    - in höhe von {timelineRecord.contractOfferSalary} k€ p. a.
                </div>,
                title: <DateCellRenderer date={timelineRecord.dateTime}/>
            }
        }

        const header = <>VG{timelineRecord.round}&nbsp;{translate('pages.recruiting.interviewTypeValue.' + timelineRecord.type)}</>;
        return {
            showInInterviewOverview: (index === 0 || (!timelineRecord.cancelled && !timelineRecord.feedbackExists)),
            dotColor: warning ? 'orange' : 'grey',
            content: <div className={"flexContainerColumn"}>
                {allowOpenInterview
                    ? <Link style={{color: warning ? COLOR_ORANGE : undefined}} onClick={(event) => {
                        if (timelineRecord.id === 0) {
                            dispatch(setUiActivityAddDataAction({
                                add: true,
                                module: EActivityModule.recruiting,
                                companyId: (recruiting as IRecruitingListModel).company.id,
                                vacancyId: (recruiting as IRecruitingListModel).vacancy ? (recruiting as IRecruitingListModel).vacancy?.id : undefined,
                                candidateId: (recruiting as IRecruitingListModel).candidate.id,
                                recruitingId: recruiting.id,
                                preSelectedEmployee: (recruiting as IRecruitingListModel).responsibleEmployee,
                                isInterview: true,
                                contactId: (recruiting as IRecruitingListModel).nextInterviewContact?.id,
                                interviewType: recruiting.nextInterviewType
                            }));
                            return;
                        }
                        dispatch(setUiActivityToEditAction(timelineRecord.id));
                        event.stopPropagation();
                    }}>{header}</Link>
                    : header
                }

                {(timelineRecord.cancelled && index > 0) &&
                    <>(abgesagt)</>
                }
                {(timelineRecord.contractOffered) &&
                    <>- Kandidat hat Vertragsangebot in höhe von {timelineRecord.contractOfferSalary} k€ p. a. erhalten<br/><br/></>
                }
                {(!timelineRecord.cancelled && !timelineRecord.feedbackExists && timelineRecord.dateTime !== 'MISSING' && getDayDifference(timelineRecord.dateTime) >= 0) &&
                    <>(Feedback ausstehend)</>
                }
                {(actionRequired && timelineRecord.cancelled && index === 0) &&
                    <>(abgesagt & kein neuer Termin)</>
                }
            </div>,
            title: timelineRecord.dateTime !== 'MISSING' ? <>
                {timelineRecord.cancelled
                    ? <>abgesagt, ursprünglich geplant für {getFormattedDateString(timelineRecord.dateTime)}</>
                    : <>{!timelineRecord.cancelled && index === 0 && recruiting.status === ERecruitingStatus.STATUS_INTERVIEW
                        ? <DateTimeCellRenderer date={timelineRecord.dateTime}/>
                        : <DateCellRenderer date={timelineRecord.dateTime}/>
                    }</>
                }
            </> : <>Noch kein Termin festgesetzt</>
        }
    });


    const suggestElement: ITimelineEntry = {
        title: <><DateCellRenderer date={recruiting.created}/>,&nbsp;<DateDiffCellRenderer date={recruiting.created}/></>,
        dotColor: 'blue',
        content: 'vorgeschlagen'
    }

    switch (recruiting.status) {
        case ERecruitingStatus.STATUS_CONTRACT:
        case ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD:
        case ERecruitingStatus.STATUS_FINISH:
            return [
                {
                    title: recruiting.contractSigningDate && <DateCellRenderer date={recruiting.contractSigningDate}/>,
                    dotColor: 'green',
                    content: <>
                        {recruiting.contractBeginningDate &&
                            <div className={"flexContainerRow"}>
                                <div>Start: &nbsp;</div>
                                <DateCellRenderer date={recruiting.contractBeginningDate}/>
                            </div>
                        }
                        {recruiting.endOfTrialPeriod &&
                            <div className={"flexContainerRow"}>
                                <div>Probezeit bis: &nbsp;</div>
                                <DateCellRenderer date={recruiting.endOfTrialPeriod}/>
                            </div>
                        }
                        {(recruiting.finishedTrialPeriod !== null) &&
                            <div className={"flexContainerRow"}>
                                <div>Probezeit bestanden: &nbsp;</div>
                                <YesNoCellRenderer value={recruiting.finishedTrialPeriod}/>
                            </div>
                        }
                        {recruiting.contractSalary &&
                            <div className={"flexContainerRow"}>
                                <div>Gehalt: &nbsp;</div>{recruiting.contractSalary/1000}k p.a.
                            </div>
                        }
                    </>
                },
                ...interviewAndOfferElements,
                suggestElement
            ];
        case ERecruitingStatus.STATUS_ABORT:
            return [
                {
                    title: recruiting.dateOfAbort &&
                        <div className={"formContent"} style={{flexDirection: 'row', color: 'unset'}}>
                            <DateCellRenderer date={recruiting.dateOfAbort}/>
                        </div>,
                    dotColor: 'red',
                    content: <>
                        Absage von {recruiting.abortedBy === ERecruitingAbortedBy.CANDIDATE ? 'Kandidat' : 'Firma'}<br/>
                        <div className={"formContent"} style={{flexDirection: 'row', color: 'unset'}}>
                            {translate('pages.vacancy.declineRecruitingReasonValues.' + recruiting.declineReason)}
                        </div>
                    </>
                },
                ...interviewAndOfferElements,
                suggestElement
            ];
        case ERecruitingStatus.STATUS_INTERVIEW: {
            return [
                ...interviewAndOfferElements,
                suggestElement
            ];
        }
        case ERecruitingStatus.STATUS_SUGGEST:
            return [{
                title: <><DateCellRenderer date={recruiting.created}/>,&nbsp;<DateDiffCellRenderer date={recruiting.created}/></>,
                dotColor: 'orange',
                content: <>vorgeschlagen<br/>(Feedback ausstehend)</>
            }]
    }

    return elements;
}

const colorMap = {
    'blue': COLOR_BLUE,
    'orange': COLOR_ORANGE,
    'grey': undefined,
    'green': COLOR_GREEN,
    'red': COLOR_RED,
}

interface IProps {
    recruiting: ICustomerRecruitingListModel|IRecruitingListModel;
    allowOpenInterview?: boolean;
}

const RecruitingStatusTimelineCellRenderer: React.FC<IProps> = (props) => {
    const allowOpenInterview = props.allowOpenInterview || false;
    const recruiting = props.recruiting;
    const timelineElements = getTimelineElementsOfRecruiting(recruiting, allowOpenInterview);
    const [expanded, setExpanded] = useState(false);
    const [displayInquiries, setDisplayInquiries] = useState(false);

    return <div className={"flexContainerColumn"} style={{width:'100%'}}>
        {recruiting.inquiries.length > 0 &&
            <div className={'formContent marginBottom10'}>
                <DisplayCustomerInquiriesView
                    inquiries={recruiting.inquiries}
                    open={displayInquiries}
                    onClose={() => setDisplayInquiries(false)}
                />
                <Link href={"#"} onClick={() => setDisplayInquiries(true)}>
                    Offene Rückfragen:&nbsp;{recruiting.inquiries.length}
                </Link>
            </div>
        }
        <Timeline style={{padding: 'unset',marginTop: 7, marginBottom: 0}} >
            {timelineElements.map((entry,index) => {
                const showInOverview = index === 0 || recruiting.status === 'interview' && entry.showInInterviewOverview;

                if (!expanded && !showInOverview) {
                    return null;
                }

                const collapsable = index === 0 && timelineElements.length > 1;
                const isFirstElement = index+1 === timelineElements.length;

                const collapseFunction = () => {
                    if (!collapsable) {
                        return;
                    }
                    setExpanded(!expanded);
                }

                return <TimelineItem key={index} style={!expanded || isFirstElement ? {minHeight: 'auto'} : {}}>
                    <TimelineOppositeContent style={{flex: 'unset', padding: 'unset'}}>&nbsp;</TimelineOppositeContent>
                    <TimelineSeparator style={{minWidth:24}}>
                        <TimelineDot color={"grey"} style={{alignSelf: 'center', backgroundColor: colorMap[entry.dotColor]}}>
                            {entry.dotColor === 'green' && IconFactory.getIconComponent(EIcons.ThumbUp, {width: 12, height: 12})}
                            {entry.dotColor === 'red' && IconFactory.getIconComponent(EIcons.ThumbDown, {width: 12, height: 12})}
                        </TimelineDot>
                        {(!isFirstElement && expanded) &&
                            <TimelineConnector />
                        }
                    </TimelineSeparator>
                    <TimelineContent
                        className={"flexContainerRow"}
                        style={{marginTop:-10, color: index === 0 ? colorMap[entry.dotColor] : undefined}}
                    >
                        <div className={"flexContainerColumn flex1"} onClick={collapseFunction}  style={{cursor: collapsable ? 'pointer' : undefined}}>
                            <div className={"formContent"} style={{flexDirection: 'row', color: 'unset'}}>
                                {entry.title}
                            </div>
                            {entry.content}
                        </div>
                        {(index === 0 && timelineElements.length > 1) &&
                            <IconButton onClick={collapseFunction} style={{alignSelf: "flex-start"}}>
                                {IconFactory.getIconComponent(expanded ? EIcons.ExpandLess : EIcons.ExpandMore)}
                            </IconButton>
                        }
                    </TimelineContent>
                </TimelineItem>
            })}
        </Timeline>
    </div>;
};

export default RecruitingStatusTimelineCellRenderer;

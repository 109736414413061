import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { EIcons, IconFactory } from '../../Icons/IconFactory';
import { Badge, Button } from '@material-ui/core';
import { setUiFilterPanelClosed, setUiFilterPanelOpen } from '../../../redux/ui/ui.actions';
import { EEntities } from '../../../definitions/entities.definition';
import { getFilterMenuBadgeCount } from '../../../selectors/entity.selectors';

interface IProps {
    entity: EEntities;
}

const CustomOpenFilterPanelButton: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const filterMenuCount = useSelector((store: IStore) => getFilterMenuBadgeCount(store, props.entity));
    const openFilterPanels = useSelector((store: IStore) => store.ui.filterPanelsOpened);

    const toggleFilterPanel = useCallback(() => {
        if (!openFilterPanels.includes(props.entity)) {
            dispatch(setUiFilterPanelOpen(props.entity));
            return;
        }

        dispatch(setUiFilterPanelClosed(props.entity));
    }, [props.entity, openFilterPanels]);

    const notInTopBar = false;

    return <>
        <Button
            variant={notInTopBar ? "outlined" : "contained"}
            color={notInTopBar ? "inherit" : "secondary"}
            onClick={toggleFilterPanel}
            startIcon={IconFactory.getIconComponent(EIcons.Filter)}
            style={notInTopBar ? {borderColor: 'rgba(233, 237, 239, 0.2)', color: 'rgb(187, 187, 187)'} : {}}
        >
            Filter
            { (filterMenuCount !== undefined && filterMenuCount>0) &&
                <>&nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={filterMenuCount}/></>
            }
        </Button>
    </>
}

export default React.memo(CustomOpenFilterPanelButton);

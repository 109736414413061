import React, { memo } from 'react';
import { IRecruiting, IRecruitingPartial } from '../../definitions/entities.definition';
import { Chip, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { useDispatch } from 'react-redux';
import styles from './VacancyCellRenderer.module.css';
import { darker } from '../../theme/theme';
import { translate } from '../../translation/translate.utils';
import { EEntityView } from '../../definitions/ui.definitions';
import { setUiCloseTopDrawer, setUiEditView } from '../../redux/ui/ui.actions';

interface IProps {
    recruiting?: IRecruiting|IRecruitingPartial;
    showCandidateName?: boolean;
    onDelete?: () => void;
}

const RecruitingCellRenderer: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const recruiting = props.recruiting;

    if (!recruiting) {
        return (<>
            {translate('misc.noInformation')}
        </>);
    }


    let label = <>{translate('misc.openRecruiting')}</>;
    if (props.showCandidateName) {
        label = <>Prozess<div className={"formContent"} style={{color: 'var(--brainz-darker)',fontSize: 10}}>{recruiting.candidate.fullName}</div></>;
    }

    return (
        <Tooltip title={translate('misc.openRecruiting')} placement="top-start">
            <Chip
                icon={IconFactory.getIconComponent(EIcons.ThumbsUpDown, {color: darker})}
                color="primary"
                className={styles.chip}
                deleteIcon={props.onDelete ? IconFactory.getIconComponent(EIcons.Close, {color: darker}) : undefined}
                onDelete={props.onDelete}
                label={label}
                onClick={(event) => {
                    event.stopPropagation();
                    dispatch(setUiCloseTopDrawer());
                    dispatch(setUiEditView(EEntityView.recruiting, recruiting.id));
                }}
            />
        </Tooltip>
    );
};

export default memo(RecruitingCellRenderer);

import React, { useEffect, useState } from 'react';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { translate } from '../../../../translation/translate.utils';
import EmployeeSizeDropDown from '../../../../components/CustomInput/CustomDropDownInput/EmployeeSizeDropDown';
import {
    EEntities,
    ETermsOfPayment,
    ICompanyForApi,
    ICompanyPartial,
    IEmployee,
    IEmployeePartial,
} from '../../../../definitions/entities.definition';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import { addCompanyAction, updateCompanyAction } from '../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import BranchAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/Legacy/BranchAutocompleteDropDown';
import { getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import { setCompanyPageCompanyToDeleteAction } from '../../../../redux/companyPage/companyPage.actions';
import CustomMailField from '../../../../components/CustomInput/CustomMailField';
import LegacyEmployeeAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import LegacyCompanyAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCompanyAutocompleteDropDown';
import TermsOfPaymentDropDown from '../../../../components/CustomInput/CustomDropDownInput/TermsOfPaymentDropDown';
import AbcRatingDropDown from '../../../../components/CustomInput/CustomDropDownInput/AbcRatingDropDown';
import { getLoggedInEmployeeFromStore, userHasRole } from '../../../../selectors/employee.selectors';
import LegacyYesNoDropDown from '../../../../components/CustomInput/CustomDropDownInput/LegacyYesNoDropDown';
import BillingConfigurationElement from '../../../Shared/BillingSetting/BillingConfigurationElement';
import { ROLE_ADMIN } from '../../../../definitions/actor.definitions';
import CustomUrlField from '../../../../components/CustomInput/CustomUrlField';
import CustomValidationErrors from '../../../../components/CustomComponent/CustomValidationErrors';

interface IProps {
    companyId: number;
}

const CompanyEditForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector((store: IStore) => store.actor.token);
    const errors = useSelector(getValidationErrorsFromStore);
    const userIsAdmin = userHasRole(ROLE_ADMIN);
    const companyId = props.companyId;
    const company = useSelector((store: IStore) => store.entities.companies.byId[companyId]);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);

    const [parentCompany, setParentCompany] = useState<undefined|ICompanyPartial>(company?.parentCompany);
    const [name, setName] = useState(company?.name || "");
    const [short, setShort] = useState(company?.short || "");
    const [taxNumber, setTaxNumber] = useState(company?.taxNumber || "");
    const [salesTaxIdentificationNumber, setSalesTaxIdentificationNumber] = useState(company?.salesTaxIdentificationNumber || "");
    const [comment, setComment] = useState(company?.comment || "");
    const [rating, setRating] = useState(company?.rating || 1);
    const [isCustomer, setIsCustomer] = useState<boolean>(company ? company.isCustomer : true);
    const [employeeSizeCategory, setEmployeeSizeCategory] = useState(company?.employeeSizeCategory || null);
    const [homepageUrl, setHomepageUrl] = useState(company?.homepageUrl || "");
    const [mailAddress, setMailAddress] = useState(company?.mailAddress || "");
    const [phoneNumber, setPhoneNumber] = useState(company?.phoneNumber || "");
    const [branches, setBranches] = useState(company?.branches || []);
    const [termsOfPayment, setTermsOfPayment] = useState(company?.termsOfPayment?.id || ETermsOfPayment.Days14);
    const [responsibleEmployee, setResponsibleEmployee] = useState(company?.responsibleEmployee || loggedInEmployee);
    const [shareWithEmployees, setShareWithEmployees] = useState<IEmployeePartial[]>(company?.shareWithEmployees || []);

    useEffect(() => {
        if (!company) {
            return;
        }
        setName(company.name);
        setShort(company.short);
        setTaxNumber(company.taxNumber);
        setSalesTaxIdentificationNumber(company.salesTaxIdentificationNumber);
        setComment(company.comment);
        setRating(company.rating);
        setIsCustomer(company.isCustomer);
        setEmployeeSizeCategory(company.employeeSizeCategory);
        setHomepageUrl(company.homepageUrl);
        setMailAddress(company.mailAddress);
        setPhoneNumber(company.phoneNumber);
        setBranches(company.branches);
        setTermsOfPayment(company.termsOfPayment?.id || ETermsOfPayment.Days14);
        setResponsibleEmployee(company.responsibleEmployee);
        setParentCompany(company.parentCompany);
        setShareWithEmployees(company.shareWithEmployees);
    }, [company])


    const onSave = () => {
        const branchIds: number[] = branches.map(({ id }) => id);

        const companyApiObject: ICompanyForApi = {
            name,
            short,
            taxNumber,
            salesTaxIdentificationNumber,
            comment,
            rating,
            employeeSizeCategory,
            homepageUrl,
            mailAddress,
            phoneNumber,
            branchIds,
            responsibleEmployeeId: responsibleEmployee ? responsibleEmployee.id : undefined,
            parentCompanyId: parentCompany?.id || undefined,
            termsOfPaymentId: termsOfPayment,
            shareWithEmployeeIds: shareWithEmployees.map((employee) => employee.id),
            isCustomer
        };

        if (!company) {
            dispatch(addCompanyAction(token, companyApiObject));
            return;
        }

        companyApiObject.id = company.id;
        dispatch(updateCompanyAction(token, companyApiObject));
        return;
    };

    const onDelete = () => {
        if (!company) {
            return;
        }
        dispatch(setCompanyPageCompanyToDeleteAction(company.id));
    }

    return <>
        <CustomValidationErrors translationKey={'pages.company'}/>
        <div style={{ height: "100%", width: "100%", overflow: "auto", flex: 1 }}>
            <PaperRow>
                <CustomTextField
                    required={true}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    value={name}
                    onChange={(value) => setName(value)}
                    label={translate('pages.company.properties.name')}
                />
                <div className={"gap5"} style={{display:"flex", flexDirection: "row", flex: 1}}>
                    <CustomTextField
                        inputProps={{ maxLength: 10 }}
                        required={true}
                        error={Boolean(errors.short)}
                        helperText={errors.short}
                        value={short}
                        onChange={(value) => setShort(value)}
                        label={translate('pages.company.properties.short')}
                    />
                    <AbcRatingDropDown
                        onChange={(event: any) => setRating(event.target.value)}
                        value={rating}
                        required={true}
                        error={Boolean(errors.rating)}
                        helperText={errors.rating}
                    />
                    <LegacyYesNoDropDown
                        hideChoose
                        value={isCustomer}
                        onChange={(event) => {
                            const value = event.target.value;
                            setIsCustomer(value === 1);
                        }}
                        label={translate('pages.company.isCustomer')}
                    />
                </div>
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    value={taxNumber}
                    onChange={(value) => setTaxNumber(value)}
                    label={translate('pages.company.properties.taxNumber')}
                />
                <CustomTextField
                    value={salesTaxIdentificationNumber}
                    onChange={(value) => setSalesTaxIdentificationNumber(value)}
                    label={translate('pages.company.properties.salesTaxIdentificationNumber')}
                />
            </PaperRow>
            <PaperRow>
                <EmployeeSizeDropDown
                    onChange={(event: any) => setEmployeeSizeCategory(event.target.value)}
                    value={employeeSizeCategory}
                />
                <CustomUrlField
                    value={homepageUrl}
                    onChange={(value) => setHomepageUrl(value)}
                    label={translate('pages.company.properties.homepageUrl')}
                />
            </PaperRow>
            <PaperRow>
                <CustomMailField
                    value={mailAddress}
                    onChange={(value) => setMailAddress(value)}
                    label={translate('pages.company.properties.mailAddress')}
                />
                <CustomTextField
                    value={phoneNumber}
                    onChange={(value) => setPhoneNumber(value)}
                    label={translate('misc.phoneNumber')}
                />
            </PaperRow>
            <PaperRow>
                <LegacyCompanyAutocompleteDropDown
                    label={translate('pages.company.parentCompany')}
                    value={parentCompany}
                    onChange={(event, value) => setParentCompany(value)}
                />
                <LegacyEmployeeAutocompleteDropDown
                    required={true}
                    label={translate('pages.vacancy.properties.responsibleEmployee')}
                    value={responsibleEmployee}
                    onChange={(event, value: IEmployee) => setResponsibleEmployee(value)}
                    error={Boolean(errors.responsibleEmployeeId)}
                    helperText={errors.responsibleEmployeeId}
                />
                <LegacyEmployeeAutocompleteDropDown
                    label={'Teilen mit'}
                    multiple={true}
                    value={shareWithEmployees}
                    onChange={(event, value: IEmployeePartial[]) => setShareWithEmployees(value)}
                />
            </PaperRow>
            <PaperRow>
                <BranchAutocompleteDropDown
                    required={true}
                    error={Boolean(errors.branchIds)}
                    helperText={errors.branchIds}
                    value={branches}
                    onChange={(branches) =>
                    setBranches(branches)}
                />
                <TermsOfPaymentDropDown
                    label={"Standard Zahlungsbedingung"}
                    required={true}
                    error={Boolean(errors.termsOfPaymentId)}
                    helperText={errors.termsOfPaymentId}
                    value={termsOfPayment}
                    onChange={(event) => setTermsOfPayment(event.target.value)}
                />
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    multiline
                    rows={10}
                    value={comment}
                    onChange={(value) => setComment(value)}
                    label={translate('misc.comment')}
                />
            </PaperRow>
            { isCustomer && userIsAdmin && company &&
                <PaperRow>
                    <BillingConfigurationElement
                        billingSetting={company}
                        entity={EEntities.Company}
                        entityId={company.id}
                    />
                </PaperRow>
            }
        </div>
        <CustomDialogActions
            buttons={[{
                void: onDelete,
                hidden: !company,
                label: translate('misc.buttons.delete')
            }, {
                void: () => onSave(),
                label: translate('misc.buttons.save')
            }]}
        />
    </>;
};

export default CompanyEditForm;

import React, { PropsWithChildren } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IListActionButtonConfig } from '../../definitions/components.definitions';
import { IconFactory } from '../Icons/IconFactory';
import { translate } from '../../translation/translate.utils';
import { TBrainzEntity } from '../../definitions/entities.definition';
import { ListSubheader } from '@material-ui/core';

const initialState = {
    mouseX: null,
    mouseY: null,
};

interface IProps extends PropsWithChildren<any> {
    index: number;
    entityId: number;
    entity: TBrainzEntity;
    menuItems: IListActionButtonConfig[]
    menuHeading?: (entity: TBrainzEntity) => string;
    onMenuOpen?: () => void;
    style?: object
    variant?: 'onClick'|'onContextMenu';
}

const CustomContextMenu: React.FC<IProps> = (props) => {
    const variant = props.variant || 'onContextMenu';
    const [state, setState] = React.useState<{
        mouseX: null | number;
        mouseY: null | number;
    }>(initialState);
    const menuItems = (props.menuItems || []).filter((actionButton) => !actionButton.condition || actionButton.condition(props.entity));

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (variant === 'onContextMenu' && event.target && (event.target.nodeName === 'INPUT' || event.target.nodeName === 'TEXTAREA')) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        if (state.mouseY) {
            setState(initialState);
            return;
        }

        setState(initialState);
        if (props.onMenuOpen) {
            props.onMenuOpen();
        }

        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const handleClose = () => {
        setState(initialState);
    };

    return <>
        <div
            onContextMenu={variant === 'onContextMenu' ? handleClick : undefined}
            onClick={variant === 'onClick' ? handleClick : undefined}
            style={props.style}
        >
            {props.children}
            {menuItems.length > 0 &&
                <Menu
                    open={state.mouseY !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                    }
                >
                    {props.menuHeading &&
                        <ListSubheader component="div" id="nested-list-subheader">
                            {props.menuHeading(props.entity)}
                        </ListSubheader>
                    }
                    {menuItems.map((menuItem: IListActionButtonConfig, index) => {
                        return (
                            <MenuItem
                                key={"actionButton" + menuItem.translationKey || menuItem.label}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (menuItem.action) {
                                        menuItem.action(props.entityId, props.entity, props.index, event);
                                        handleClose();
                                    }
                                }}
                            >
                                {menuItem.icon &&
                                    <>{IconFactory.getIconComponent(menuItem.icon)}&nbsp;</>
                                }
                                {menuItem.translationKey ? translate(menuItem.translationKey) : menuItem.label}
                            </MenuItem>
                        );
                    })}
                </Menu>
            }
        </div>
    </>;
};

export default CustomContextMenu;

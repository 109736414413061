import React, { CSSProperties, ReactNode } from 'react';
import Paper from '@material-ui/core/Paper';
import { EEntities, TBrainzEntity } from '../../definitions/entities.definition';
import CustomTableHeader from './CustomTableHeader';
import CustomTableBody from './CustomTableBody';
import {
    ICustomTableColumnConfig,
    IListActionButtonConfig,
    ITableHeaderCallbacks,
    TListAction,
} from '../../definitions/components.definitions';
import { connect } from 'react-redux';
import { setCustomTableClickedRow } from '../../redux/ui/ui.actions';
import CustomTableFooter from './CustomTableFooter';
import { darker } from '../../theme/theme';
import CustomTableBodyDragAndDrop from './CustomTableBodyDragAndDrop';
import CustomTableBodyGrouped from './CustomTableBodyGrouped';

export interface ICustomTableConfig {
    loading?: boolean;
    hideHeader?: boolean;
    entity: EEntities;
    columnConfig: ICustomTableColumnConfig;
    sortedEntries: TBrainzEntity[];
    emptyText?: string|ReactNode;
    listActionHeading?: (entity: TBrainzEntity) => string;
    listActionButtons?: IListActionButtonConfig[];
    tableHeaderCallbacks?: ITableHeaderCallbacks;
    scrollCallback?: VoidFunction;
    onRowClick?: TListAction;
    onRowDoubleClick?: TListAction;
    onReload?: () => void;
    count?: number;
    total?: number;
    footerText?: string;
    color?: string;
    denyVerticalCenter?: boolean;
    groupBy?: (entity: TBrainzEntity) => string;
    dragAndDrop?: boolean;
    isRecordDraggable?: (record: TBrainzEntity) => boolean;
    groupHeaderExtraInformation?: (entities: TBrainzEntity[]) => ReactNode;
    roundBorder?: boolean;
}

export interface ICustomTableProps {
    style?: CSSProperties
    config: ICustomTableConfig;
    onUnmount: (entryId?: number) => void;
}

const styleToUse: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: darker,
    flex: 1,
    overflow: "hidden",
    overflowX: "auto",
    padding: 5,
    border: 'unset',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
};

class CustomTable extends React.Component<ICustomTableProps, any> {
    style: React.CSSProperties;

    constructor(props: ICustomTableProps) {
        super(props);

        this.style = {...styleToUse, ...props.style};
    }

    shouldComponentUpdate(prevProps: ICustomTableProps) {
        return true;

        //todo: logik optimieren. Mit der unteren logik klappt das sortieren in den rekrutierungs-listen nicht mehr
        const loadingChanged = this.props.config.loading !== prevProps.config.loading;
        const newElements = this.props.config.sortedEntries.length !== prevProps.config.sortedEntries.length;

        if (loadingChanged || newElements) {
            return true;
        }

        return JSON.stringify(this.props.config.sortedEntries) == JSON.stringify(prevProps.config.sortedEntries);
    }

    componentWillUnmount() {
        this.props.onUnmount(undefined);
    }

    getBodyComponent() {
        if (this.props.config.sortedEntries.length === 0 && !this.props.config.loading) {
            return <>
                <div style={{height: '100%', borderLeft: '1px solid rgba(0, 0, 0, 0.12)', borderRight: '1px solid rgba(0, 0, 0, 0.12)'}}>
                    <div style={{padding:5}}>
                        { this.props.config.emptyText || 'Es konnten keine Daten gefunden werden' }
                    </div>
                </div>
            </>
        }

        if (this.props.config.groupBy) {
            return <CustomTableBodyGrouped
                denyVerticalCenter={this.props.config.denyVerticalCenter}
                entity={this.props.config.entity}
                loading={this.props.config.loading}
                sortedEntries={this.props.config.sortedEntries}
                emptyText={this.props.config.emptyText}
                listActionButtons={this.props.config.listActionButtons}
                listActionHeading={this.props.config.listActionHeading}
                columnConfig={this.props.config.columnConfig}
                scrollCallback={this.props.config.scrollCallback}
                onDoubleClick={this.props.config.onRowDoubleClick}
                onClick={this.props.config.onRowClick}
                groupBy={this.props.config.groupBy}
                groupHeaderExtraInformation={this.props.config.groupHeaderExtraInformation}
            />
        }

        if (this.props.config.dragAndDrop) {
            return <CustomTableBodyDragAndDrop
                denyVerticalCenter={this.props.config.denyVerticalCenter}
                entity={this.props.config.entity}
                loading={this.props.config.loading}
                sortedEntries={this.props.config.sortedEntries}
                emptyText={this.props.config.emptyText}
                listActionButtons={this.props.config.listActionButtons}
                listActionHeading={this.props.config.listActionHeading}
                columnConfig={this.props.config.columnConfig}
                scrollCallback={this.props.config.scrollCallback}
                onDoubleClick={this.props.config.onRowDoubleClick}
                onClick={this.props.config.onRowClick}
                isRecordDraggable={this.props.config.isRecordDraggable}
            />
        }

        return <CustomTableBody
            denyVerticalCenter={this.props.config.denyVerticalCenter}
            entity={this.props.config.entity}
            loading={this.props.config.loading}
            sortedEntries={this.props.config.sortedEntries}
            emptyText={this.props.config.emptyText}
            listActionButtons={this.props.config.listActionButtons}
            listActionHeading={this.props.config.listActionHeading}
            columnConfig={this.props.config.columnConfig}
            scrollCallback={this.props.config.scrollCallback}
            onDoubleClick={this.props.config.onRowDoubleClick}
            onClick={this.props.config.onRowClick}
        />
    }

    render() {
        return (
            <Paper variant={'outlined'} square style={this.style}>
                {!this.props.config.hideHeader &&
                    <CustomTableHeader
                        entity={this.props.config.entity}
                        tableHeaderCallbacks={this.props.config.tableHeaderCallbacks}
                        columnConfig={this.props.config.columnConfig}
                        countActionButtons={this.props.config.listActionButtons?.length ?? 0}
                        color={this.props.config.color}
                        roundBorder={this.props.config.roundBorder}
                    />
                }
                {this.getBodyComponent()}
                <CustomTableFooter
                    onReload={this.props.config.onReload}
                    loading={this.props.config.loading}
                    count={this.props.config.count}
                    totalCount={this.props.config.total}
                    text={this.props.config.footerText}
                />
            </Paper>
        );
    }
}

const mapDispatch = {
    onUnmount: setCustomTableClickedRow
};

// @ts-ignore-next-line
const connected = connect(null, mapDispatch)(CustomTable);
export default connected;

import React from 'react';
import Moment from 'moment';
import { Tooltip } from '@material-ui/core';
import { getFormattedDateWithDayString } from '../../utils/date.utils';

interface IProps {
    date: string;
    withoutLeadingText?: boolean;
    beforeText?: string;
    hideTooltip?: boolean;
}

const DateDiffCellRenderer: React.FC<IProps> = (props) => {
    if (props.date === null) {
        return null;
    }

    const hideTooltip = props.hideTooltip;
    const today = Moment();
    const yesterday = Moment().subtract('1', 'day');
    const date = Moment(props.date);

    if (date.format('Y-MM-DD') === today.format('Y-MM-DD')) {
        return <>Heute</>;
    }

    if (date.format('Y-MM-DD') === yesterday.format('Y-MM-DD')) {
        return <>Gestern</>;
    }

    const daysDifference = today.diff(date, 'days');
    const monthDifference = today.diff(date, 'month');
    const yearsDifference = today.diff(date, 'year');

    const getText = (daysDifference: number, monthDifference: number, yearDifference: number, withoutLeadingText?: boolean) => {
        const valueParts: string[] = [];
        if (!withoutLeadingText) {
            valueParts.push(daysDifference < 0 ? 'in' : props.beforeText || 'vor');
        }

        if (daysDifference < 0) {
            daysDifference = daysDifference*-1;
            monthDifference = monthDifference*-1;
        }

        if (yearDifference === 1) {
            valueParts.push('einem Jahr');
            return valueParts.join(' ');
        }

        if (yearDifference > 1) {
            valueParts.push(yearDifference + ' Jahren');
            return valueParts.join(' ');
        }

        if (monthDifference === 1) {
            valueParts.push('einem Monat');
            return valueParts.join(' ');
        }

        if (monthDifference > 1) {
            valueParts.push(monthDifference + ' Monaten');
            return valueParts.join(' ');
        }

        if (daysDifference === 1) {
            valueParts.push('einem Tag');
            return valueParts.join(' ');
        }

        if (daysDifference > 1) {
            valueParts.push(daysDifference + ' Tagen');
            return valueParts.join(' ');
        }
    }

    if (hideTooltip) {
        return <>
            <div>
                {getText(daysDifference, monthDifference, yearsDifference, props.withoutLeadingText)}
            </div>
        </>;
    }

    return <>
        <Tooltip title={getFormattedDateWithDayString(props.date, true)}>
            <div>
                {getText(daysDifference, monthDifference, yearsDifference, props.withoutLeadingText)}
            </div>
        </Tooltip>
    </>;
}

export default DateDiffCellRenderer;

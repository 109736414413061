import React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { EEntities, IVacancyListModel, TBrainzEntity } from '../../../definitions/entities.definition';
import { ECellAlign, ESortingOptions } from '../../../definitions/components.definitions';
import PinRating from '../../../components/CustomRating/PinRating';
import { translate } from '../../../translation/translate.utils';
import { Divider, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import UrlCellRenderer from '../../../components/CustomCellRenderer/UrlCellRenderer';
import YesNoCellRenderer from '../../../components/CustomCellRenderer/YesNoCellRenderer';
import CurrencyCellRenderer from '../../../components/CustomCellRenderer/CurrencyCellRenderer';
import {
    getDefaultCompanyColumn,
    getDefaultEmployeeColumn,
    getDefaultLastActivityColumn,
    INFINITE_SCROLL_INTERVAL,
} from '../../../utils/components.utils';
import { getToken } from '../../../selectors/app.selectors';
import { getOpenVacancyListModels, getVacancyPageOpenListRequestObject } from '../../../selectors/vacancy.selectors';
import { getFilterMenuBadgeCount } from '../../../selectors/entity.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { EEntityView } from '../../../definitions/ui.definitions';
import { setUiEditView } from '../../../redux/ui/ui.actions';
import {
    changeVacancyOrder,
    getAllOpenVacanciesAction,
    updateVacancyAction,
} from '../../../redux/entities/entities.actions';
import {
    setVacancyPageAddSuggestionAction,
    setVacancyPageFilterAction,
    setVacancyPageSortingOptionAction,
    setVacancyPageVacancyToArchiveAction,
    setVacancyPageVacancyToDeleteAction,
} from '../../../redux/vacancyPage/vacancyPage.actions';
import { setRecruitingPageStartRecruitingAction } from '../../../redux/recruitingPage/recruitingPage.actions';
import DateDiffCellRenderer from '../../../components/CustomCellRenderer/DateDiffCellRenderer';
import { COLOR_GREEN, COLOR_RED } from '../../../theme/theme';
import { getDayDifference } from '../../../utils/date.utils';

const getSuggestionDueDateIcon = (vacancy: IVacancyListModel) => {
    const suggestionDueDate = vacancy.suggestionDueDate as string;

    if (vacancy.hasProcessesForDueDate) {
        return IconFactory.getIconComponent(EIcons.CheckCircle, {color: COLOR_GREEN});
    }

    const dateDifference = getDayDifference(suggestionDueDate);
    if (dateDifference >= 0) {
        return IconFactory.getIconComponent(EIcons.Alarm, {color: COLOR_RED});
    }

    return IconFactory.getIconComponent(EIcons.HourglassEmpty);
}

const VacancyOpenList: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);

    const count = useSelector((store: IStore) => store.entities.vacancies.order.length);
    const total = useSelector((store: IStore) => store.entities.vacancies.totalOpen);
    const loading = useSelector((store: IStore) => store.ui.openVacanciesLoading);
    const sortedEntries = useSelector(getOpenVacancyListModels);
    const filter = useSelector((store: IStore) => store.vacancyPage.vacancyListFilter);
    const sorting = useSelector((store: IStore) => store.vacancyPage.vacancyListSorting);
    const requestObject = useSelector(getVacancyPageOpenListRequestObject);
    const order = useSelector((store: IStore) => store.entities.vacancies.order);
    const filterCount = useSelector((store: IStore) => getFilterMenuBadgeCount(store, EEntities.VacancyList));
    const draggable = filterCount === 0 && Object.keys(sorting)[0] === 'pin';

    const editAction = (vacancyId: number, index: number) => {
        dispatch(setUiEditView(EEntityView.vacancy, vacancyId));
    }

    const load = () => {
        dispatch(getAllOpenVacanciesAction(token, requestObject))
    }

    const onDragEnd = (result: DropResult) => {
        const vacancy = sortedEntries[result.source.index];
        if (!result.destination) {
            return;
        }

        const newOrder = order;
        const [reorderedItem] = newOrder.splice(result.source.index, 1);
        newOrder.splice(result.destination.index, 0, reorderedItem);

        dispatch(updateVacancyAction(
            token, {
                id: vacancy.id,
                ranking: sortedEntries[result.destination.index].ranking
            }
        ));

        if (filterCount && filterCount > 0) {
            load();
        } else {
            dispatch(changeVacancyOrder(newOrder));
        }
    };

    return <>
        <DragDropContext onDragEnd={onDragEnd}>
            <CustomTable
                config={{
                    entity: EEntities.VacancyList,
                    dragAndDrop: true,
                    isRecordDraggable: (entity: TBrainzEntity) => (entity as IVacancyListModel).pin && draggable,
                    onRowDoubleClick: (id, entity, index) => editAction(id, index),
                    footerText: 'Gepinnte: ' + sortedEntries.filter((vacancy: IVacancyListModel) => vacancy.pin).length,
                    listActionHeading: (entity) => {
                        const entityCast = entity as IVacancyListModel;
                        return entityCast.title;
                    },
                    listActionButtons: [{
                        action: (vacancyId) => dispatch(setVacancyPageAddSuggestionAction({
                            open: true,
                            vacancyId
                        })),
                        translationKey: 'pages.vacancy.addSuggestion',
                        icon: EIcons.Star,
                    }, {
                        action: (vacancyId) => dispatch(setRecruitingPageStartRecruitingAction({
                            open: true,
                            vacancyId
                        })),
                        translationKey: 'pages.vacancy.startRecruiting',
                        icon: EIcons.ThumbsUpDown,
                    }, {
                        action: (vacancyId) => dispatch(setVacancyPageVacancyToArchiveAction(vacancyId)),
                        translationKey: 'pages.vacancy.archiveDialog.title',
                        icon: EIcons.CloudOff,
                    }, {
                        action: (vacancyId) => dispatch(setVacancyPageVacancyToDeleteAction(vacancyId)),
                        translationKey: 'pages.vacancy.deleteDialog.title',
                        icon: EIcons.Delete,
                    }],
                    columnConfig: {
                        pin: {
                            header: '#',
                            property: 'pin',
                            width: 40,
                            align: ECellAlign.center,
                            cellRenderer: (pin: boolean, todo) => {
                                const vacancyCast = todo as IVacancyListModel;

                                return (
                                    <PinRating
                                        value={vacancyCast.pin}
                                        onChangePin={() => {
                                            dispatch(updateVacancyAction(
                                                token, {
                                                    id: vacancyCast.id,
                                                    pin: !vacancyCast.pin
                                                },
                                                () => load()
                                            ))
                                        }}
                                        tooltip={translate('pages.vacancy.pin')}
                                    />
                                );
                            }
                        },
                        company: getDefaultCompanyColumn(),
                        status: {
                            header: translate('pages.recruiting.status'),
                            width: 50,
                            align: ECellAlign.center,
                            property: 'status',
                            cellRenderer: (value, entity) => {
                                const entityCast = entity as IVacancyListModel;

                                if (entityCast.countOpenProcesses > 0) {
                                    return (
                                        <Tooltip title={translate('pages.vacancy.statusGreen')}>
                                            <div>{IconFactory.getIconComponent(EIcons.Dot, {color: 'green'})}</div>
                                        </Tooltip>
                                    );
                                }

                                if (entityCast.countOpenFavorites > 0) {
                                    return (
                                        <Tooltip title={translate('pages.vacancy.statusYellow')}>
                                            <div>{IconFactory.getIconComponent(EIcons.Dot, {color: 'yellow'})}</div>
                                        </Tooltip>
                                    );
                                }

                                return (
                                    <Tooltip title={translate('pages.vacancy.statusRed')}>
                                        <div>{IconFactory.getIconComponent(EIcons.Dot, {color: 'red'})}</div>
                                    </Tooltip>
                                );
                            }
                        },
                        title: {
                            header: translate('pages.vacancy.properties.title') + ' / ' + translate('misc.location'),
                            property: "title",
                            cellRenderer: (value, entity: TBrainzEntity) => {
                                const vacancy = entity as IVacancyListModel;

                                return <div className={"flexContainerColumn"}>
                                    {value}<br/>
                                    {vacancy.companyLocation &&
                                    <div className={"formContent"}>{vacancy.companyLocation.city}</div>
                                    }
                                </div>;
                            }
                        },
                        countOpenFavorites: {
                            header: 'Fav',
                            property: "countOpenFavorites",
                            width: 40,
                            align: ECellAlign.center
                        },
                        suggestionDueDate: {
                            header: 'Vorschlag bis',
                            property: "suggestionDueDate",
                            align: ECellAlign.right,
                            width: 130,
                            cellRenderer: (suggestionDueDate: string, entity) => {
                                const entityCast = entity as IVacancyListModel;
                                if (!suggestionDueDate) {
                                    return '-';
                                }

                                return <>
                                    {getSuggestionDueDateIcon(entityCast)}
                                    &nbsp;
                                    <Divider orientation={"vertical"}/>
                                    <div style={{width:80}}>
                                        <DateDiffCellRenderer date={suggestionDueDate} />
                                    </div>
                                </>
                            },
                        },
                        countProcesses: {
                            header: 'Proz',
                            property: "countProcesses",
                            width: 50,
                            align: ECellAlign.center,
                            cellRenderer: (value: number, entity: TBrainzEntity) => {
                                const vacancyListModel = entity as IVacancyListModel;

                                if (vacancyListModel.countAbortedProcesses > 0) {
                                    return (
                                        <div style={{float: 'right'}}>
                                            <div style={{float: 'left'}}>{vacancyListModel.countOpenProcesses} /</div>
                                            <div style={{float: 'left', color: 'red'}}>&nbsp;{vacancyListModel.countProcesses}</div>
                                        </div>
                                    );
                                }
                                return (
                                    <>
                                        {vacancyListModel.countOpenProcesses} / {vacancyListModel.countProcesses}
                                    </>
                                );
                            }
                        },
                        urlExtern: {
                            header: <div className="content" dangerouslySetInnerHTML={{__html: translate('pages.vacancy.urlExtern')}} />,
                            property: "webVacancy.online",
                            width: 70,
                            align: ECellAlign.center,
                            cellRenderer: (online: boolean, entity) => {
                                const vacancy = entity as IVacancyListModel;
                                const webVacancy = vacancy.webVacancy;

                                if (webVacancy) {
                                    return <UrlCellRenderer value={<YesNoCellRenderer value={online} />} url={webVacancy.url} hideLaunchIcon/>
                                }

                                if (vacancy.urlExtern) {
                                    return <UrlCellRenderer value={"Öffnen"} url={vacancy.urlExtern} hideLaunchIcon/>
                                }

                                return '-';
                            },
                        },
                        urlIntern: {
                            header: <div className="content" dangerouslySetInnerHTML={{__html: translate('pages.vacancy.urlIntern')}} />,
                            property: "url",
                            width: 70,
                            align: ECellAlign.center,
                            cellRenderer: (url?: string) => {
                                if (url) {
                                    return <UrlCellRenderer value={"Öffnen"} url={url} hideLaunchIcon/>
                                }

                                return '-';
                            },
                        },
                        possibleCommission: {
                            header: translate('pages.vacancy.possibleCommission'),
                            property: "possibleCommission",
                            width: 95,
                            align: ECellAlign.right,
                            cellRenderer: (commission: number) => {
                                if (commission) {
                                    return (<CurrencyCellRenderer value={commission} />)
                                }
                                return '-';
                            },
                        },
                        lastActivity: getDefaultLastActivityColumn(),
                        responsibleEmployee: getDefaultEmployeeColumn()
                    },
                    sortedEntries: sortedEntries,
                    loading: loading,
                    scrollCallback: () => {
                        if (filter.limit && filter.limit < total) {
                            dispatch(setVacancyPageFilterAction({
                                start: 0,
                                limit: filter.limit + INFINITE_SCROLL_INTERVAL,
                            }));
                        }
                    },
                    tableHeaderCallbacks: {
                        setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                            dispatch(setVacancyPageSortingOptionAction(
                                propertyToSort,
                                sortOption
                            ))
                    },
                    onReload: () => load(),
                    count: count,
                    total: total
                }}
            />
        </DragDropContext>
    </>;
}

export default React.memo(VacancyOpenList);

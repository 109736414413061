import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import CustomTextField from './CustomTextField';
import { Button, ClickAwayListener } from '@material-ui/core';
import styles from './CustomEditableValue.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { setForceExitFastEditAction } from '../../redux/ui/ui.actions';
import { addOnKeydownEventHandler } from '../../utils/components.utils';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import CustomBackdropLoadingInsideDiv from '../CustomBackdrop/CustomBackdropLoadingInsideDiv';

interface IProps {
    value?: any;
    onSave?: (value?: any) => Promise<void>;
    onClose?: () => void;
    rows?: number;
    size?: 'medium'|'small';
    theme?: 'dark'|'light';
    inputType?: 'text'|'number';
    input?: ReactNode;
    inputStyle?: CSSProperties;
    emptyValue?: ReactNode;
    width?: number;
    className?: string;
}

const CustomEditableValue: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const initialValue = props.value;
    const [editMode, setEditMode] = useState(false);
    const [value, setValue] = useState(props.value);
    const [loading, setLoading] = useState(false);
    const forceExit = useSelector((store: IStore) => store.ui.forceExitFastEdit);
    const emptyValue = props.emptyValue || 'Hier klicken zum eintragen';

    const onClose = () => {
        setEditMode(false);
        setValue(props.value);
        if (props.onClose) {
            props.onClose();
        }
    }

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    addOnKeydownEventHandler("Escape",() => {
        onClose();
    });

    useEffect(() => {
        if (forceExit) {
            setEditMode(false);
            dispatch(setForceExitFastEditAction(false));
        }
    }, [forceExit])

    const onSave = () => {
        if ((initialValue !== value || props.input) && props.onSave) {
            setLoading(true);
            props.onSave(value).then(() => {
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
            setValue('');
        }
        setEditMode(false);
    }

    if (!editMode) {
        return <>
            <CustomBackdropLoadingInsideDiv open={loading} />
            <div
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setEditMode(true);
                }}
                className={props.className + ' ' + styles.root + ' ' + styles[props.theme || 'dark']}
            >
                {props.value
                    ? <>{props.children}</>
                    : <div className={"formContent" + ' ' + styles[props.theme || 'dark']}>
                        <i>{emptyValue}</i>
                    </div>
                }
            </div>
        </>
    }

    if (props.input) {
        return <>
            <ClickAwayListener mouseEvent={"onClick"} onClickAway={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onSave();
            }}>
                <div
                    id={"editableValue"}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                    style={{width: '100%'}}
                    className={"flexContainerColumn flex1 gap10"}
                >
                    {props.input}
                    {props.onSave &&
                        <div className={"flexContainerRow gap5 alignHorizontalRight marginBottom5"}>
                            <Button
                                variant="contained"
                                size={'small'}
                                color="default"
                                onClick={onClose}
                            >
                                {IconFactory.getIconComponent(EIcons.Cancel, {color: 'var(--brainz-dark)'})}
                            </Button>
                            <Button
                                variant="contained"
                                size={'small'}
                                color="default"
                                onClick={onSave}
                            >
                                {IconFactory.getIconComponent(EIcons.Save, {color: 'var(--brainz-dark)'})}
                            </Button>
                        </div>
                    }
                </div>
            </ClickAwayListener>
        </>;
    }

    return <>
        <ClickAwayListener mouseEvent={"onClick"} onClickAway={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onSave();
        }}>
            <div className={"flexContainerColumn gap5"}>
                <CustomTextField
                    type={props.inputType || 'text'}
                    id={"editableValue"}
                    size={props.size || 'medium'}
                    InputProps={{
                        autoFocus: true
                    }}
                    multiline={Boolean(props.rows && props.rows > 1)}
                    rows={props.rows || 1}
                    value={value}
                    onChange={(value) => setValue(value)}
                    width={props.width}
                />
                {props.onSave &&
                    <div className={"flexContainerRow gap5 alignHorizontalRight marginBottom5"}>
                        <Button
                            variant="contained"
                            size={'small'}
                            color="default"
                            onClick={onClose}
                        >
                            {IconFactory.getIconComponent(EIcons.Cancel, {color: 'var(--brainz-dark)'})}
                        </Button>
                        <Button
                            variant="contained"
                            size={'small'}
                            color="default"
                            onClick={onSave}
                        >
                            {IconFactory.getIconComponent(EIcons.Save, {color: 'var(--brainz-dark)'})}
                        </Button>
                    </div>
                }
            </div>
        </ClickAwayListener>
    </>
}

export default CustomEditableValue;

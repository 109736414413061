import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { setRecruitingPageStartRecruitingAction } from '../../../../redux/recruitingPage/recruitingPage.actions';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { setUiExpandCollapseRecruitingListItem } from '../../../../redux/ui/ui.actions';
import CompanyRecruitingList from './components/CompanyRecruitingList';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import { EEntities } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { setCompanyPageRecruitingListFilterAction } from '../../../../redux/companyPage/companyPage.actions';
import { EFilterPanelType } from '../../../../definitions/filter.definitions';

interface IProps {
    companyId: number;
}

const CompanyRecruitingPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const companyId = props.companyId;
    const collapseOrExpandAll = useSelector((store: IStore) => store.ui.expandOrCollapseRecruitingListItems);
    const filter = useSelector((store: IStore) => store.companyPage.filterRecruitingList);

    const configuration: IAppBarConfig = {
        entity: EEntities.RecruitingOfCompany,
        value: filter.fastSearch || '',
        emptyValue: translate('pages.recruiting.fastSearchEmptyText'),
        fastSearchCallback: (fastSearch) => {
            dispatch(setCompanyPageRecruitingListFilterAction({fastSearch}))
        },
        actionButtons: [{
            action: () => dispatch(setRecruitingPageStartRecruitingAction({
                open: true,
                companyId
            })),
            tooltip: "Kandidat vorschlagen",
            icon: EIcons.Add
        }, {
            action: () => dispatch(setUiExpandCollapseRecruitingListItem(collapseOrExpandAll === 'expand' ? 'collapse' : 'expand')),
            tooltip: collapseOrExpandAll === 'expand' ? 'Alle zuklappen' : 'Alle aufklappen',
            icon: collapseOrExpandAll === 'expand' ? EIcons.FullscreenExit : EIcons.Fullscreen
        }],
        filterPanelType: EFilterPanelType.popupMenu
    };

    return <>
        <div className={"flexColumn padding5"}>
            <div className={"flexAutoGrow"}>
                <CustomFastSearchPanel appBarConfig={configuration}/>
            </div>
            <CompanyRecruitingList companyId={companyId}/>
        </div>
    </>;
};

export default CompanyRecruitingPanel;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { setRecruitingPageStartRecruitingAction } from '../../../../redux/recruitingPage/recruitingPage.actions';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { setUiExpandCollapseRecruitingListItem } from '../../../../redux/ui/ui.actions';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import { EEntities } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { setCompanyPageContactRecruitingListFilterAction } from '../../../../redux/companyPage/companyPage.actions';
import { EFilterPanelType } from '../../../../definitions/filter.definitions';
import CompanyContactRecruitingList from './components/CompanyContactRecruitingList';

interface IProps {
    companyContactId: number;
    companyId: number;
}

const CompanyContactRecruitingPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const companyContactId = props.companyContactId;
    const companyId = props.companyId;
    const collapseOrExpandAll = useSelector((store: IStore) => store.ui.expandOrCollapseRecruitingListItems);
    const filter = useSelector((store: IStore) => store.companyPage.filterContactRecruitingList);

    const configuration: IAppBarConfig = {
        entity: EEntities.RecruitingOfCompanyContact,
        value: filter.fastSearch || '',
        emptyValue: translate('pages.recruiting.fastSearchEmptyText'),
        fastSearchCallback: (fastSearch) => {
            dispatch(setCompanyPageContactRecruitingListFilterAction({fastSearch}))
        },
        actionButtons: [{
            action: () => dispatch(setRecruitingPageStartRecruitingAction({
                open: true,
                companyId,
                contactId: companyContactId
            })),
            tooltip: "Kandidat vorschlagen",
            icon: EIcons.Add
        }, {
            action: () => dispatch(setUiExpandCollapseRecruitingListItem(collapseOrExpandAll === 'expand' ? 'collapse' : 'expand')),
            tooltip: collapseOrExpandAll === 'expand' ? 'Alle zuklappen' : 'Alle aufklappen',
            icon: collapseOrExpandAll === 'expand' ? EIcons.FullscreenExit : EIcons.Fullscreen
        }],
        filterPanelType: EFilterPanelType.popupMenu
    };

    return <>
        <div className={"flexColumn padding5"}>
            <div className={"flexAutoGrow"}>
                <CustomFastSearchPanel appBarConfig={configuration}/>
            </div>
            <CompanyContactRecruitingList companyContactId={companyContactId}/>
        </div>
    </>;
};

export default CompanyContactRecruitingPanel;

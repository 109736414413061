import { IStore } from '../definitions/redux/store.definitions';
import { IVacancyListModel, IVacancyOrWebVacancy } from '../definitions/entities.definition';
import { IGetRequestObject } from '../definitions/requests.definitions';
import { buildRequestObject } from './entity.selectors';

export const getOpenVacancyListModels = (store: IStore) => {
    const order = store.entities.vacancies.order;
    const vacanciesById = store.entities.vacancies.listModelById;

    return order.reduce((accumulator: IVacancyListModel[], vacancyId) => {
        if (vacanciesById[vacancyId]) {
            accumulator.push(vacanciesById[vacancyId]);
        }

        return accumulator;
    }, []);
};

export const getArchivedVacancyListModels = (store: IStore) => {
    const order = store.entities.vacancies.orderArchived;
    const vacanciesById = store.entities.vacancies.listModelById;

    return order.reduce((accumulator: IVacancyListModel[], vacancyId) => {
        if (vacanciesById[vacancyId]) {
            accumulator.push(vacanciesById[vacancyId]);
        }

        return accumulator;
    }, []);
};

export const getVacanciesOfCompany = (store: IStore, companyId: number) => {
    const vacanciesById = store.entities.vacancies.listModelById;
    const vacanciesByCompanyId = store.entities.vacancies.byCompany[companyId] || [];

    const vacancies: IVacancyListModel[] = [];

    vacanciesByCompanyId.forEach((vacancyId) => {
        if (vacanciesById[vacancyId]) {
            vacancies.push(vacanciesById[vacancyId]);
        }
    });

    return vacancies;
};

export const getVacancyPageOpenListRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    const sorting = store.vacancyPage.vacancyListSorting;
    const filter = store.vacancyPage.vacancyListFilter;

    return buildRequestObject(sorting, filter);
};

export const getVacancyPageArchivedListRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    const sorting = store.archivePage.sortingVacancyList;
    const filter = store.archivePage.filterVacancyList;

    return buildRequestObject(sorting, filter);
};

export const getAllVacanciesForCandidateFromStore = (store: IStore): IVacancyOrWebVacancy[] => {
    const order = store.entities.vacancyAndWebVacancy.orderCandidateSearch;
    const candidatesById = store.entities.vacancyAndWebVacancy.byId;

    return order.reduce((accumulator: IVacancyOrWebVacancy[], candidateId) => {
        accumulator.push(candidatesById[candidateId]);

        return accumulator;
    }, []);
};


export const getCandidatePageVacancySearchListRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    const sorting = store.candidatePage.sortingVacancyList;
    const filter = store.candidatePage.filterVacancyList;

    return buildRequestObject(sorting, filter);
};

export const getCompanyVacanciesForCompany = (store: IStore, companyId: number) => {
    const vacanciesByCompany = store.entities.vacancies.byCompany[companyId];
    const vacancies = store.entities.vacancies.listModelById;

    const companyVacancies: IVacancyListModel[] = [];

    if (!vacanciesByCompany) {
        return [];
    }

    vacanciesByCompany.forEach((companyId) => {
        if (vacancies[companyId]) {
            companyVacancies.push(vacancies[companyId])
        }
    });

    return companyVacancies;
};

export const getCompanyContactVacanciesForCompany = (store: IStore, companyContactId: number) => {
    const vacanciesByCompany = store.entities.vacancies.byCompanyContact[companyContactId];
    const vacancies = store.entities.vacancies.listModelById;

    const companyVacancies: IVacancyListModel[] = [];

    if (!vacanciesByCompany) {
        return [];
    }

    vacanciesByCompany.forEach((companyId) => {
        if (vacancies[companyId]) {
            companyVacancies.push(vacancies[companyId])
        }
    });

    return companyVacancies;
};

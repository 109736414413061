import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Select } from '@material-ui/core';
import React, { CSSProperties, ReactNode, useEffect } from 'react';
import { EIcons } from '../../Icons/IconFactory';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import CustomClickableIcon from '../CustomClickableIcon';

interface IProps {
    onChange?: TMouseEventFunction;
    value: any;
    size?: 'small' | 'medium';
    id: string;
    label: string;
    required?: boolean;
    onAddNewEntryCallback?: () => void;
    error?: boolean;
    helperText?: ReactNode;
    disabled?: boolean;
    width?: number;
    flex?: number;
    multiple?: boolean;
    renderValue?: (value: any[]) => React.ReactNode;
    autoFocus?: boolean;
    disablePortal?: boolean;
}

const CustomDropDownInput: React.FC<IProps> = (props) => {
    const labelId = `${props.id}-label`;
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    let startAdornment = null;

    if (props.onAddNewEntryCallback) {
        startAdornment = (
            <InputAdornment position="start">
                <CustomClickableIcon icon={EIcons.Add} onClick={() => props.onAddNewEntryCallback ? props.onAddNewEntryCallback() : console.log(1)}/>
            </InputAdornment>
        );
    }

    let endAdornment = undefined;

    if (props.value && !props.disabled && !props.required) {
        endAdornment = (
            <InputAdornment position="end">
                <CustomClickableIcon onClick={() => {
                        if (!props.onChange) {
                            return;
                        }
                        props.onChange({
                            target: {
                                value: undefined
                            }
                        })
                    }}
                    icon={EIcons.Close}
                />
            </InputAdornment>
        );
    }

    useEffect(() => {
        if (inputLabel && inputLabel.current) {
            // @ts-ignore-next-line
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, [inputLabel, labelWidth]);

    const sizeStyles: CSSProperties = {
        flex: props.flex || 1
    }
    if (props.width) {
        delete sizeStyles.flex;
        sizeStyles.width = props.width;
    }

    let value = props.value
    if (props.multiple && !props.value) {
        value = [];
    }

    return (
        <FormControl error={props.error} variant={"outlined"} style={sizeStyles} size={props.size}>
            <InputLabel required={props.required} shrink ref={inputLabel} id={labelId}>
                {props.label}
            </InputLabel>
            <Select
                MenuProps={{disablePortal: props.disablePortal}}
                style={{zIndex: 1500}}
                autoFocus={props.autoFocus}
                multiple={props.multiple}
                labelId={labelId}
                disabled={props.disabled}
                id={props.id}
                value={value}
                error={props.error}
                onChange={props.onChange}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                displayEmpty={true}
                //@ts-ignore
                renderValue={props.renderValue}
                input={
                    <OutlinedInput
                        notched
                        labelWidth={labelWidth}
                        name={props.label}
                        id={labelId}
                    />
                }
            >
                {props.children}
            </Select>
            {props.error && <FormHelperText error={true}>{props.helperText}</FormHelperText>}
        </FormControl>
    );
};

export default CustomDropDownInput;

import React from 'react';
import { IMailboxFolderTreeNode } from '../../../../definitions/entities.definition';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { setMailingFilterAction } from '../../../../redux/mailing/mailing.actions';
import { useDispatch } from 'react-redux';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

interface IProps {
    node: IMailboxFolderTreeNode;
    nested?: boolean;
    selectedFolderId?: number;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    }),
);

const FolderTreeItem: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const node = props.node;

    const handleClick = () => {
        setOpen(!open);
        dispatch(setMailingFilterAction({folderId: node.id}));
    };

    if (node.children.length > 0) {
        return <>
            <ListItem button onClick={handleClick} selected={node.id === props.selectedFolderId} className={props.nested ? classes.nested : ''}>
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={node.text} secondary={node.countUnread > 0 && node.countUnread + ' ungelesen'}/>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {node.children.map((treeNode) => <FolderTreeItem
                        key={treeNode.id}
                        node={treeNode}
                        nested
                        selectedFolderId={props.selectedFolderId}
                    />)}
                </List>
            </Collapse>
        </>;
    }

    return <>
        <ListItem button onClick={handleClick} selected={node.id === props.selectedFolderId} className={props.nested ? classes.nested : ''}>
            <ListItemIcon>
                <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={node.text} secondary={node.countUnread > 0 && node.countUnread + ' ungelesen'}/>
        </ListItem>
    </>;
}

export default FolderTreeItem;

import React from 'react';
import { EEntities } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { EFilterPanelType } from '../../../../definitions/filter.definitions';
import { useDispatch, useSelector } from 'react-redux';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { setActivityPageFastSearchAction } from '../../../../redux/activityOverviewPage/activityOverviewPage.actions';

const FastSearchPanelActivityPage: React.FC = () => {
    const dispatch = useDispatch();
    const fastSearchActivity = useSelector((store: IStore) => store.activityPage.filter.fastSearch);

    const configuration: IAppBarConfig = {
        entity: EEntities.Activity,
        value: fastSearchActivity ?? '',
        emptyValue: translate('misc.fastSearchEmptyText'),
        fastSearchCallback: (value: string) => dispatch(setActivityPageFastSearchAction(value)),
        filterPanelType: EFilterPanelType.popupMenu
    };

    return <>
        <CustomFastSearchPanel appBarConfig={configuration}/>
    </>
}

export default FastSearchPanelActivityPage;

import React from 'react';
import { Avatar } from '@material-ui/core';
import { API_URL } from '../../../../requests/routes';
import { translate } from '../../../../translation/translate.utils';
import { ICustomerRecruitingListModel } from '../../../../definitions/entities.customer.definitions';

interface IProps {
    token: string;
    recruiting: ICustomerRecruitingListModel;
}

const RecruitingCandidateCellRenderer: React.FC<IProps> = (props) => {
    const token = props.token;
    const recruiting = props.recruiting;
    const photoId = recruiting.photoId;
    const fullName = recruiting.fullName;
    const desiredYearSalary = recruiting.desiredYearSalary;
    const noticePeriod = recruiting.noticePeriod;

    return <>
        {photoId
            ?
            <div className={'flexContainerColumn paddingRight5'} style={{maxHeight: 60, marginTop: 10, marginLeft: 5}}>
                <Avatar
                    style={{width: 50, height: 50}}
                    src={API_URL + "/candidate-image/" + photoId + "/preview?Authorization=" + token + "&recruitingId=" + recruiting.id}
                />
            </div>
            :
            <div className={'flexContainerColumn paddingRight5'} style={{maxHeight: 60, marginTop: 10, marginLeft: 5}}>
                <Avatar style={{width: 50, height: 50}}/>
            </div>
        }
        <div className={'flexContainerColumn marginTop5'}>
            <div style={{paddingLeft: 0}}>
                {fullName}
                <div className={'formContent'}>
                    KF:&nbsp;
                    {noticePeriod ? translate('misc.noticePeriodValue.' + noticePeriod) : translate('misc.noInformation')}
                </div>
                <div className={'formContent'}>
                    {translate('pages.recruiting.salary')}:&nbsp;
                    {desiredYearSalary ? desiredYearSalary/1000 + 'k p.a.' : translate('misc.noInformation')}
                </div>
            </div>
        </div>
    </>;
}

export default React.memo(RecruitingCandidateCellRenderer);

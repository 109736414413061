import { preloadedStateUi } from './ui.definitions';
import { preloadedStateActor } from './actor.definitions';
import { IStore } from './redux/store.definitions';
import { preloadedStateError } from './error.definitions';
import { ICompanyContactPartial, IEmployeePartial, IUser, preloadedStateEntities } from './entities.definition';
import { preloadedStateCompanyPage } from './companyPage.definitions';
import { ESortingOptions } from './components.definitions';
import { preloadedStateCandidatePage } from './candidatePage.definitions';
import { preloadedStateRecruitingPage } from './recruitingPage.definitions';
import { preloadedStateVacancyPage } from './vacancyPage.definitions';
import { preloadedStateActivityPage } from './activityPage.definitions';
import { preloadedCalendarState } from './calendarPage.definitions';
import { preloadedStateDashboardPage } from './dashboardPage.definitions';
import { preloadedStateArchivePage } from './archivePage.definitions';
import { preloadedStateAdministrationPage } from './administrationPage.definitions';
import { preloadedStateWebVacancyPage } from './webVacancyPage.definitions';
import { preloadedTodoState } from './todo.definitions';
import { preloadedNewsState } from './news.definitions';
import { preloadedStateApplicationPage } from './applicationPage.definitions';
import { preloadedStateBillingPage } from './billingPage.definitions';
import { preloadedStateSkillPage } from './skillPage.definitions';
import { preloadedStateJobTemplatePage } from './jobTemplatePage.definitions';
import { preloadedMailingState } from './mailing.definitions';
import { IDateRangeFilterApiProperties } from './filter.definitions';
import { preloadedStateCustomerApp } from './customerApp.definitions';

export interface IChangeEvent {
    target: {
        value: any;
    };
}
export interface IKeyDownEvent {
    key: string;
}

export enum ERoutes {
    Dashboard = '/dashboard',
    Companies = '/companies',
    Company = '/company',
    ActivityOverview = '/activity-overview',
    Calendar = '/calendar',
    Applications = '/applications',
    Recruitings = '/recruitings',
    Recruiting = '/recruiting',
    Candidates = '/candidates',
    Candidate = '/candidate',
    Archive = '/archive',
    Administration = '/administration',
    Profile = '/profile',
    WebVacancies = '/web-vacancies',
    WebVacancy = '/web-vacancy',
    Vacancy = '/vacancy',
    Analyse = '/analyse',
    Billing = '/billing',
    Suggestion = '/suggestion'
}

export enum EStorageItem {
    Token = 'brainz_token',
    Views = 'views'
}

export const preloadedState: IStore = {
    ui: preloadedStateUi,
    actor: preloadedStateActor,
    error: preloadedStateError,
    entities: preloadedStateEntities,
    companyPage: preloadedStateCompanyPage,
    applicationPage: preloadedStateApplicationPage,
    dashboardPage: preloadedStateDashboardPage,
    candidatePage: preloadedStateCandidatePage,
    recruitingPage: preloadedStateRecruitingPage,
    vacancyPage: preloadedStateVacancyPage,
    activityPage: preloadedStateActivityPage,
    calendarPage: preloadedCalendarState,
    archivePage: preloadedStateArchivePage,
    administrationPage: preloadedStateAdministrationPage,
    webVacancyPage: preloadedStateWebVacancyPage,
    todo: preloadedTodoState,
    news: preloadedNewsState,
    mailing: preloadedMailingState,
    billingPage: preloadedStateBillingPage,
    skillPage: preloadedStateSkillPage,
    jobTemplatePage: preloadedStateJobTemplatePage,
    customerApp: preloadedStateCustomerApp
};

export const preloadedStateExpire: IStore = {
    ...preloadedState,
    ui: { ...preloadedStateUi, expireModal: true },
};

export interface ISorting {
    [propertyKey: string]: ESortingOptions;
}

export interface IApiBaseFilter {
    [filterKey: string]: any;
    start: number;
    limit: number;
    fastSearch?: string;
    dateRangeFilter?: IDateRangeFilterApiProperties;
}

export interface IJwtData {
    employee?: IEmployeePartial;
    companyContact?: ICompanyContactPartial;
    user: IUser;
    onlyShowMine: boolean;
    expire: string;
}

export interface IFile {
    fileType: string;
    fileContent: string;
    fileName: string;
}

import React, { ReactNode, useEffect, useRef } from 'react';
import CustomTableCell from '../components/CustomTable/CustomTableCell';
import { ECellAlign, ICustomTableColumn, ICustomTableColumnConfig } from '../definitions/components.definitions';
import {
    EEntities,
    ICompanyContactPartial,
    ICompanyPartial,
    IEmployee,
    ITodo,
    TBrainzEntity,
} from '../definitions/entities.definition';
import moment from 'moment';
import { translate } from '../translation/translate.utils';
import EmployeeCellRenderer from '../components/CustomCellRenderer/EmployeeCellRenderer';
import DateDiffCellRenderer from '../components/CustomCellRenderer/DateDiffCellRenderer';
import CompanyCellRenderer from '../components/CustomCellRenderer/CompanyCellRenderer';
import { fixUrl } from './application.utils';

export const INFINITE_SCROLL_INTERVAL = 30;

export const detectScrollEnd = (elementId: string) => {
    const wrappedElement = document.getElementById(elementId);

    if (wrappedElement) {
        const heightOfElement = wrappedElement.offsetHeight;
        const scrollTop = wrappedElement.scrollTop;
        const scrollHeight = wrappedElement.scrollHeight;
        const scrollHeightToObserve = heightOfElement + scrollTop;

        return scrollHeightToObserve >= (scrollHeight - 20);
    }

    return false;
};

export const detectScrollVerticalPosition = (elementId: string) => {
    const wrappedElement = document.getElementById(elementId);

    if (wrappedElement) {
        return wrappedElement.scrollLeft;
    }

    return false;
};

export const getDefaultLastActivityColumn = (): ICustomTableColumn => {
    return {
        header: translate('misc.lastActivity'),
        property: "lastActivity",
        align: ECellAlign.right,
        width: 120,
        cellRenderer: (lastActivity: string) => <DateDiffCellRenderer date={lastActivity} />,
    }
}

export const getDefaultCompanyColumn = (): ICustomTableColumn => {
    return {
        header: translate('pages.vacancy.properties.company'),
        property: "company",
        orderByProperty: "company.name",
        width: 120,
        align: ECellAlign.left,
        cellRenderer: (company: null | ICompanyPartial, entity) => {
            //@ts-ignore
            if (company && entity['responsibleContact']) {
                //@ts-ignore
                return <CompanyCellRenderer company={company} companyContact={entity.responsibleContact as ICompanyContactPartial}/>;
            }
            if (company) {
                return <CompanyCellRenderer company={company}/>;
            }

            return translate('misc.noInformation');
        },
    }
}

export const getDefaultCreatedColumn = (title?: ReactNode): ICustomTableColumn => {
    return {
        header: title || translate('misc.created'),
        property: "created",
        width: 120,
        align: ECellAlign.right,
        cellRenderer: (created: string) => <DateDiffCellRenderer date={created} />,
    }
}

export const getDefaultEmployeeColumn = (property?: string): ICustomTableColumn => {
    return {
        header: translate('misc.employeeShort'),
        property: property || "responsibleEmployee",
        align: ECellAlign.center,
        width: 60,
        cellRenderer: (employee?: null|IEmployee) => {
            if (!employee) {
                return <>-</>;
            }
            return <EmployeeCellRenderer employee={employee} />;
        },
    }
}

export const getEmployeeColor = (employeeId: number) => {
    const colorSteve = '#0079bf';
    const colorDaniel = '#000';
    const colorVroni = '#ff78cb';
    const colorMaik = '#61bd4f';
    const colorUli = 'var(--brainz-color-purple)';

    let color = '#99aab5';
    switch (employeeId) {
        case 1:
            color = colorDaniel;
            break;
        case 2:
            color = colorSteve;
            break;
        case 3:
            color = colorVroni;
            break;
        case 4:
            color = colorMaik;
            break;
        case 9:
            color = colorUli;
            break;
    }

    return color;
}

export const createCustomTableCellsHook = (
    denyVerticalCenter: boolean,
    entity: EEntities,
    config: ICustomTableColumnConfig,
    entry: TBrainzEntity
) => {
    const cells: ReactNode[] = [];
    Object.values(config).forEach((configEntry, index) => {
        const { width, align, cellRenderer, flex, property } = configEntry;
        const value = extractValuesForTableCell(entry, configEntry);

        cells.push(
            <CustomTableCell
                record={entry}
                key={index + 1}
                value={value}
                width={width}
                align={align}
                cellRenderer={cellRenderer}
                flex={flex}
                entity={entity}
                property={property}
                denyVerticalCenter={denyVerticalCenter}
            />
        );
    });

    return cells;
};

/**
 * util function that extracts the values depending on the depth of the properties given by config
 * @param entry
 * @param configEntry
 * @returns
 */
const extractValuesForTableCell = (entry: any, configEntry: ICustomTableColumn) => {
    const propSplit = configEntry.property.split(".");
    let value = undefined;

    /**
     * entity.object.object.value
     */
    if (propSplit && propSplit.length === 3) {
        const one = propSplit[0];
        const two = propSplit[1];
        const three = propSplit[2];

        if (entry[one] !== null && entry[one] !== undefined && entry[one][two] !== null && entry[one][two] !== undefined) {
            if (entry[one][two][three] !== null) {
                value = entry[one][two][three];
            }
        }

        if (value === undefined) {
            value = '-';
        }
    } else if (propSplit && propSplit.length === 2) {
        /**
         * entity.object.value
         */
        const one = propSplit[0];
        const two = propSplit[1];

        if (entry[one] !== null && entry[one] !== undefined) {
            if (entry[one][two] !== null) {
                value = entry[one][two];
            }
        }

        if (value === undefined) {
            value = '-';
        }
    } else {
        /**
         * entity.value
         */
        value = entry[configEntry.property];
    }

    return value;
};

export const sendUrlToBrainzPlugin = (url: string) => {
    url = fixUrl(url);
    const pluginImportInput = document.getElementById('brainz_import_url');
    if (pluginImportInput) {
        pluginImportInput.value = url;
        pluginImportInput.dispatchEvent(new Event('input', { 'bubbles': true }));
        return true;
    }

    return false;
}

export const addOnBrainzPluginHandler = (handler: (event: MessageEvent) => void, eventName = "message", element = window) => {
    const messageHandler = useRef();

    useEffect(() => {
        // @ts-ignore-next-line
        messageHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) {
            return;
        }

        const eventListener = (event: any) => {
            if (!event.data.type || event.data.type !== "BRAINZ_PLUGIN") {
                return;
            }

            // @ts-ignore-next-line
            messageHandler.current(event);
        };
        element.addEventListener(eventName, eventListener);

        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);
}

export const addOnKeydownEventHandler = (key: string, handler: (event: any) => void, eventName = "keydown", element = window) => {
    const savedHandler = useRef();

    useEffect(() => {
        // @ts-ignore-next-line
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) {
            return;
        }

        const eventListener = (event: any) => {
            if (event.key === key) {
                // @ts-ignore-next-line
                savedHandler.current(event);
            }
        };
        element.addEventListener(eventName, eventListener);

        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);
};

export const sliceArrayIntoChunks = (arr: any[], chunkSize: number) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
};

export const getDateFromIsoString = (isoDate: string) => {
    const split = isoDate.split("T");
    return split[0];
};

export const getTimeFromIsoString = (isoDate: string) => {
    const split = isoDate.split("T");
    const splitTwo = split[1].split("+");
    const hhmmss = splitTwo[0];
    const timeSplit = hhmmss.split(":");

    return `${timeSplit[0]}:${timeSplit[1]}`;
};

export const checkIfDatesOverlap = (a: ITodo, b: ITodo) => {
    // Current row dates
    const dateStart = moment(a.dueDate);
    const dateEnd = moment(a.dueDate);

    // Check with dates above
    const rangeUsedStart = moment(b.dueDate);
    const rangeUsedEnd = moment(b.dueDate);

    // Range covers other ?
    if (dateStart <= rangeUsedStart && rangeUsedEnd <= dateEnd) {
        return true;
    }
    // Range intersects with other start ?
    if (dateStart <= rangeUsedStart && rangeUsedStart <= dateEnd) {
        return true;
    }
    // Range intersects with other end ?
    if (dateStart <= rangeUsedEnd && rangeUsedEnd <= dateEnd) {
        return true;
    }

    // All good
    return false;
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import { setUiCompanyLocationAddDataAction, setUiCompanyLocationToEditAction } from '../../../redux/ui/ui.actions';
import CompanyLocationForm from './CompanyLocationForm';
import { resetFormValidation } from '../../../redux/error/error.actions';
import { Dialog } from '@material-ui/core';

const CompanyLocationAddEditView: React.FC = () => {
    const dispatch = useDispatch();
    const addCompanyLocation = useSelector((store: IStore) => Boolean(store.ui.addCompanyLocationData.companyId));
    const editCompanyLocation = useSelector((store: IStore) => Boolean(store.ui.editCompanyLocationId));

    const onClose = () => {
        dispatch(setUiCompanyLocationAddDataAction({companyId: 0}));
        dispatch(setUiCompanyLocationToEditAction(0));
        dispatch(resetFormValidation());
    }

    const open = addCompanyLocation || editCompanyLocation;

    if (!open) {
        return null;
    }

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth onClose={onClose}>
            <CustomDialogHeader translationString={addCompanyLocation ? "pages.companyLocation.add" : "pages.companyLocation.edit"} onClose={onClose} />
            <CompanyLocationForm onClose={onClose} />
        </Dialog>
    );
}

export default CompanyLocationAddEditView;


import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useDrawerStyles = makeStyles((theme: Theme) =>
    createStyles({
        defaultWidth: {
            minWidth: 900,
            width: 'auto',
            maxWidth: 1000
        },
        entityWidth: {
            minWidth: 900,
            width: 'auto',
        },
        root: {
            display: "flex",
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: "none",
        },
        drawer: {
            flexShrink: 0,
        },
        boxShadow: {
            boxShadow: "0px 5px 5px 0px rgb(0 0 0 / 20%)",
        },
        drawerOpen: {
            zIndex: (theme.zIndex.drawer + 1 + ' !important') as any,
            backgroundColor: 'var(--brainz-darker)'
        },
        drawerBringToTop: {
            zIndex: 1310
        },
        drawerClose: {
            backgroundColor: theme.palette.background.paper,
            overflowX: "hidden",
            width: 0,
        },
        toolbar: {
            display: "flex",
            padding: theme.spacing(0, 1),
            backgroundColor: 'var(--brainz-darkest)'
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        }
    })
);

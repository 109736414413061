import React from 'react';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import { connect, ConnectedProps } from 'react-redux';
import { addContractOfferAction, updateContractOfferAction } from '../../../redux/entities/entities.actions';
import { getToken, getValidationErrorsFromStore } from '../../../selectors/app.selectors';
import {
    EInterviewType,
    ICompanyContactPartial,
    IContractOfferForApi,
    IEmployee,
    IEmployeePartial,
} from '../../../definitions/entities.definition';
import LegacyEmployeeAutocompleteDropDown
    from '../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import { translate } from '../../../translation/translate.utils';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';
import autobind from 'autobind-decorator';
import CustomDateTimePicker from '../../../components/CustomInput/CustomDateTimePicker';
import moment from 'moment';
import { setUiActivityToDeleteAction, setUiCompanyContactAddDataAction } from '../../../redux/ui/ui.actions';
import CustomCheckboxInput from '../../../components/CustomInput/CustomCheckboxInput';
import CustomDatePicker from '../../../components/CustomInput/CustomDatePicker';
import CompanyContactAutocomplete
    from '../../../components/CustomInput/CustomAutocomplete/components/CompanyContactAutocomplete';
import CustomNumberInput from '../../../components/CustomInput/CustomNumberInput';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';

interface IProps extends PropsFromRedux {
    onClose: () => void;
}

type TState = {
    interviewType: EInterviewType,
    interviewCancelled: boolean,
    recruitingAbort: boolean,
    employee: IEmployee | IEmployeePartial;
    companyContact: ICompanyContactPartial | null;
    dateTime?: Date;
    userInput: string;
    offeredContractBeginningDate?: Date;
    offeredContractSalary?: number;
}

class ContractOfferForm extends React.PureComponent<IProps, TState> {
    constructor(props: IProps) {
        super(props);

        const initialActivity = props.initialActivity;
        const addData = props.addData;

        this.state = {
            interviewType: initialActivity?.interviewType || addData?.interviewType || EInterviewType.phone,
            interviewCancelled: initialActivity?.interviewCancelled || false,
            recruitingAbort: initialActivity?.recruitingAbort || false,
            employee: initialActivity?.employee || this.props.addData.preSelectedEmployee || this.props.loggedInEmployee,
            companyContact: initialActivity?.companyContact || addData?.contactId || undefined,
            dateTime: initialActivity ? new Date(initialActivity?.dateTime) : new Date(),
            userInput: initialActivity?.userInput || '',
            offeredContractBeginningDate: initialActivity?.data ? initialActivity?.data['offeredContractBeginningDate'] : undefined,
            offeredContractSalary: initialActivity?.data ? initialActivity?.data['offeredContractSalary'] : undefined,
        };
    }

    @autobind
    save() {
        const activity: IContractOfferForApi = {
            employeeId: this.state.employee?.id || 0,
            dateTime: moment(this.state.dateTime).format('DD.MM.Y H:mm:ss'),
            companyContactId: this.state.companyContact?.id,
            userInput: this.state.userInput,
            recruitingId: this.props.addData.recruitingId,
            offeredContractBeginningDate: this.state.offeredContractBeginningDate ? moment(this.state.offeredContractBeginningDate).format('DD.MM.Y H:mm:ss') : undefined,
            offeredContractSalary: this.state.offeredContractSalary,
        };
        if (this.props.addData.add) {
            this.props.addContractOfferAction(this.props.token, activity);
            return;
        }

        activity.id = this.props.initialActivity.id;
        this.props.updateContractOfferAction(this.props.token, activity);
        return;
    }

    render() {
        return <>
            <PaperRow>
                <div className={'flexContainerColumn flex1'}>
                    <div className={'flex1 alignVerticalCenter gap10'}>
                        <CustomDateTimePicker
                            flex
                            label={'Datum & Uhrzeit'}
                            value={this.state.dateTime}
                            onChange={(value) => this.setState({
                                dateTime: value,
                            })}
                        />
                        {this.props.initialActivity &&
                        <CustomCheckboxInput
                            checked={this.state.interviewCancelled}
                            onChange={() => this.setState((state) => ({
                                interviewCancelled: !state.interviewCancelled,
                            }))}
                            label={<>Termin hat <br/>nicht statt gefunden</>}
                        />
                        }
                    </div>
                    <LegacyEmployeeAutocompleteDropDown
                        value={this.state.employee}
                        onChange={(event, value) => this.setState({
                            employee: value,
                        })}
                        error={Boolean(this.props.validationErrors.employeeId)}
                        helperText={this.props.validationErrors.employeeId}
                    />
                    <CompanyContactAutocomplete
                        value={this.state.companyContact}
                        onChange={(value) => this.setState({
                            companyContact: value,
                        })}
                        label={'Angebot erhalten von'}
                        onAdd={() => {
                            if (this.props.companyId) {
                                this.props.setUiCompanyContactAddDataAction({
                                    companyId: this.props.companyId,
                                });
                            }
                        }}
                        companyId={this.props.companyId}
                    />
                </div>
                <CustomTextField
                    multiline
                    rows={8}
                    label={translate('misc.internalComment')}
                    value={this.state.userInput}
                    onChange={(value) => this.setState({
                        userInput: value,
                    })}
                    error={Boolean(this.props.validationErrors.userInput)}
                    helperText={this.props.validationErrors.userInput}
                />
            </PaperRow>
            <PaperRow>
                <CustomDatePicker
                    label={'Geplanter Vertragsbeginn'}
                    flex
                    value={this.state.offeredContractBeginningDate}
                    required
                    onChange={(value) => this.setState({
                        offeredContractBeginningDate: value,
                    })}
                    error={Boolean(this.props.validationErrors.offeredContractBeginningDate)}
                    helperText={this.props.validationErrors.offeredContractBeginningDate}
                />
                <CustomNumberInput
                    suffix={'k p.a.'}
                    label={'Gebotenes Jahresgehalt'}
                    value={this.state.offeredContractSalary}
                    onChange={(value) => this.setState({
                        offeredContractSalary: value,
                    })}
                    error={Boolean(this.props.validationErrors.offeredContractSalary)}
                    helperText={this.props.validationErrors.offeredContractSalary}
                />
            </PaperRow>
            <CustomDialogActions
                onClose={this.props.onClose}
                buttons={[{
                    void: () => {this.props.setUiActivityToDeleteAction(this.props.initialActivity?.id)},
                    hidden: Boolean(!this.props.initialActivity),
                    label: translate('misc.buttons.delete')
                }, {
                    void: this.save,
                    label: translate('misc.buttons.saveAndClose')
                }]}
            />
        </>;
    }
}

const mapState = (store: IStore) => {
    const initialActivity = store.entities.activities.byId[store.ui.editActivityId];
    let companyId = 0;
    if (store.ui.addActivityData.add && store.ui.addActivityData.companyId) {
        companyId = store.ui.addActivityData.companyId;
    }
    if (!store.ui.addActivityData.add && initialActivity && initialActivity.company) {
        companyId = initialActivity.company.id;
    }

    return ({
        token: getToken(store),
        validationErrors: getValidationErrorsFromStore(store),
        loggedInEmployee: getLoggedInEmployeeFromStore(store),
        initialActivity,
        addData: store.ui.addActivityData,
        companyId: companyId,
    });
};

const mapDispatch = {
    updateContractOfferAction,
    addContractOfferAction,
    setUiCompanyContactAddDataAction,
    setUiActivityToDeleteAction,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ContractOfferForm);

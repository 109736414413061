import React, { memo, useState } from 'react';
import { ICandidate, ICandidatePartial } from '../../definitions/entities.definition';
import { useDispatch } from 'react-redux';
import { Chip, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import styles from './CandidateCellRenderer.module.css';
import { darker } from '../../theme/theme';
import { useLoadProfiles } from './CandidateCellRenderer.hook';
import CandidateCellRendererPopup from './CandidateCellRendererPopup';
import { setUiCloseTopDrawer, setUiEditView } from '../../redux/ui/ui.actions';
import { EEntityView } from '../../definitions/ui.definitions';

interface IProps {
    candidate: ICandidate|ICandidatePartial;
    onDelete?: () => void;
    label?: string;
    hideIcon?: boolean;
}

const CandidateCellRenderer: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const hideIcon = props.hideIcon || false;
    const candidate = props.candidate;

    const icons = (
        <div>
            {IconFactory.getIconComponent(EIcons.Person, {color: candidate.type === 'candidate' ? darker : 'white'})}
            {candidate.topCandidate && IconFactory.getIconComponent(EIcons.Star, {color: 'gold'})}
        </div>
    )

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const {profiles, loading} = useLoadProfiles(tooltipOpen, candidate.id);

    return (
        <Tooltip
            title={<CandidateCellRendererPopup candidate={candidate} profiles={profiles} />}
            placement="bottom-start"
            interactive
            onOpen={() => setTooltipOpen(true)}
            onClose={() => setTooltipOpen(false)}
        >
            <Chip
                key={candidate.id}
                icon={props.label || hideIcon ? undefined : icons}
                color="primary"
                className={styles.chip}
                deleteIcon={props.onDelete ? IconFactory.getIconComponent(EIcons.Close, {color: darker}) : undefined}
                onDelete={props.onDelete}
                label={props.label || candidate.fullName}
                onClick={(event) => {
                    event.stopPropagation();
                    dispatch(setUiCloseTopDrawer());
                    dispatch(setUiEditView(EEntityView.candidate, candidate.id));
                }}
            />
        </Tooltip>
    );
};

export default memo(CandidateCellRenderer);

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMailboxMail } from '../../../../definitions/entities.definition';
import CustomTableFooter from '../../../../components/CustomTable/CustomTableFooter';
import {
    getMailListByChannelAction,
    getMailListByFolderAction,
    getMailMessageAction,
} from '../../../../redux/entities/entities.actions';
import { getToken } from '../../../../selectors/app.selectors';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { getFormattedDateWithDayString } from '../../../../utils/date.utils';
import CustomTypography from '../../../../components/CustomTypography/CustomTypography';
import { getMailsFromStore, getMailsTotalCountFromStore, groupMailsByDate } from '../../../../selectors/mail.selectors';
import MailElement from './MailElement';
import CustomBackdropLoadingInsideDiv from '../../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import CustomDetectScrolledToEndDiv
    from '../../../../components/CustomDetectScrolledToEnd/CustomDetectScrolledToEndDiv';
import { INFINITE_SCROLL_INTERVAL } from '../../../../utils/components.utils';
import { setMailingPagingAction } from '../../../../redux/mailing/mailing.actions';

const MailList: React.FC = () => {
    const dispatch = useDispatch();
    const records = useSelector((store: IStore) => getMailsFromStore(store));
    const recordsGroupedByDate = groupMailsByDate(records);
    const token = useSelector(getToken);
    const loading = useSelector((state: IStore) => state.ui.mailsLoading);
    const filter = useSelector((state: IStore) => state.mailing.filter);
    const paging = useSelector((state: IStore) => state.mailing.paging);
    const total = useSelector((store: IStore) => getMailsTotalCountFromStore(store));
    const showMessageOfMailId = useSelector((store: IStore) => store.mailing.showMessageOfMailId);
    const mailOfShowMessage = useSelector((store: IStore) => store.entities.mail.byId[showMessageOfMailId]);
    const selectedMailboxId = useSelector((state: IStore) => state.mailing.selectedMailboxId);

    const onScrollEnd = () => {
        if (paging.limit >= total) {
            return;
        }

        if (filter.folderId && selectedMailboxId) {
            dispatch(setMailingPagingAction(0,paging.limit + INFINITE_SCROLL_INTERVAL));
            return;
        }
        if (filter.channel) {
            return;
        }
    }

    const loadMails = () => {
        if (filter.folderId && selectedMailboxId) {
            dispatch(getMailListByFolderAction(token, filter.folderId, selectedMailboxId, paging.start, paging.limit,false));
            return;
        }
        if (filter.channel) {
            dispatch(getMailListByChannelAction(token, filter.channel));
            return;
        }
    }

    useEffect(() => {
        if (showMessageOfMailId === 0 || !mailOfShowMessage) {
            return;
        }

        if (showMessageOfMailId !== showMessageOfMailId) {
            return;
        }

        if (mailOfShowMessage && mailOfShowMessage.message) {
            return;
        }

        dispatch(getMailMessageAction(token, mailOfShowMessage.mailboxMailId));
    }, [showMessageOfMailId])

    return (
        <>
            <div className={"flexColumn flex1 marginTop5"}>
                <CustomDetectScrolledToEndDiv
                    className={"overflow flex1 flexContainerColumn gap5 marginLeft5"}
                    id={"mail-list"}
                    loading={loading}
                    scrollCallback={onScrollEnd}>
                    {total === 0 &&
                        <>Keine Daten vorhanden</>
                    }
                    <CustomBackdropLoadingInsideDiv open={loading}/>
                    {Object.keys(recordsGroupedByDate).map((dateTime) => {
                        const records = recordsGroupedByDate[dateTime];

                        return <>
                            <div className={"flexContainerRow alignVerticalCenter"} style={{padding: 5, backgroundColor: 'var(--brainz-darkest)'}}>
                                <CustomTypography
                                    variant={'body2'}
                                    value={getFormattedDateWithDayString(dateTime, false)}
                                />
                            </div>
                            {
                                records.map((mail: IMailboxMail) => <MailElement
                                    key={mail.id}
                                    id={mail.id}
                                    showMessage={showMessageOfMailId === mail.id}
                                />)
                            }
                        </>;
                    })}
                </CustomDetectScrolledToEndDiv>
                <CustomTableFooter
                    count={records.length}
                    totalCount={total}
                    loading={loading}
                    onReload={loadMails}
                />
            </div>
        </>
    );
}

export default MailList;

import {
    ISetArchivePageFastSearchAction,
    ISetArchivePageFilterAction,
    ISetArchivePageSortingOptionAction,
} from '../../definitions/redux/actions.definition';
import { IVacancyFilter } from '../../definitions/vacancyPage.definitions';
import { ESortingOptions } from '../../definitions/components.definitions';
import { IRecruitingFilter } from '../../definitions/recruitingPage.definitions';

export const ARCHIVE_PAGE_SET_FILTER = "ARCHIVE_PAGE_SET_FILTER";
export const ARCHIVE_PAGE_SET_SORTING_OPTION = "ARCHIVE_PAGE_SET_SORTING_OPTION";
export const ARCHIVE_PAGE_SET_FAST_SEARCH = "ARCHIVE_PAGE_VACANCY_SET_FAST_SEARCH";

export const setArchivePageFilterAction = (filter: IVacancyFilter|IRecruitingFilter): ISetArchivePageFilterAction => ({
    type: ARCHIVE_PAGE_SET_FILTER,
    payload: filter,
});
export const setArchivePageSearchAction = (fastSearchValue: string): ISetArchivePageFastSearchAction => ({
    type: ARCHIVE_PAGE_SET_FAST_SEARCH,
    payload: fastSearchValue,
});
export const setArchivePageSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetArchivePageSortingOptionAction => ({
    type: ARCHIVE_PAGE_SET_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

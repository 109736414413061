import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerError, registerInfo } from '../../../../../../redux/error/error.actions';
import { EIcons } from '../../../../../../components/Icons/IconFactory';
import { deleteCandidateAddressRouteConfig, putCandidateAddressRouteConfig } from '../../../../../../requests/routes';
import { IListActionButtonConfig } from '../../../../../../definitions/components.definitions';
import CustomEditableValue from '../../../../../../components/CustomInput/CustomEditableValue';
import CustomHoverIcon from '../../../../../../components/CustomInput/CustomHoverIcon';
import CustomTextField from '../../../../../../components/CustomInput/CustomTextField';
import { getToken } from '../../../../../../selectors/app.selectors';
import CustomContextMenu from '../../../../../../components/CustomContextMenu/CustomContextMenu';
import { ICandidateAddress, ICandidateAddressForApi } from '../../../../../../definitions/entities.definition';
import { ClientApi } from '../../../../../../requests/ClientApi';
import Nl2brCellRenderer from '../../../../../../components/CustomCellRenderer/Nl2brCellRenderer';
import {
    setCandidatePageDeleteAddressFromFormAction,
    setCandidatePageUpdateAddressInFormAction,
} from '../../../../../../redux/candidatePage/candidatePage.actions';
import { translate } from '../../../../../../translation/translate.utils';
import CountryAutocomplete
    from '../../../../../../components/CustomInput/CustomAutocomplete/components/CountryAutocomplete';
import { getSuggestionAction } from '../../../../../../redux/entities/entities.actions';
import { add } from 'husky';

interface IProps {
    index: number;
    candidateId?: number;
    suggestionId?: number;
    addresses: ICandidateAddress[];
    address: ICandidateAddress;
}

const CandidateAddressItemView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const candidateId = props.candidateId;
    const address = props.address;
    const addresses = props.addresses;
    const suggestionId = props.suggestionId;
    const index = props.index;

    const [city, setCity] = useState(address.city);
    const [street, setStreet] = useState(address.address);
    const [zipCode, setZipCode] = useState(address.zipCode);
    const [comment, setComment] = useState(address.comment);
    const [country, setCountry] = useState(address.country || address.countryId || null);

    const onDelete = () => {
        if (!candidateId) {
            dispatch(setCandidatePageDeleteAddressFromFormAction(index));
            return;
        }

        ClientApi.request(deleteCandidateAddressRouteConfig, {
            token,
            candidateId,
            id: addresses[index].id,
        }).then(() => {
            dispatch(registerInfo('Datensatz wurde gelöscht.'));

            if (suggestionId) {
                dispatch(getSuggestionAction(token, suggestionId));
                return;
            }

            dispatch(setCandidatePageDeleteAddressFromFormAction(index));
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht gelöscht werden'));
        });
    };

    const onUpdate = (setDefault?: boolean) => {
        if (!setDefault && street === address.address && comment === address.comment && city === address.city && zipCode === address.zipCode && (country && address.country && country.id === address.country.id)) {
            return Promise.resolve();
        }

        const updateInStore = (index: number, address: ICandidateAddress) => {
            if (setDefault) {
                addresses.forEach((address, index) => {
                    if (!address.default) {
                        return;
                    }
                    dispatch(setCandidatePageUpdateAddressInFormAction(index, {...address, default: false}));
                });
            }
            dispatch(setCandidatePageUpdateAddressInFormAction(index, address));
        };

        if (!candidateId) {
            updateInStore(index, address);
            return Promise.resolve();
        }

        const putAddress: ICandidateAddressForApi = {
            id: address.id,
            address: street,
            city: city,
            zipCode: zipCode,
            default: setDefault || mailAddress.default,
            comment: address.comment,
            countryId: country ? country.id : undefined,
        }

        return ClientApi.request(putCandidateAddressRouteConfig, {
            token,
            candidateId,
            ...putAddress
        }).then((address: ICandidateAddress) => {
            if (suggestionId) {
                dispatch(getSuggestionAction(token, suggestionId));
                return;
            }

            updateInStore(index, address);
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht aktualisiert werden'));
        });
    };

    const contextMenu: IListActionButtonConfig[] = [{
        icon: EIcons.Remove,
        action: () => onDelete(),
        translationKey: 'tables.openDeleteView',
    }, {
        icon: EIcons.CheckCircle,
        action: () => onUpdate(true),
        translationKey: 'misc.markAsDefault',
        condition: (entity) => {
            const entityCast = entity as ICandidateAddress;
            return addresses.length > 1 && !entityCast.default;
        },
    }, {
        icon: EIcons.Copy,
        action: () => {
            navigator.clipboard.writeText(address.zipCode + ' ' + address.city + (address.address ?  ', ' + address.address : ''));
            dispatch(registerInfo('Adresse wurde kopiert'));
        },
        translationKey: 'misc.copy',
    }, {
        icon: EIcons.LocationOn,
        action: () => {
            window.open('https://www.google.de/maps/place/' + address.zipCode + ' ' + address.city + (address.address ?  ', ' + address.address : ''));
        },
        label: 'Auf Google Maps öffnen'
    }];

    return <>
        <CustomContextMenu
            index={index}
            entityId={address.id}
            entity={address}
            menuHeading={(entity) => {
                const entityCast = entity as ICandidateAddress;
                return entityCast.address;
            }}
            menuItems={contextMenu}
        >
            <CustomEditableValue
                key={address.id}
                theme={'light'}
                value={"Adresse"}
                onSave={onUpdate}
                input={<>
                    <div className={"flexContainerColumn flex1 gap10"}>
                        <div className={"flexContainerRow flex1 gap10"}>
                            <div className={"flexContainerRow flex1 gap10"}>
                                <CustomTextField
                                    size={'small'}
                                    required
                                    value={zipCode}
                                    onChange={(value) => setZipCode(value)}
                                    label={translate('misc.zipCode')}
                                    width={70}
                                />
                                <CustomTextField
                                    size={'small'}
                                    required
                                    label={translate('misc.city')}
                                    value={city}
                                    onChange={(value) => setCity(value)}
                                />
                            </div>
                            <CountryAutocomplete
                                size={'small'}
                                value={country}
                                onChange={(country) => setCountry(country)}
                            />
                        </div>
                        <div className={"flexContainerRow flex1"}>
                            <CustomTextField
                                size={'small'}
                                value={street}
                                onChange={(value) => setStreet(value)}
                                label={translate('misc.address')}
                            />
                        </div>
                        <div className={"flexContainerRow flex1"}>
                            <CustomTextField
                                size={'small'}
                                value={comment}
                                multiline
                                rows={5}
                                onChange={(value) => setComment(value)}
                                label={translate('misc.comment')}
                            />
                        </div>
                    </div>
                </>}
            >
                <div className={'flexContainerRow gap5 alignVerticalCenter'}>
                    {address.zipCode} {address.city} {address.address && <>, {address.address}</>}
                    {addresses.length > 1 && address.default &&
                        <CustomHoverIcon iconStyle={{width:'0.8em', height:'0.8em'}} icon={EIcons.CheckCircle} tooltip={'ist Standard-Adresse'}/>
                    }
                </div>
                {address.comment &&
                    <div className={'formContent marginLeft5'}>
                        <Nl2brCellRenderer value={address.comment}/>
                    </div>
                }
            </CustomEditableValue>
        </CustomContextMenu>
    </>;
};

export default CandidateAddressItemView;

import { useEffect } from 'react';
import { getProcessAggregationListByCandidateAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import { getProcessesForCandidate } from '../../../../../selectors/process.selectors';

export const useCandidateProcessListHook = (candidateId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.candidateProcessesLoading);
    const reload = useSelector((store: IStore) => store.candidatePage.reloadProcessesOfCandidate);

    const processes = useSelector((store: IStore) => getProcessesForCandidate(store, candidateId));

    useEffect(() => {
        if (!loading) {
            dispatch(getProcessAggregationListByCandidateAction(token, candidateId));
        }
    }, []);

    useEffect(() => {
        if (reload && !loading) {
            dispatch(getProcessAggregationListByCandidateAction(token, candidateId));
        }
    }, [reload]);

    return {loading, processes, total: processes.length};
}

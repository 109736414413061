import React, { useState } from 'react';
import {
    ECandidateDocumentType,
    EEntities,
    ERecruitingStatus,
    IRecruitingTimelineInformation,
} from '../../../definitions/entities.definition';
import { translate } from '../../../translation/translate.utils';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { ICustomerRecruitingListModel } from '../../../definitions/entities.customer.definitions';
import RecruitingStatusTimelineCellRenderer from './components/RecruitingStatusTimelineCellRenderer';
import { API_URL } from '../../../requests/routes';
import { useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import UrlCellRenderer from '../../../components/CustomCellRenderer/UrlCellRenderer';
import RecruitingCandidateCellRenderer from './components/RecruitingCandidateCellRenderer';
import { COLOR_BLUE } from '../../../theme/theme';
import CandidateProfilesRenderer from '../../../components/CustomCellRenderer/CandidateProfilesRenderer';
import { ECellAlign } from '../../../definitions/components.definitions';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { EIcons } from '../../../components/Icons/IconFactory';
import DeclineRecruitingView from '../DeclineRecruitingView';
import InquireForRecruitingView from '../InquireForRecruitingView';
import AcceptRecruitingView from '../AcceptRecruitingView';

interface IProps {
    records: ICustomerRecruitingListModel[];
    loading: boolean;
    onReload: () => void;
}

const RecruitingList: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const recruitings = props.records;
    const loading = props.loading;

    const [recruitingIdToDecline, setRecruitingIdToDecline] = useState(0);
    const [recruitingIdToAccept, setRecruitingIdToAccept] = useState(0);
    const [recruitingIdToAcceptStatus, setRecruitingIdToAcceptStatus] = useState(ERecruitingStatus.STATUS_SUGGEST);
    const [recruitingIdToAcceptSalary, setRecruitingIdToAcceptSalary] = useState(0);
    const [recruitingIdToInquire, setRecruitingIdToInquire] = useState(0);

    return <>
        {Boolean(recruitingIdToDecline) &&
            <DeclineRecruitingView
                recruitingId={recruitingIdToDecline}
                open
                onClose={() => setRecruitingIdToDecline(0)}
                callback={props.onReload}
            />
        }
        {Boolean(recruitingIdToInquire) &&
            <InquireForRecruitingView
                recruitingId={recruitingIdToInquire}
                open
                onClose={() => setRecruitingIdToInquire(0)}
                callback={props.onReload}
            />
        }
        {Boolean(recruitingIdToAccept) &&
            <AcceptRecruitingView
                recruitingId={recruitingIdToAccept}
                status={recruitingIdToAcceptStatus}
                open
                onClose={() => {
                    setRecruitingIdToAccept(0);
                    setRecruitingIdToAcceptStatus(ERecruitingStatus.STATUS_SUGGEST);
                    setRecruitingIdToAcceptSalary(0);
                }}
                callback={props.onReload}
            />
        }
        <CustomTable
            style={{borderRadius: '6px 6px 0px 0px'}}
            config={{
                entity: EEntities.Recruiting,
                color: COLOR_BLUE,
                roundBorder: true,
                loading,
                denyVerticalCenter: true,
                columnConfig: {
                    action: {
                        header: <>Feedback |<br/>Rückfrage</>,
                        property: 'status',
                        width: 80,
                        align: ECellAlign.center,
                        disableOrder: true,
                        cellRenderer: (status: ERecruitingStatus, entity) => {
                            const entityCast = entity as ICustomerRecruitingListModel;

                            const supportElement = <CustomClickableIcon
                                tooltip={"Rückfrage an Peagle senden"}
                                onClick={() => setRecruitingIdToInquire(entityCast.id)}
                                icon={EIcons.ContactSupport}
                                iconStyle={{color: 'white'}}
                            />;

                            if (!entityCast.customerActionRequired || entityCast.interviewActionIsRequired) {
                                return <div className={"flexContainerRow"} style={{height: 65}}>
                                    {supportElement}
                                </div>
                            }
                            return <div className={"flexContainerRow"} style={{height: 65}}>
                                <CustomClickableIcon
                                    tooltip={entityCast.status === ERecruitingStatus.STATUS_SUGGEST ? "Vorschlag ablehnen" : "Negatives Feedback zum Bewerbungsgespräch eintragen"}
                                    onClick={() => setRecruitingIdToDecline(entityCast.id)}
                                    icon={EIcons.ThumbDown}
                                    iconStyle={{color: 'white'}}
                                />
                                {supportElement}
                                <CustomClickableIcon
                                    tooltip={entityCast.status === ERecruitingStatus.STATUS_SUGGEST ? "Vorschlag annehmen" : "Positives Feedback zum Bewerbungsgespräch eintragen"}
                                    onClick={() => {
                                        setRecruitingIdToAccept(entityCast.id);
                                        setRecruitingIdToAcceptStatus(entityCast.status);
                                        setRecruitingIdToAcceptSalary(entityCast.desiredYearSalary || 0);
                                    }}
                                    icon={EIcons.ThumbUp}
                                    iconStyle={{color: 'white'}}
                                />
                            </div>
                        }
                    },
                    candidate: {
                        header: translate('pages.activity.properties.candidate'),
                        property: "candidateFullName",
                        width: 250,
                        cellRenderer: (fullName: string, entity) => {
                            const entityCast = entity as ICustomerRecruitingListModel;
                            return <RecruitingCandidateCellRenderer token={token} recruiting={entityCast}/>
                        }
                    },
                    status: {
                        header: 'Status',
                        property: "interviews",
                        width: 300,
                        orderByProperty: "nextAppointment",
                        cellRenderer: (interviews: IRecruitingTimelineInformation[], entity) => {
                            const entityCast = entity as ICustomerRecruitingListModel;
                            return <RecruitingStatusTimelineCellRenderer recruiting={entityCast}/>
                        },
                    },
                    vacancyTitle: {
                        header: 'Position',
                        property: "vacancyTitle",
                        width: 300,
                        cellRenderer: (vacancyTitle: string, entity) => {
                            const entityCast = entity as ICustomerRecruitingListModel;

                            return <div className={"flexContainerColumn gap5"}>
                                {vacancyTitle}
                                {entityCast.vacancyUrlIntern && <UrlCellRenderer hideLaunchIcon value={<img height={20} src={'https://peagle.de/_Resources/Static/Packages/Peagle.Website/Images/favicons/apple-touch-icon-114x114.png'}/>} url={entityCast.vacancyUrlIntern} />}
                                {entityCast.vacancyUrlExtern && <UrlCellRenderer hideLaunchIcon value={<img height={20} src={'https://helsing.ai/favicon-32x32.png'} />} url={entityCast.vacancyUrlExtern} />}
                            </div>
                        }
                    },
                    documents: {
                        header: "Dokument/e",
                        property: "documents",
                        width: 150,
                        cellRenderer: (documents: {id: number, fileName: string, type: ECandidateDocumentType}[], entity) => {
                            return <>{documents.map((document) => {
                                const type = document.type === ECandidateDocumentType.INTERN_PROFILE ? ECandidateDocumentType.CV : document.type;
                                return <UrlCellRenderer
                                    key={document.id}
                                    value={translate('pages.candidateDocument.typeValue.' + type)}
                                    url={`${API_URL}/candidate-document/${document.id}/preview?Authorization=${token}&recruitingId=${entity.id}`}
                                />
                            })}</>
                        }
                    },
                    web: {
                        header: "Web",
                        property: "profiles",
                        cellRenderer: (id: number, entity) => {
                            const entityCast = entity as ICustomerRecruitingListModel;

                            return <CandidateProfilesRenderer profiles={{
                                furtherUrl: entityCast.furtherUrl,
                                githubUrl: entityCast.githubUrl,
                                xingUrl: entityCast.xingUrl,
                                homepageUrl: entityCast.homepageUrl,
                                linkedInUrl: entityCast.linkedInUrl
                            }}/>
                        }
                    },
                },
                sortedEntries: recruitings,
                count: recruitings.length,
                onReload: props.onReload
            }}
        />
    </>;
}

export default RecruitingList;

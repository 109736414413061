import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EEntities, ICandidatePartial, IInvoice, IRecruitingPartial } from '../../../definitions/entities.definition';
import { getDefaultCompanyColumn, INFINITE_SCROLL_INTERVAL } from '../../../utils/components.utils';
import { ECellAlign, ESortingOptions } from '../../../definitions/components.definitions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { IStore } from '../../../definitions/redux/store.definitions';
import { translate } from '../../../translation/translate.utils';
import { getToken } from '../../../selectors/app.selectors';
import { getInvoiceListAction } from '../../../redux/entities/entities.actions';
import { getBillingPageInvoiceListRequestObject, getInvoiceListFromStore } from '../../../selectors/billing.selectors';
import {
    setBillingPageAddPaymentForInvoiceAction,
    setBillingPageInvoiceListFilterAction,
    setBillingPageInvoiceListSortingOptionAction,
    setBillingPageInvoiceToDeleteAction,
    setBillingPageInvoiceToEditAction,
} from '../../../redux/billingPage/billingPage.actions';
import { EDocumentStatus, EInvoiceType } from '../../../definitions/billingPage.definitions';
import DateCellRenderer from '../../../components/CustomCellRenderer/DateCellRenderer';
import CurrencyCellRenderer from '../../../components/CustomCellRenderer/CurrencyCellRenderer';
import RecruitingCellRenderer from '../../../components/CustomCellRenderer/RecruitingCellRenderer';
import InvoiceTypeCellRenderer from '../../../components/CustomCellRenderer/InvoiceTypeCellRenderer';
import DocumentStatusCellRenderer from '../../../components/CustomCalendar/DocumentStatusCellRenderer';
import { EIcons } from '../../../components/Icons/IconFactory';
import CandidateCellRenderer from '../../../components/CustomCellRenderer/CandidateCellRenderer';
import { API_URL } from '../../../requests/routes';
import { Tooltip } from '@material-ui/core';

const InvoiceList: React.FC = () => {
    const dispatch = useDispatch();
    const invoices = useSelector(getInvoiceListFromStore);
    const loading = useSelector((store: IStore) => store.ui.invoicesLoading);
    const filter = useSelector((store: IStore) => store.billingPage.filterInvoiceList);
    const totalRecords = useSelector((store: IStore) => store.entities.invoice.total);
    const requestObject = useSelector(getBillingPageInvoiceListRequestObject);
    const token = useSelector(getToken);

    return (
        <CustomTable
            config={{
                groupBy: (entity) => {
                    const entityCast = entity as IInvoice;

                    if (entityCast.status === EDocumentStatus.closed || entityCast.status === EDocumentStatus.cancelled) {
                        return 'Abgeschlossen';
                    }

                    return 'Offen';
                },
                groupHeaderExtraInformation: (entities) => {
                    const entitiesCast = entities as IInvoice[];
                    const sum = entitiesCast.reduce((sum, current) => sum + current.totalNet, 0);

                    return <>&nbsp;Summe: <CurrencyCellRenderer value={sum} roundedToThousand/></>
                },
                entity: EEntities.Invoice,
                columnConfig: {
                    number: {
                        header: translate('pages.billing.invoiceNumber'),
                        property: "number",
                        width: 160,
                    },
                    status: {
                        header: translate('misc.status'),
                        property: "status",
                        width: 120,
                        cellRenderer: (status: EDocumentStatus) => {
                            return <DocumentStatusCellRenderer status={status} />
                        }
                    },
                    invoiceType: {
                        header: 'Typ',
                        property: 'invoiceType',
                        width: 60,
                        align: ECellAlign.center,
                        cellRenderer: (invoiceType: EInvoiceType) => {
                            return <InvoiceTypeCellRenderer invoiceType={invoiceType} />
                        }
                    },
                    date: {
                        header: translate('misc.date'),
                        property: "date",
                        width: 100,
                        cellRenderer: (dateTime: string) => <DateCellRenderer date={dateTime} />,
                    },
                    dueDate: {
                        header: translate('misc.dueDate'),
                        property: "dueDate",
                        width: 100,
                        cellRenderer: (dateTime: string, entity) => {
                            const invoice = entity as IInvoice;

                            if (invoice.status === EDocumentStatus.closed) {
                                return <DateCellRenderer date={dateTime} />
                            }
                            if (invoice.dunningLetters.length > 0) {
                                return <div className={"flexContainerColumn"}>
                                    <DateCellRenderer date={invoice.dunningLetters[0].dueDate} />
                                    <Tooltip title={"Ursprüngliches Fälligkeits-Datum"}>
                                        <div className={"formContent"}><DateCellRenderer date={dateTime} /></div>
                                    </Tooltip>
                                </div>
                            }
                            if (invoice.remindOfPaymentDueDate) {
                                return <div className={"flexContainerColumn"}>
                                    <DateCellRenderer date={invoice.remindOfPaymentDueDate} />
                                    <Tooltip title={"Ursprüngliches Fälligkeits-Datum"}>
                                        <div className={"formContent"}><DateCellRenderer date={dateTime} /></div>
                                    </Tooltip>
                                </div>
                            }
                            return <DateCellRenderer date={dateTime} />
                        },
                    },
                    company: getDefaultCompanyColumn(),
                    candidate: {
                        header: translate('pages.activity.properties.candidate'),
                        disableOrder: true,
                        property: 'recruiting.candidate',
                        cellRenderer: (candidate: ICandidatePartial) => {
                            if (candidate) {
                                return <CandidateCellRenderer candidate={candidate} />
                            }
                        }
                    },
                    recruiting: {
                        width: 150,
                        disableOrder: true,
                        header: 'Prozess',
                        property: 'recruiting',
                        cellRenderer: (recruiting: IRecruitingPartial) => {
                            if (recruiting) {
                                return <RecruitingCellRenderer recruiting={recruiting} />
                            }
                        }
                    },
                    totalNet: {
                        header: translate('pages.billing.totalNet'),
                        property: "totalNet",
                        align: ECellAlign.right,
                        width: 120,
                        cellRenderer: (value: number) => <CurrencyCellRenderer value={value} />
                    },
                },
                sortedEntries: invoices,
                loading: loading,
                scrollCallback: () => {
                    if (filter.limit && filter.limit < totalRecords) {
                        dispatch(setBillingPageInvoiceListFilterAction({
                            start: 0,
                            limit: filter.limit + INFINITE_SCROLL_INTERVAL,
                        }));
                    }
                },
                onRowDoubleClick: (invoiceId: number) => dispatch(setBillingPageInvoiceToEditAction(
                    invoiceId
                )),
                listActionButtons: [{
                    action: (invoiceId: number, entity) => {
                        const invoice = entity as IInvoice;
                        window.open(`${API_URL}/invoice/${invoice.id}/print?Authorization=${token}`)
                    },
                    translationKey: "pages.billing.printDocument",
                    icon: EIcons.Print,
                }, {
                    action: (invoiceId: number) => dispatch(setBillingPageAddPaymentForInvoiceAction(invoiceId)),
                    translationKey: "pages.billing.addPayment",
                    icon: EIcons.Money,
                }, {
                    action: (invoiceId: number) => dispatch(setBillingPageInvoiceToDeleteAction(invoiceId)),
                    translationKey: "tables.openDeleteView",
                    icon: EIcons.Delete,
                }],
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                        dispatch(setBillingPageInvoiceListSortingOptionAction(propertyToSort, sortOption)),
                },
                onReload: () => {
                    dispatch(getInvoiceListAction(token, requestObject));
                },
                count: invoices.length,
                total: totalRecords,
            }}
        />
    );
}

export default InvoiceList;

import React from 'react';
import { EEntities } from '../../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { translate } from '../../../../../translation/translate.utils';
import {
    setCompanyPageCompanyContactSortingOptionAction,
    setCompanyPageCompanyContactToDeleteAction,
} from '../../../../../redux/companyPage/companyPage.actions';
import { EIcons } from '../../../../../components/Icons/IconFactory';
import { ESortingOptions } from '../../../../../definitions/components.definitions';
import { setUiEditView } from '../../../../../redux/ui/ui.actions';
import { getToken } from '../../../../../selectors/app.selectors';
import { getContactsForCompanyAction } from '../../../../../redux/entities/entities.actions';
import { getCompanyPageRequestObjectForCompanyContactsInEditView } from '../../../../../selectors/company.selectors';
import { COLOR_BLUE } from '../../../../../theme/theme';
import { useCompanyContactListHook } from './CompanyContactList.hooks';
import { EEntityView } from '../../../../../definitions/ui.definitions';
import { getDefaultLastActivityColumn } from '../../../../../utils/components.utils';

interface IProps {
    companyId: number;
}

const CompanyContactList: React.FC<IProps> = (props) => {
    const companyId = props.companyId;
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const companyContactsRequestObject = useSelector(getCompanyPageRequestObjectForCompanyContactsInEditView);
    const { loading, contacts, total} = useCompanyContactListHook(companyId);

    return (
        <CustomTable
            config={{
                color: COLOR_BLUE,
                entity: EEntities.CompanyContact,
                loading,
                columnConfig: {
                    fullName: {
                        header: translate('pages.companyContact.properties.name'),
                        property: "fullName",
                        width: 150
                    },
                    position: {
                        header: translate('pages.companyContact.properties.position'),
                        property: "position",
                        width: 100
                    },
                    phoneNumber: {
                        header: translate('misc.phoneNumber'),
                        property: "phoneNumber",
                        width: 110
                    },
                    comment: {
                        header: translate('misc.comment'),
                        property: "comment",
                    },
                    lastActivity: getDefaultLastActivityColumn(),
                },
                sortedEntries: contacts,
                onRowDoubleClick: (contactId: number) => dispatch(setUiEditView(EEntityView.companyContact, contactId)),
                listActionButtons: [
                    {
                        action: (contactId: number) => dispatch(setCompanyPageCompanyContactToDeleteAction(contactId)),
                        translationKey: "tables.openDeleteView",
                        icon: EIcons.Delete,
                    },
                ],
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                        dispatch(setCompanyPageCompanyContactSortingOptionAction(propertyToSort, sortOption)),
                },
                total: total,
                onReload: () => {
                    dispatch(getContactsForCompanyAction(
                        token,
                        companyId,
                        companyContactsRequestObject
                    ));
                }
            }}
        />
    );
};

export default CompanyContactList;

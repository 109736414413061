import React, { ReactNode } from 'react';
import { InputAdornment, Tooltip } from '@material-ui/core';
import CustomClickableIcon from './CustomClickableIcon';
import { EIcons } from '../Icons/IconFactory';
import CustomTextField from './CustomTextField';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField/TextField';

interface IProps extends Omit<OutlinedTextFieldProps, "variant"> {
    onChange?: (value: any) => void;
    value?: null|string;
    url?: null|string;
    error?: boolean;
    helperText?: ReactNode|string;
    flex?: number;
    size?: 'small'|'medium';
    importFunction?: () => void;
}

const CustomUrlField: React.FC<IProps> = (props) => {
    let url = props.url || props.value;

    if (props.value && !props.value.includes('http')) {
        url = 'https://' + props.value;
    }


    return <>
        <CustomTextField
            flex={props.flex}
            value={props.value}
            onChange={props.onChange}
            label={props.label}
            error={props.error}
            size={props.size}
            helperText={props.helperText}
            required={props.required}
            InputProps={{
                startAdornment: url
                    ? <Tooltip title={"Link öffnen"}>
                        <InputAdornment position="end"><CustomClickableIcon onClick={() => window.open(url)} icon={EIcons.Launch}/></InputAdornment>
                    </Tooltip>
                    : undefined,
                endAdornment: url && props.importFunction
                    ? <Tooltip title={"Daten importieren"}>
                        <InputAdornment position="end"><CustomClickableIcon onClick={props.importFunction} icon={EIcons.Download}/></InputAdornment>
                    </Tooltip>
                    : undefined
            }}
        />
    </>
};

export default CustomUrlField;

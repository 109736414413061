import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { EIcons } from '../../../../components/Icons/IconFactory';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import { EEntities } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { EFilterPanelType } from '../../../../definitions/filter.definitions';
import { setVacancyPageAddVacancyDialogAction } from '../../../../redux/vacancyPage/vacancyPage.actions';
import CompanyVacancyList from './components/CompanyVacancyList';
import { setCompanyPageVacancyListFilterAction } from '../../../../redux/companyPage/companyPage.actions';

interface IProps {
    companyId: number;
}

const CompanyVacancyPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const companyId = props.companyId;
    const filter = useSelector((store: IStore) => store.companyPage.companyVacancyFilter);

    const configuration: IAppBarConfig = {
        entity: EEntities.CompanyVacancy,
        value: filter.fastSearch || '',
        emptyValue: translate('pages.vacancy.fastSearchEmptyText'),
        fastSearchCallback: (fastSearch) => {
            dispatch(setCompanyPageVacancyListFilterAction({fastSearch}))
        },
        actionButtons: [{
            action: () => dispatch(setVacancyPageAddVacancyDialogAction(true, companyId)),
            tooltip: "Vakanz hinzufügen",
            icon: EIcons.Add
        }],
        filterPanelType: EFilterPanelType.popupMenu
    };

    return <>
        <div className={"flexColumn padding5"}>
            <div className={"flexAutoGrow"}>
                <CustomFastSearchPanel appBarConfig={configuration}/>
            </div>
            <CompanyVacancyList companyId={companyId} />
        </div>
    </>;
};

export default CompanyVacancyPanel;

import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { EStorageItem } from '../../definitions/app.definitions';
import { IPinnedViewState } from '../../definitions/ui.definitions';

interface IProps {
    token: string;
    pinnedViews: IPinnedViewState[];
}

/**
 * renderless controller
 */
class StorageController extends React.PureComponent<IProps> {
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.token && this.props.token !== prevProps.token) {
            localStorage.setItem(EStorageItem.Token, this.props.token);
        }

        if (JSON.stringify(this.props.pinnedViews) !== JSON.stringify(prevProps.pinnedViews)) {
            localStorage.setItem(EStorageItem.Views, JSON.stringify(this.props.pinnedViews));
        }
    }

    render() {
        return null;
    }
}

const mapState = (store: IStore) => ({
    token: store.actor.token,
    pinnedViews: store.ui.pinnedViews
});
export default connect(mapState)(StorageController);

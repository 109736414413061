import React from 'react';
import CustomDeleteDialog from '../../../components/CustomDialog/CustomDeleteDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { deleteActivityAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';
import { setUiActivityToDeleteAction } from '../../../redux/ui/ui.actions';

const ActivityDeleteView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.ui.deleteActivityId));
    const deleteActivityId = useSelector((store: IStore) => store.ui.deleteActivityId);
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    const onDelete = () => {
        dispatch(deleteActivityAction(
            token,
            deleteActivityId
        ));
        dispatch(setUiActivityToDeleteAction(0));
    };

    const onClose = () => {
        dispatch(setUiActivityToDeleteAction(0));
    }

    return (
        <CustomDeleteDialog
            open={open}
            onClose={onClose}
            onDelete={onDelete}
            titleTranslation={"pages.activity.deleteDialog.title"}
            bodyTranslation={"pages.activity.deleteDialog.body"}
        />
    );
};

export default ActivityDeleteView;

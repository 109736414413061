import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import {
    EActivityModule,
    EActivityType,
    EFeedbackType,
    ERecruitingStatus,
    IRecruitingPartial,
} from '../../../../definitions/entities.definition';
import CustomDialogHeader from '../../../../components/CustomDialog/CustomDialogHeader';
import { translate } from '../../../../translation/translate.utils';
import TopBarButton from '../../../../components/ListAndPreview/TopBarButton';
import { setUiActivityAddDataAction } from '../../../../redux/ui/ui.actions';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { addActivityAction } from '../../../../redux/entities/entities.actions';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedInEmployeeFromStore } from '../../../../selectors/employee.selectors';
import { getToken } from '../../../../selectors/app.selectors';
import { IStore } from '../../../../definitions/redux/store.definitions';

interface IProps {
    open: boolean;
    onClose: () => void;
    recruitingId: number;
}

const RecruitingAddActivityView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const recruitingId = props.recruitingId;
    const recruiting = useSelector((store: IStore) => store.entities.recruiting.byId[recruitingId]);
    const open = props.open;

    if (!open) {
        return null;
    }

    return <>
        <CustomDialog open={open} onClose={props.onClose} maxWidth={'xs'}>
            <CustomDialogHeader string={translate('pages.activity.add')} onClose={props.onClose}/>
            <div className={"flexContainerColumn gap10 padding10"}>
                {Boolean(recruiting?.status === ERecruitingStatus.STATUS_INTERVIEW || recruiting?.status === ERecruitingStatus.STATUS_SUGGEST) &&
                    <>
                        <div className={"flexContainerRow gap10"}>
                            <TopBarButton
                                onClick={() => {
                                    dispatch(setUiActivityAddDataAction({
                                        add: true,
                                        module: EActivityModule.recruiting,
                                        companyId: recruiting.company.id,
                                        vacancyId: recruiting.vacancy ? recruiting.vacancy.id : undefined,
                                        candidateId: recruiting.candidate.id,
                                        recruitingId: recruiting.id,
                                        preSelectedEmployee: recruiting.responsibleEmployee,
                                        isInterview: true,
                                    }));
                                    props.onClose();
                                }}
                                size={"medium"}
                                icon={EIcons.Calendar}
                                label={translate('pages.recruiting.addInterview')}
                            />
                        </div>
                        <div className={"flexContainerRow gap10"}>
                            <TopBarButton
                                onClick={() => {
                                    dispatch(setUiActivityAddDataAction({
                                        add: true,
                                        module: EActivityModule.recruiting,
                                        companyId: recruiting.company.id,
                                        vacancyId: recruiting.vacancy ? recruiting.vacancy.id : undefined,
                                        candidateId: recruiting.candidate.id,
                                        recruitingId: recruiting.id,
                                        preSelectedEmployee: recruiting.responsibleEmployee,
                                        isContractOffer: true,
                                    }));
                                    props.onClose();
                                }}
                                size={"medium"}
                                icon={EIcons.Money}
                                label={translate('pages.recruiting.addContractOffer')}
                            />
                        </div>
                    </>
                }
                {recruiting?.status === ERecruitingStatus.STATUS_CONTRACT &&
                    <div className={"flexContainerRow"}>
                        <TopBarButton
                            onClick={() => {
                                dispatch(setUiActivityAddDataAction({
                                    add: true,
                                    module: EActivityModule.recruiting,
                                    companyId: recruiting.company.id,
                                    vacancyId: recruiting.vacancy ? recruiting.vacancy.id : undefined,
                                    candidateId: recruiting.candidate.id,
                                    preSelectedEmployee: recruiting.responsibleEmployee,
                                    recruitingId: recruiting.id,
                                    isFeedback: true,
                                    feedbackType: EFeedbackType.trial_end,
                                    company: recruiting.company,
                                    vacancy: recruiting.vacancy ? recruiting.vacancy : undefined,
                                    candidate: recruiting.candidate,
                                    recruiting: recruiting as IRecruitingPartial,
                                }));
                                props.onClose();
                            }}
                            size={"medium"}
                            icon={EIcons.Feedback}
                            label={translate('pages.recruiting.feedbackTypeValue.trial_end')}
                        />
                    </div>
                }
                <div className={"flexContainerRow gap10"}>
                    <TopBarButton
                        onClick={() => {
                            dispatch(setUiActivityAddDataAction({
                                add: true,
                                module: EActivityModule.recruiting,
                                candidateId: recruiting.candidate.id,
                                vacancyId: recruiting.vacancy ? recruiting.vacancy.id : undefined,
                                recruitingId: recruiting.id,
                            }));
                            props.onClose();
                        }}
                        size={"medium"}
                        icon={EIcons.Person}
                        label={translate('pages.recruiting.addCandidateHistory')}
                    />
                    <TopBarButton
                        onClick={() => {
                            dispatch(addActivityAction(
                                token, {
                                    module: EActivityModule.recruiting,
                                    candidateId: recruiting.candidate.id,
                                    vacancyId: recruiting.vacancy ? recruiting.vacancy.id : undefined,
                                    recruitingId: recruiting.id,
                                    employeeId: loggedInEmployee.id,
                                    type: EActivityType.phoneNotReached,
                                    dateTime: moment(new Date()).format('DD.MM.Y H:mm:ss'),
                                },
                            ));
                            props.onClose();
                        }}
                        size={"medium"}
                        icon={EIcons.PhoneDisabled}
                        label={translate('pages.activity.fastEntryNotCandidateReached')}
                    />
                </div>
                <div className={"flexContainerRow gap10"}>
                    <TopBarButton
                        onClick={() => {
                            dispatch(setUiActivityAddDataAction({
                                add: true,
                                module: EActivityModule.recruiting,
                                companyId: recruiting.company.id,
                                vacancyId: recruiting.vacancy ? recruiting.vacancy.id : undefined,
                                recruitingId: recruiting.id,
                            }));
                            props.onClose();
                        }}
                        size={"medium"}
                        icon={EIcons.Apartment}
                        label={translate('pages.recruiting.addCompanyHistory')}
                    />
                    <TopBarButton
                        onClick={() => {
                            dispatch(addActivityAction(
                                token, {
                                    module: EActivityModule.recruiting,
                                    companyId: recruiting.company.id,
                                    vacancyId: recruiting.vacancy ? recruiting.vacancy.id : undefined,
                                    recruitingId: recruiting.id,
                                    employeeId: loggedInEmployee.id,
                                    type: EActivityType.phoneNotReached,
                                    dateTime: moment(new Date()).format('DD.MM.Y H:mm:ss'),
                                },
                            ));
                            props.onClose();
                        }}
                        size={"medium"}
                        icon={EIcons.PhoneDisabled}
                        label={translate('pages.activity.fastEntryNotCompanyReached')}
                    />
                </div>
            </div>
        </CustomDialog>
    </>;
};

export default RecruitingAddActivityView;

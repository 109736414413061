import {
    ISetMailboxAction,
    ISetMailingFilterAction,
    ISetMailingPagingAction,
    ISetShowMessageAction,
} from '../../definitions/redux/actions.definition';
import { IMailingFilter } from '../../definitions/mailing.definitions';
import { IMailbox } from '../../definitions/entities.definition';

export const MAILING_SET_FILTER = "MAILING_SET_FILTER";
export const MAILING_SET_PAGING = "MAILING_SET_PAGING";
export const MAILING_SET_SHOW_MESSAGE = "MAILING_SET_SHOW_MESSAGE";
export const MAILING_SET_MAILBOX = "MAILING_SET_MAILBOX";

export const setMailingFilterAction = (filter: Partial<IMailingFilter>): ISetMailingFilterAction => ({
    type: MAILING_SET_FILTER,
    payload: filter
});

export const setMailingPagingAction = (start: number, limit: number): ISetMailingPagingAction => ({
    type: MAILING_SET_PAGING,
    payload: { start, limit }
});

export const setShowMessageAction = (messageId: number): ISetShowMessageAction => ({
    type: MAILING_SET_SHOW_MESSAGE,
    payload: messageId
});

export const setMailboxAction = (mailbox: IMailbox): ISetMailboxAction => ({
    type: MAILING_SET_MAILBOX,
    payload: mailbox
});

import React, { useState } from 'react';
import RenderProcessListItem from './RenderProcessListItem';
import { IProcessAggregation } from '../../../definitions/entities.definition';
import { EIcons } from '../../../components/Icons/IconFactory';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { translate } from '../../../translation/translate.utils';

interface IProps {
    status: string;
    process: IProcessAggregation[];
}

const RenderProcessListGroup: React.FC<IProps> = (props) => {
    const status = props.status;
    const process = props.process;
    const [expanded, setExpanded] = useState(true);
    const icon = expanded ? EIcons.ExpandLess : EIcons.ExpandMore;
    const toggleExpand = () => setExpanded(!expanded);

    return <>
        <div className={"flexContainerRow"} onClick={toggleExpand} style={{cursor: 'pointer', backgroundColor: 'var(--brainz-darkest)'}}>
            <h4 style={{margin: 5, flex: 1}}>{translate('pages.process.statusValues.' + status)} ({process.length})</h4>
            <CustomClickableIcon onClick={toggleExpand} icon={icon}/>
        </div>
        {expanded &&
        <>
            {process.map((process, index) =>
                <RenderProcessListItem
                    key={index}
                    process={process}
                />
            )}
        </>
        }
    </>
}

export default React.memo(RenderProcessListGroup);

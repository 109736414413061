import React, { useEffect } from 'react';
import { translate } from '../../../translation/translate.utils';
import UrlCellRenderer from '../../../components/CustomCellRenderer/UrlCellRenderer';
import DateCellRenderer from '../../../components/CustomCellRenderer/DateCellRenderer';
import VacancySkillElement from '../../RecruitingPage/components/components/VacancySkillElement';
import { getWebVacancyAction } from '../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import { Chip } from '@material-ui/core';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';

interface IProps {
    webVacancyId: number;
}

const WebVacancyDataPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const webVacancyId = props.webVacancyId;
    const webVacancy = useSelector((store: IStore) => store.entities.webVacancy.byId[webVacancyId]);
    const skills = webVacancy?.skills || [];
    const languages = webVacancy?.languages || [];

    useEffect(() => {
        if (webVacancyId > 0 && (!webVacancy || webVacancy.id !== webVacancyId)) {
            dispatch(getWebVacancyAction(token, webVacancyId))
        }
    }, [webVacancyId]);

    if (webVacancyId > 0 && (webVacancy === undefined || webVacancyId !== webVacancy.id)) {
        return <>
            <CustomBackdropLoadingInsideDiv open />
        </>;
    }

    return (
        <>
            <div className={"flexContainerRow"}>
                <div className={"formRowColumn flex1"}>
                    <div className={"formLabel"}>{translate('misc.url')}:</div>
                    <div><UrlCellRenderer value={"Öffnen"} url={webVacancy.url}/></div>
                </div>
                <div className={"formRowColumn flex1"}>
                    <div className={"formLabel"}>Teilzeit:</div>
                    <div>{webVacancy.partTime ? 'Ja' : 'Nein'}</div>
                </div>
                <div className={"formRowColumn flex1"}>
                    <div className={"formLabel"}>{translate('pages.webVacancy.locations')}:</div>
                    <div> {webVacancy.locations.join(', ')}</div>
                </div>
                <div className={"formRowColumn flex1"}>
                    <div className={"formLabel"}>{translate('misc.created')}:</div>
                    <div><DateCellRenderer date={webVacancy.created}/></div>
                </div>
                <div className={"formRowColumn flex1"}>
                    <div className={"formLabel"}>{translate('misc.languages')}:</div>
                    <div>
                        {languages.map((language, index) =>
                            <div style={{padding:5}} key={index}>
                                <Chip
                                    key={index}
                                    label={<> {language.name} </>}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={"flexContainerRow"}>
                <div className={"formRowColumn"}>
                    <div className={"formLabel"}>Gesuchte Fähigkeiten:</div>
                    <div>
                        <ul style={{userSelect: 'text'}}>
                            {webVacancy.skillTextsFromAdvertisement && webVacancy.skillTextsFromAdvertisement.map((skill: string, index) =>
                                <li key={index}>{skill}</li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className={"formRowColumn"}>
                    <div className={"formLabel"}>Geforderte Aufgaben:</div>
                    <div>
                        <ul style={{userSelect: 'text'}}>
                            {webVacancy.taskTextsFromAdvertisement && webVacancy.taskTextsFromAdvertisement.map((task: string, index) =>
                                <li key={index}>{task}</li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={"flexContainerRow flex1 overflow "} style={{alignItems: 'start'}}>
                <div className={"flexContainerRow flexAutoGrow gap5 flexWrap"}>
                    {skills.map((vacancySkill, index) =>
                        <VacancySkillElement
                            key={index}
                            skill={vacancySkill}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default WebVacancyDataPanel;

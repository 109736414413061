import React from 'react';
import CustomFilterItem from '../CustomSidePanelList/CustomFilterItem';
import { EIcons } from '../../../Icons/IconFactory';
import YesNoDropDown from '../../../CustomInput/CustomDropDownInput/YesNoDropDown';
import PaperRow from '../../../CustomPaper/PaperRow';

interface IProps {
    value?: boolean;
    onChange: (value?: boolean) => void;
}

const HasDocumentsFilter: React.FC<IProps> = (props) => {
    const value = props.value;

    const onChange = (value?: boolean) => {
        props.onChange(value);
    }

    const resetFilter = () => {
        props.onChange();
    }

    const valueRendered = value === true ? 'Nur mit Unterlagen' : (value === false ? 'Nur ohne Unterlagen' : '');
    const deletable = value !== undefined;

    return <>
        <CustomFilterItem
            title={"Unterlagen"}
            icon={EIcons.AttachFile}
            renderedValue={valueRendered}
            active={deletable}
            removeFilterAction={resetFilter}
            width={400}
            autoClose
        >
            <PaperRow>
                <YesNoDropDown
                    onChange={onChange}
                    label={"Unterlagen vorhanden"}
                    value={value}
                />
            </PaperRow>
        </CustomFilterItem>
    </>;
};

export default React.memo(HasDocumentsFilter);

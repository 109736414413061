import React, { useState } from 'react';
import FolderTreeItem from './FolderTreeItem';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, List, ListItem, ListItemText, ListSubheader, MenuItem } from '@material-ui/core';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomBackdropLoadingInsideDiv from '../../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import CustomDropDownInput from '../../../../components/CustomInput/CustomDropDownInput/CustomDropDownInput';
import { setMailboxAction } from '../../../../redux/mailing/mailing.actions';

const MailboxView: React.FC = () => {
    const dispatch = useDispatch();
    const selectedMailboxId = useSelector((store: IStore) => store.mailing.selectedMailboxId || 0);
    const mailbox = useSelector((store: IStore) => store.entities.mail.mailbox[selectedMailboxId]);
    const mailboxes = useSelector((store: IStore) => store.entities.mail.mailbox);
    const loading = useSelector((state: IStore) => state.ui.mailboxTreeLoading);
    const filter = useSelector((state: IStore) => state.mailing.filter);
    const [selectMailbox, setSelectMailbox] = useState(false);

    if (selectedMailboxId === 0) {
        return <>
            <List
                dense
                subheader={<>
                    <ListSubheader style={{backgroundColor:'var(--brainz-darkest)'}}>
                        Postfach
                    </ListSubheader>
                </>}
            />
        </>;
    }

    const getMailboxElement = () => {
        const mailboxesCount = Object.entries(mailboxes).length;
        if (mailboxesCount === 1) {
            return <>
                <ListSubheader style={{backgroundColor:'var(--brainz-darkest)'}}>
                    Postfach
                </ListSubheader>
                <ListItem button style={{paddingTop: 0, paddingBottom: 0}}>
                    <ListItemText primary={mailbox.username}/>
                </ListItem>
            </>;
        }

        if (selectMailbox) {
            return <>
                <ListSubheader style={{backgroundColor:'var(--brainz-darkest)'}}>
                    Postfach
                </ListSubheader>
                <ListItem className={"flexContainerRow"}>
                    <CustomDropDownInput
                        id={"mailbox-dropdown"}
                        onChange={(event) => {
                            dispatch(setMailboxAction(mailboxes[event.target.value]));
                            setSelectMailbox(false);
                        }}
                        size={"small"}
                        required
                        value={mailbox.id}
                        label={"Postfach"}
                    >
                        {Object.values(mailboxes).map((mailbox) => <MenuItem key={mailbox.id} value={mailbox.id}>{mailbox.username}</MenuItem>)}
                    </CustomDropDownInput>
                    <IconButton
                        onClick={() => setSelectMailbox(false)}
                        size={"small"}
                        className={"alignHorizontalRight"}
                    >
                        {IconFactory.getIconComponent(EIcons.Close)}
                    </IconButton>
                </ListItem>
            </>;
        }

        return <>
            <ListSubheader style={{backgroundColor:'var(--brainz-darkest)'}}>
                Postfach
            </ListSubheader>
            <ListItem button style={{paddingTop: 0, paddingBottom: 0}}>
                <ListItemText primary={mailbox.username} secondary={"wechseln"} onClick={() => setSelectMailbox(true)}/>
            </ListItem>
        </>;
    }

    return <>
        <CustomBackdropLoadingInsideDiv open={loading}/>
        <List
            dense
            subheader={getMailboxElement()}
            style={{overflow: 'auto'}}
        >
            {mailbox.folderTree.map((treeNode) => <FolderTreeItem
                key={treeNode.id}
                node={treeNode}
                selectedFolderId={filter.folderId}
            />
            )}
        </List>
    </>;
};

export default MailboxView;

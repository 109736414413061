import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerError, registerInfo } from '../../../../../../redux/error/error.actions';
import { EIcons } from '../../../../../../components/Icons/IconFactory';
import {
    deleteCandidateMailAddressRouteConfig,
    putCandidateMailAddressRouteConfig,
} from '../../../../../../requests/routes';
import { IListActionButtonConfig } from '../../../../../../definitions/components.definitions';
import {
    setCandidatePageDeleteMailFromFormAction,
    setCandidatePageUpdateMailInFormAction,
} from '../../../../../../redux/candidatePage/candidatePage.actions';
import CustomEditableValue from '../../../../../../components/CustomInput/CustomEditableValue';
import CustomHoverIcon from '../../../../../../components/CustomInput/CustomHoverIcon';
import CustomTextField from '../../../../../../components/CustomInput/CustomTextField';
import { getToken } from '../../../../../../selectors/app.selectors';
import CustomContextMenu from '../../../../../../components/CustomContextMenu/CustomContextMenu';
import { ICandidateMailAddress } from '../../../../../../definitions/entities.definition';
import { ClientApi } from '../../../../../../requests/ClientApi';
import Nl2brCellRenderer from '../../../../../../components/CustomCellRenderer/Nl2brCellRenderer';
import { translate } from '../../../../../../translation/translate.utils';
import { getSuggestionAction } from '../../../../../../redux/entities/entities.actions';
import { InputAdornment } from '@material-ui/core';
import CustomClickableIcon from '../../../../../../components/CustomInput/CustomClickableIcon';

interface IProps {
    index: number;
    candidateId?: number;
    suggestionId?: number;
    mailAddresses: ICandidateMailAddress[];
    mailAddress: ICandidateMailAddress;
}

const CandidateMailItemView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const candidateId = props.candidateId;
    const mailAddress = props.mailAddress;
    const mailAddresses = props.mailAddresses;
    const suggestionId = props.suggestionId;
    const index = props.index;

    const [mailAddressText, setMailAddressText] = useState(mailAddress.mailAddress);
    const [comment, setComment] = useState(mailAddress.comment);

    const onDelete = () => {
        if (!candidateId) {
            dispatch(setCandidatePageDeleteMailFromFormAction(index));
            return;
        }

        ClientApi.request(deleteCandidateMailAddressRouteConfig, {
            token,
            candidateId,
            id: mailAddress.id,
        }).then(() => {
            dispatch(registerInfo('Datensatz wurde gelöscht.'));
            if (suggestionId) {
                dispatch(getSuggestionAction(token, suggestionId));
                return;
            }
            dispatch(setCandidatePageDeleteMailFromFormAction(index));
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht gelöscht werden'));
        });
    };

    const onUpdate = (setDefault?: boolean) => {
        if (!setDefault && mailAddressText === mailAddress.mailAddress && comment === mailAddress.comment) {
            return Promise.resolve();
        }

        const updateInStore = (index: number, mail: ICandidateMailAddress) => {
            if (setDefault) {
                mailAddresses.forEach((mailAddress, index) => {
                    if (!mailAddress.default) {
                        return;
                    }
                    dispatch(setCandidatePageUpdateMailInFormAction(index, {...mail, default: false}));
                });
            }
            dispatch(setCandidatePageUpdateMailInFormAction(index, mail));
        };

        const putMail: ICandidateMailAddress = {
            id: mailAddress.id,
            mailAddress: mailAddressText,
            default: setDefault || mailAddress.default,
            comment: comment
        }

        if (!candidateId) {
            updateInStore(index, putMail);
            return Promise.resolve();
        }

        return ClientApi.request(putCandidateMailAddressRouteConfig, {
            token,
            candidateId,
            ...putMail,
        }).then((mail: ICandidateMailAddress) => {
            if (suggestionId) {
                dispatch(getSuggestionAction(token, suggestionId));
                return;
            }
            updateInStore(index, mail);
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht aktualisiert werden'));
        });
    };
    const contextMenu: IListActionButtonConfig[] = [{
        icon: EIcons.Remove,
        action: () => onDelete(),
        translationKey: 'tables.openDeleteView',
    }, {
        icon: EIcons.CheckCircle,
        action: () => onUpdate(true),
        translationKey: 'misc.markAsDefault',
        condition: (entity) => {
            const entityCast = entity as ICandidateMailAddress;
            return mailAddresses.length > 1 && !entityCast.default;
        },
    }, {
        icon: EIcons.Copy,
        action: () => {
            navigator.clipboard.writeText(mailAddress.mailAddress);
            dispatch(registerInfo('E-Mail Adresse wurde kopiert'));
        },
        translationKey: 'misc.copy',
    }];

    return <>
        <CustomContextMenu
            index={index}
            entityId={mailAddress.id}
            entity={mailAddress}
            menuHeading={(entity) => {
                const entityCast = entity as ICandidateMailAddress;
                return entityCast.mailAddress;
            }}
            menuItems={contextMenu}
        >
            <CustomEditableValue
                key={mailAddress.id}
                theme={'light'}
                input={<div className={"flexContainerColumn flex1 gap5"}>
                    <CustomTextField
                        required
                        autoFocus
                        size={'small'}
                        value={mailAddressText}
                        onChange={(value) => setMailAddressText(value)}
                        label={translate('misc.mailAddress')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <CustomClickableIcon onClick={() => {
                                    navigator.clipboard.writeText(mailAddressText);
                                    dispatch(registerInfo('E-Mail Adresse wurde kopiert'));
                                }} icon={EIcons.Copy}/>
                            </InputAdornment>
                        }}
                    />
                    <CustomTextField
                        multiline
                        size={'small'}
                        rows={5}
                        label={translate('misc.comment')}
                        value={comment}
                        onChange={(value) => setComment(value)}
                    />
                </div>}
                value={mailAddress.mailAddress}
                onSave={onUpdate}
            >
                <div className={'flexContainerRow gap5 alignVerticalCenter'}>
                    {mailAddress.mailAddress}
                    {mailAddresses.length > 1 && mailAddress.default &&
                        <CustomHoverIcon iconStyle={{width:'0.8em', height:'0.8em'}} icon={EIcons.CheckCircle} tooltip={'ist Standard-Adresse'}/>
                    }
                </div>
                {mailAddress.comment &&
                    <div className={'formContent marginLeft5'}>
                        <Nl2brCellRenderer value={mailAddress.comment}/>
                    </div>
                }
            </CustomEditableValue>
        </CustomContextMenu>
    </>;
};

export default CandidateMailItemView;

import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import MomentUtils from '@date-io/moment';
import CustomDumbTextField from './CustomDumbTextField';
import CustomClickableIcon from './CustomClickableIcon';
import { EIcons } from '../Icons/IconFactory';

interface IProps {
    label: string;
    onChange?: (date?: Date) => void;
    disabled?: boolean;
    value?: Date;
    error?: boolean;
    helperText?: string;
    flex?: boolean;
}

const CustomDateTimePicker: React.FC<IProps> = (props) => {
    const handleClear = () => {
        props.onChange(undefined);
    }

    const style = props.flex ? {flex: 1} : {width: 300};

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
                autoOk
                label={props.label}
                ampm={false}
                disabled={props.disabled}
                TextFieldComponent={CustomDumbTextField}
                value={props.value ?? null}
                clearable={true}
                error={props.error}
                helperText={props.helperText}
                InputProps={{
                    startAdornment: props.value ? <CustomClickableIcon onClick={handleClear} icon={EIcons.Close} /> : undefined
                }}
                style={style}
                onChange={(value: any) => {
                    if (!props.onChange) {
                        return;
                    }
                    if (!value) {
                        props.onChange(undefined);
                        return;
                    }
                    props.onChange(value);
                }}
                format="DD.MM.yyyy HH:mm"
            />
        </MuiPickersUtilsProvider>
    );
};

export default CustomDateTimePicker;

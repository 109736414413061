import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface IProps {
    value?: string;
    onChange: (value?: string) => void;
    label?: ReactNode;
    states: {
        value: string;
        label: string;
    }[],
    dontCareOption?: boolean;
}

const CustomRadioGroupInput: React.FC<IProps> = (props) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (value === "other") {
            props.onChange(undefined);
            return;
        }
        props.onChange(value);
    }

    const radioGroup = <>
        <RadioGroup value={props.value} onChange={onChange} style={{flexDirection: 'row'}}>
            {props.states.map((state) => <>
                <FormControlLabel key={state.label} value={state.value} control={<Radio color={"default"} />} label={state.label} />
            </>)}
            {(props.dontCareOption) &&
                <FormControlLabel key={"other"} value="other" control={<Radio color={"default"} />} label="egal" />
            }
        </RadioGroup>
    </>

    if (props.label) {
        return (
            <FormControl component="fieldset">
                <FormLabel component="legend">
                    {props.label}
                </FormLabel>
                {radioGroup}
            </FormControl>
        );
    }

    return radioGroup;
};

export default CustomRadioGroupInput;

import React from 'react';
import { ICountry, TBrainzEntity } from '../../../../definitions/entities.definition';
import CustomAutocomplete from '../CustomAutocomplete';
import { getCountryListRouteConfig } from '../../../../requests/routes';
import { translate } from '../../../../translation/translate.utils';

interface IProps {
    value: ICountry|number|null;
    onChange: (value: ICountry|null) => void;
    label?: string;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    disabled?: boolean;
    size?: 'small';
}

const CountryAutocomplete: React.FC<IProps> = (props) => {
    const value = props.value;

    const onChange = (value: TBrainzEntity|null) => {
        props.onChange(value as ICountry);
    }

    const getOptionLabel = (record: TBrainzEntity|null) => {
        return (record as ICountry).name;
    }

    return <CustomAutocomplete
        id={"country-autocomplete"}
        label={props.label || translate('misc.country')}
        value={value}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
        onChange={onChange}
        routeConfig={getCountryListRouteConfig}
        getOptionLabel={getOptionLabel}
        routeParams={{}}
        size={props.size}
    />;
}

export default React.memo(CountryAutocomplete);

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomDeleteDialog from '../../../components/CustomDialog/CustomDeleteDialog';
import { getToken } from '../../../selectors/app.selectors';
import { deleteCompanyAction } from '../../../redux/entities/entities.actions';
import { setCompanyPageCompanyToDeleteAction } from '../../../redux/companyPage/companyPage.actions';

const CompanyDeleteView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.companyPage.companyToDelete));
    const idToDelete = useSelector((store: IStore) => store.companyPage.companyToDelete);
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    if (!open) {
        return null;
    }

    return (
        <CustomDeleteDialog
            open={open}
            onClose={() => dispatch(setCompanyPageCompanyToDeleteAction(0))}
            onDelete={() => {
                dispatch(deleteCompanyAction(token, idToDelete));
                dispatch(setCompanyPageCompanyToDeleteAction(0));
            }}
            titleTranslation={"pages.company.deleteDialog.title"}
            bodyTranslation={"pages.company.deleteDialog.body"}
        />
    );
};

export default CompanyDeleteView;

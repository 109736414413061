import React, { useEffect } from 'react';
import { getCompanyContactAction } from '../../../redux/entities/entities.actions';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getToken } from '../../../selectors/app.selectors';
import { themeBlue } from '../../../theme/theme';
import CustomTabContainer from '../../../components/CustomTabContainer/CustomTabContainer';
import { ITab } from '../../../definitions/components.definitions';
import CompanyContactEditForm from './components/CompanyContactEditForm';
import CompanyContactActivityChatPanel from './components/CompanyContactActivityChatPanel';
import CompanyContactVacancyPanel from './components/CompanyContactVacancyPanel';
import CompanyContactRecruitingPanel from './components/CompanyContactRecruitingPanel';

interface IProps {
    id: number;
}

const CompanyContactView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const companyContactId = props.id;
    const companyContact = useSelector((store: IStore) => store.entities.companyContacts.byId[companyContactId]);

    const contactLoaded = companyContactId > 0 && companyContact !== undefined && companyContactId === companyContact.id;
    useEffect(() => {
        if (companyContactId > 0 && (!companyContact || companyContact.id !== companyContactId)) {
            dispatch(getCompanyContactAction(token, companyContactId));
        }
    }, [companyContactId]);

    if (companyContactId === 0) {
        return null;
    }

    if (!contactLoaded || !companyContact) {
        return <>
            <div className={"flexContainerRow flex"}>
                <CustomBackdropLoadingInsideDiv open={true} />
            </div>
        </>;
    }

    const tabs: ITab[] = [{
        label: 'Stammdaten',
        component: <>
            <CompanyContactEditForm companyContactId={companyContactId}  />
        </>
    }, {
        label: 'Vakanzen',
        component: <>
            <CompanyContactVacancyPanel companyContactId={companyContactId}  />
        </>
    }, {
        label: 'Prozesse',
        component: <>
            <CompanyContactRecruitingPanel companyContactId={companyContactId} companyId={companyContact.company.id} />
        </>
    }, {
        label: 'Historie',
        component: <>
            <CompanyContactActivityChatPanel companyContactId={companyContactId}/>
        </>
    }];


    return <>
        <div className={'flexContainerColumn flex'} style={{width: 900}}>
            <CustomTabContainer
                color={themeBlue.palette.primary.main}
                roundBorder
                tabs={tabs}
            />
        </div>
    </>;
};

export default CompanyContactView;

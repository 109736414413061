import { Button } from '@material-ui/core';
import React from 'react';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { dark } from '../../theme/theme';

interface IProps {
    onClick: VoidFunction;
    icon: EIcons;
    label: string|React.ReactElement;
    size?: 'medium'|'small';
}

const TopBarButton: React.FC<IProps> = (props) => {
    const size = props.size || 'small';
    return <>
        <Button onClick={props.onClick} variant={"contained"} size={size} color={'default'}>
            <div className={"flex flexCentered"}>
                {IconFactory.getIconComponent(props.icon, {color: dark})}&nbsp;
                {props.label}
            </div>
        </Button>
    </>;
};

export default TopBarButton;

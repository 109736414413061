import React from 'react';
import CustomQuickFilterButton from './CustomQuickFilterButton';
import { Badge, Divider } from '@material-ui/core';
import CustomQuickFilterContainer from './CustomQuickFilterContainer';
import { ESuggestionStatus } from '../../../definitions/entities.definition';
import { ISuggestionFilter } from '../../../definitions/recruitingPage.definitions';
import CustomQuickFilterDivider from './CustomQuickFilterDivider';

interface IProps {
    disabled: boolean;
    countOpen: number;
    countDeclined: number;
    countAccepted: number;
    countNeedAction: number;
    status?: ESuggestionStatus;
    actionNeeded?: boolean;
    groupByProgressStatus?: boolean;
    setFilterAction: (filter: Partial<ISuggestionFilter>) => void;
}

const CustomQuickFilterSuggestionList: React.FC<IProps> = (props) => {
    const countOpen = props.countOpen;
    const countDeclined = props.countDeclined;
    const countAccepted = props.countAccepted;
    const countNeedAction = props.countNeedAction;
    const status = props.status;
    const actionNeeded = props.actionNeeded;
    const countAll = countOpen + countDeclined + countAccepted;
    const disabled = props.disabled;
    const groupByProgressStatus = props.groupByProgressStatus || false;

    return <>
        <CustomQuickFilterContainer disabled={disabled}>
            <CustomQuickFilterButton
                disabled={disabled}
                onClick={() => props.setFilterAction({status: undefined})}
                isActive={status === undefined}
            >
                Alle &nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={countAll.toString()}/>&nbsp;
            </CustomQuickFilterButton>
            <CustomQuickFilterDivider/>
            {countOpen > 0 &&
                <CustomQuickFilterButton
                    disabled={disabled}
                    onClick={() => props.setFilterAction({status: ESuggestionStatus.OPEN})}
                    isActive={status === ESuggestionStatus.OPEN}
                >
                    Offen &nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={countOpen.toString()}/>&nbsp;
                </CustomQuickFilterButton>
            }
            {countDeclined > 0 &&
                <CustomQuickFilterButton
                    disabled={disabled}
                    onClick={() => props.setFilterAction({status: ESuggestionStatus.DECLINED})}
                    isActive={status === ESuggestionStatus.DECLINED}
                >
                    Abgelehnt &nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={countDeclined.toString()}/>&nbsp;
                </CustomQuickFilterButton>
            }
            {countAccepted > 0 &&
                <CustomQuickFilterButton
                    disabled={disabled}
                    onClick={() => props.setFilterAction({status: ESuggestionStatus.ACCEPTED})}
                    isActive={status === ESuggestionStatus.ACCEPTED}
                >
                    Proz. gestartet &nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={countAccepted.toString()}/>&nbsp;
                </CustomQuickFilterButton>
            }
            <CustomQuickFilterDivider/>
            {actionNeeded &&
                <>
                    <CustomQuickFilterButton
                        disabled={disabled}
                        onClick={() => props.setFilterAction({actionNeeded: !actionNeeded})}
                        isActive={actionNeeded}
                    >
                        Handlungsbedarf
                        {countNeedAction > 0 &&
                            <>&nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={countNeedAction.toString()}/>&nbsp;</>
                        }
                    </CustomQuickFilterButton>
                    <Divider orientation={"vertical"} style={{height: '90%', alignSelf: 'center', backgroundColor: 'rgba(233, 237, 239, 0.2)'}}/>
                </>
            }
            <CustomQuickFilterButton
                disabled={disabled}
                onClick={() => props.setFilterAction({groupByProgressStatus: !groupByProgressStatus})}
                isActive={groupByProgressStatus}
            >
                Nach Kontakt gruppieren
            </CustomQuickFilterButton>
        </CustomQuickFilterContainer>
    </>;
}

export default CustomQuickFilterSuggestionList;

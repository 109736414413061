import React from 'react';
import { EEntities } from '../../../../definitions/entities.definition';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import { IAppBarActionButtonConfig } from '../../../../definitions/components.definitions';

interface IProps {
    fastSearch: string;
    setFastSearch: (fastSearch: string) => void;
    actionButtons?: IAppBarActionButtonConfig[];
}

const FastSearchPanelSuggestionList: React.FC<IProps> = (props) => {
    const fastSearch = props.fastSearch;

    const configuration: IAppBarConfig = {
        entity: EEntities.Suggestion,
        value: fastSearch,
        emptyValue: "Nach Namen oder Referenznummer suchen",
        fastSearchCallback: props.setFastSearch,
        actionButtons: props.actionButtons
    };

    return <>
        <div className={"flexAutoGrow"}>
            <CustomFastSearchPanel appBarConfig={configuration}/>
        </div>
    </>
}

export default FastSearchPanelSuggestionList;

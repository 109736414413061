import {
    EActivityModule,
    EFeedbackType,
    ETodo,
    ICandidatePartial,
    IRecruitingPartial,
    ITodo,
    ITodoWithDue,
} from '../definitions/entities.definition';
import { setTodoToEditAction } from '../redux/todo/todo.actions';
import { setUiActivityAddDataAction, setUiActivityToEditAction, setUiEditView } from '../redux/ui/ui.actions';
import React, { Dispatch } from 'react';
import { EIcons, IconFactory } from '../components/Icons/IconFactory';
import { COLOR_BLUE, COLOR_TEXT, COLOR_YELLOW } from '../theme/theme';
import { translate } from '../translation/translate.utils';
import CustomTypography from '../components/CustomTypography/CustomTypography';
import moment from 'moment';
import { getFormattedDateWithDayString, getFormattedTimeString } from './date.utils';
import Nl2brCellRenderer from '../components/CustomCellRenderer/Nl2brCellRenderer';
import { setBillingPageInvoiceToEditAction } from '../redux/billingPage/billingPage.actions';
import { EEntityView } from '../definitions/ui.definitions';
import { Tooltip } from '@material-ui/core';

export const executeTodoAction = (todo: ITodo|ITodoWithDue, dispatch: Dispatch<any>) => {
    switch (todo.todoEnum) {
        case ETodo.manual:
            dispatch(setTodoToEditAction(todo.id as number));
            break;
        case ETodo.trialPeriodFailed: {
            const candidate = todo.candidate as ICandidatePartial;
            dispatch(setUiEditView(EEntityView.candidate, candidate.id));
            break;
        }
        case ETodo.suggestionAppointmentFeedback:
            if (todo.recruitingSuggestion) {
                dispatch(setUiEditView(EEntityView.suggestion, todo.recruitingSuggestion.id));
            }
            break;
        case ETodo.paymentMissing:
            dispatch(setBillingPageInvoiceToEditAction(todo.invoiceId as number));
            break;
        case ETodo.invoiceMissing:
        case ETodo.congratulationMail:
        case ETodo.recruitingInformationMissing: {
            const recruiting = todo.recruiting as IRecruitingPartial;
            dispatch(setUiEditView(EEntityView.recruiting, recruiting.id));
            break;
        }
        case ETodo.suggestionResponsibleChanged:
            if (todo.recruitingSuggestion) {
                dispatch(setUiEditView(EEntityView.suggestion, todo.recruitingSuggestion.id));
            }
            break;
        case ETodo.interviewFeedbackMissing:
        case ETodo.contractStartFeedbackMissing:
        case ETodo.trialPeriodFeedbackMissing:
            if (todo.activityId) {
                dispatch(setUiActivityToEditAction(todo.activityId));
                break;
            }

            if (!todo.recruiting) {
                break;
            }

            dispatch(setUiActivityAddDataAction({
                add: true,
                module: EActivityModule.recruiting,
                companyId: todo.recruiting.company.id,
                vacancyId: todo.recruiting.vacancy ? todo.recruiting.vacancy.id : undefined,
                candidateId: todo.recruiting.candidate.id,
                preSelectedEmployee: todo.recruiting.responsibleEmployee,
                recruitingId: todo.recruiting.id,
                isFeedback: true,
                feedbackType: todo.todoEnum === ETodo.contractStartFeedbackMissing ? EFeedbackType.start : EFeedbackType.trial_end,
                company: todo.recruiting.company,
                vacancy: todo.recruiting.vacancy ? todo.recruiting.vacancy : undefined,
                candidate: todo.recruiting.candidate,
                recruiting: todo.recruiting
            }));
            break;
        case ETodo.suggestionWithPhoneNumber:
            if (!todo.vacancy) {
                break;
            }
            dispatch(setUiEditView(EEntityView.vacancy, todo.vacancy.id));
            break;
    }
}

export const getTodoCardDueDate = (dueDate: undefined|string, formatDueDate: undefined|'time'|'datetime', isFlexible: boolean) => {
    if (!dueDate) {
        return '';
    }
    if (formatDueDate === 'datetime' && isFlexible) {
        return getFormattedDateWithDayString(dueDate);
    }
    if (formatDueDate === 'time' && !isFlexible) {
        return getFormattedTimeString(dueDate);
    }
    if (formatDueDate === 'time' && isFlexible) {
        return 'Flexibel'
    }

    return getFormattedDateWithDayString(dueDate, true);
}

export const getTodoDescription = (todo: ITodo|ITodoWithDue) => {
    const showDaysLeft = todo.todoEnum === ETodo.trialPeriodFeedbackMissing || todo.todoEnum === ETodo.contractStartFeedbackMissing;
    const isManualTodo = todo.todoEnum === ETodo.manual;

    return (
        <div className={"flexColumn"}>
            <div className={"flexContainerRow alignVerticalCenter gap5"}>
                { todo.completed && <> {IconFactory.getIconComponent(EIcons.Check, {color: COLOR_TEXT, background: COLOR_BLUE, borderRadius: 16})}</> }
                { todo.new && <>{IconFactory.getIconComponent(EIcons.New, {color: COLOR_YELLOW})}</> }
                { todo.createdByUser.isContact && <Tooltip title={"Kundenanfrage"}>{IconFactory.getIconComponent(EIcons.ContactSupport, {color: COLOR_BLUE})}</Tooltip> }
            </div>
            <div className={"flexContainerRow alignVerticalCenter"}>
                { todo.vacancy && <>{todo.vacancy.title}</>}
                { !isManualTodo && !todo.vacancy &&todo.company && <>Initiativbewerbung</> }
                { isManualTodo && <Nl2brCellRenderer value={todo.description}/>}
                { !isManualTodo && translate('pages.todo.todoValues.' + todo.todoEnum) }&nbsp;
                { todo.todoEnum === ETodo.suggestionAppointmentFeedback && todo.recruitingSuggestion?.fullName}
            </div>
            { todo.todoEnum === ETodo.suggestionResponsibleChanged && <div>{todo.description}</div>}
            { (showDaysLeft || todo.dueDate) && (todo.daysLeft as number) < 0 &&
                <div className={"flexContainerRow alignVerticalCenter"}>
                    seit {(todo.daysLeft as number) * -1} Tagen überfällig
                </div>
            }
            { showDaysLeft && (todo.daysLeft as number) > 0 &&
                <div className={"flexContainerRow alignVerticalCenter"}>
                    in {(todo.daysLeft as number)} Tagen fällig
                </div>
            }
        </div>
    );
}

export const getTodoDuration = (todo: ITodo) => {
    const dueDate = moment(todo.dueDate);
    const dueDateTo = moment(todo.dueDateTo);
    return dueDateTo.diff(dueDate, 'minutes');
}

export const getTodoDescriptionShort = (todo: ITodo|ITodoWithDue) => {
    return (
        <>
            { todo.new && <div>{IconFactory.getIconComponent(EIcons.New, {color: COLOR_YELLOW})}&nbsp;</div> }
            <div>
                { todo.todoEnum === ETodo.manual
                    ? <CustomTypography value={todo.description} variant={"body2"} />
                    : <CustomTypography value={translate('pages.todo.todoValues.' + todo.todoEnum)} variant={"body2"} />
                }
            </div>
        </>
    );
}

import React, { useEffect } from 'react';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setDashboardPageRecruitingsOfLast7DaysViewOpenAction } from '../../../redux/dashboardPage/dashboardPage.actions';
import { getRecruitingsOfLast7DaysAction } from '../../../redux/entities/entities.actions';
import RecruitingsOfLast7DaysTable from './tables/RecruitingsOfLast7DaysTable';
import { buildRequestObject } from '../../../selectors/entity.selectors';
import CustomFilterMenu from '../../../components/CustomFilter/CustomPopupFilterMenu/CustomFilterMenu';
import { EEntities } from '../../../definitions/entities.definition';
import { translate } from '../../../translation/translate.utils';

const RecruitingsOfLast7DaysView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector((store: IStore) => store.actor.token);
    const open = useSelector((store: IStore) => store.dashboardPage.recruitingsOfLast7DaysViewOpen);
    const handleClose = () => dispatch(setDashboardPageRecruitingsOfLast7DaysViewOpenAction(false));
    const sorting = useSelector((store: IStore) => store.dashboardPage.sortingRecruitingOfLast7Days);
    const filter = useSelector((store: IStore) => store.dashboardPage.filterRecruitingOfLast7Days);

    useEffect(() => {
        if (open) {
            dispatch(getRecruitingsOfLast7DaysAction(token, buildRequestObject(sorting, filter)));
        }
    }, [dispatch, open, JSON.stringify(sorting), JSON.stringify(filter)]);

    if (!open) {
        return null;
    }

    return (
        <>
            <CustomDialog fullWidth onClose={handleClose} open={open}>
                <CustomDialogHeader string={translate('pages.dashboard.recruitingsOfLast7Days', [filter.days.toString()])} onClose={handleClose}>
                    <CustomFilterMenu entity={EEntities.RecruitingsOfLast7Days} />
                </CustomDialogHeader>
                <RecruitingsOfLast7DaysTable />
            </CustomDialog>
        </>
    );
};

export default RecruitingsOfLast7DaysView;

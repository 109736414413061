/**
 * Custom Async Action Creator für maximale modularität bei Error handling
 *
 * @param actionType der Action type ohne suffix
 * @param request arrow function die ClienApi.request() returned
 */
import { IFile } from '../definitions/app.definitions';
import axios from 'axios';

export const actionCreator = (params: any): Promise<any> => {
    return (dispatch: any) => {
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        return axios
            .post(`127.0.0.1/api/token`, params, config)
            .then((res) => {
                dispatch({
                    type: 'LOGGED_IN',
                    payload: res.data,
                });
                return res;
            })
            .catch((err) => {
                console.log(err);
                alert('Provided username and password is incorrect');
                throw err;
            });
    };
};

export const asyncActionCreator = (
    actionType: string,
    request: () => Promise<any>,
    meta?: any,
    successCallback?: (response: any) => void
): Promise<any> => {
    return (dispatch: any) =>
    {
        const pendingActionType = `${actionType}_PENDING`;
        const fulfilledActionType = `${actionType}_FULFILLED`;
        const rejectedActionType = `${actionType}_REJECTED`;

        dispatch({ type: pendingActionType, meta });

        return request()
            .then((response: any) => {
                if (successCallback) {
                    successCallback(response);
                }
                dispatch({
                    type: fulfilledActionType,
                    payload: response,
                    meta,
                });
                return response;
            })
            .catch((error: any) => {
                console.log(rejectedActionType);
                console.log(error);
                dispatch({
                    type: rejectedActionType,
                    payload: { errorMessage: error },
                });
                return error;
            });
    };
}

export const downloadFile = (result: IFile) => {
    const linkSource = `data:${result.fileType};base64,${result.fileContent}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = result.fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

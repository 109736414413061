import React from 'react';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { connect, ConnectedProps } from 'react-redux';
import { addInterviewAction, updateInterviewAction } from '../../../redux/entities/entities.actions';
import { getToken, getValidationErrorsFromStore } from '../../../selectors/app.selectors';
import {
    EInterviewType,
    ERecruitingAbortedBy,
    ERecruitingDeclineReason,
    ERecruitingStatus,
    IAddInterviewForApi,
    ICompanyContactPartial,
    IEmployee,
    IEmployeePartial,
    IUpdateInterviewForApi,
} from '../../../definitions/entities.definition';
import LegacyEmployeeAutocompleteDropDown
    from '../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import { translate } from '../../../translation/translate.utils';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';
import autobind from 'autobind-decorator';
import CustomDateTimePicker from '../../../components/CustomInput/CustomDateTimePicker';
import moment from 'moment';
import Moment from 'moment';
import { setUiActivityToDeleteAction, setUiCompanyContactAddDataAction } from '../../../redux/ui/ui.actions';
import InterviewTypeDropDown from '../../../components/CustomInput/CustomDropDownInput/InterviewTypeDropDown';
import { COLOR_TEXT } from '../../../theme/theme';
import CustomCheckboxInput from '../../../components/CustomInput/CustomCheckboxInput';
import CustomDatePicker from '../../../components/CustomInput/CustomDatePicker';
import CompanyContactAutocomplete
    from '../../../components/CustomInput/CustomAutocomplete/components/CompanyContactAutocomplete';
import { Divider } from '@material-ui/core';
import RecruitingAbortedByDropDown
    from '../../../components/CustomInput/CustomDropDownInput/RecruitingAbortedByDropDown';
import RecruitingDeclineReasonDropDown
    from '../../../components/CustomInput/CustomDropDownInput/RecruitingDeclineReasonDropDown';
import CustomNumberInput from '../../../components/CustomInput/CustomNumberInput';
import { setRecruitingPageReloadRecruitingAction } from '../../../redux/recruitingPage/recruitingPage.actions';

interface IProps extends PropsFromRedux {
    onClose: () => void;
}

type TState = {
    interviewType: EInterviewType,
    interviewCancelled: boolean,
    recruitingAbort: boolean,
    contractOffer: boolean,
    feedbackCompany: string,
    feedbackCandidate: string,
    feedbackCompanyDate?: Date,
    feedbackCandidateDate?: Date,
    employee: IEmployee|IEmployeePartial;
    companyContact: ICompanyContactPartial|null;
    dateTime?: Date;
    userInput: string;
    dateOfAbort?: Date;
    abortedBy?: ERecruitingAbortedBy;
    declineReason?: ERecruitingDeclineReason;
    abortComment?: string;
    candidateFollowupDate?: Date;
    offeredContractBeginningDate?: Date;
    offeredContractSalary?: number;
}

class InterviewForm extends React.PureComponent<IProps, TState> {
    constructor(props: IProps) {
        super(props);

        const initialActivity = props.initialActivity;
        const addData = props.addData;

        this.state = {
            interviewType: initialActivity?.interviewType || addData?.interviewType || EInterviewType.phone,
            interviewCancelled: initialActivity?.interviewCancelled || false,
            recruitingAbort: initialActivity?.recruitingAbort || false,
            contractOffer: initialActivity?.contractOffer || false,
            feedbackCompany: initialActivity?.feedbackCompany || '',
            feedbackCandidate: initialActivity?.feedbackCandidate || '',
            feedbackCompanyDate: initialActivity && initialActivity.feedbackCompanyDate ? new Date(initialActivity.feedbackCompanyDate) : undefined,
            feedbackCandidateDate: initialActivity && initialActivity.feedbackCandidateDate ? new Date(initialActivity.feedbackCandidateDate) : undefined,
            employee: initialActivity?.employee || this.props.addData.preSelectedEmployee || this.props.loggedInEmployee,
            companyContact: initialActivity?.companyContact || addData?.contactId || undefined,
            dateTime: initialActivity ? new Date(initialActivity?.dateTime) : new Date(),
            userInput: initialActivity?.userInput || '',
            dateOfAbort: (initialActivity?.recruiting?.dateOfAbort) ? new Date(initialActivity?.recruiting?.dateOfAbort) : new Date(),
            abortedBy: initialActivity?.recruiting?.abortedBy,
            declineReason: initialActivity?.recruiting?.declineReason,
            abortComment: initialActivity?.recruiting?.abortComment,
            offeredContractSendAt: initialActivity?.data ? initialActivity?.data['offeredContractSendDate'] : undefined,
            offeredContractBeginningDate: initialActivity?.data ? initialActivity?.data['offeredContractBeginningDate'] : undefined,
            offeredContractSalary: initialActivity?.data ? initialActivity?.data['offeredContractSalary'] : undefined,
            candidateFollowupDate: (initialActivity?.candidate?.followupDate) ? new Date(initialActivity.candidate.followupDate) : undefined
        }
    }

    @autobind
    save() {
        if (this.props.addData.add) {
            const activity: IAddInterviewForApi = {
                employeeId: this.state.employee?.id || 0,
                dateTime: moment(this.state.dateTime).format("DD.MM.Y H:mm:ss"),
                companyContactId: this.state.companyContact?.id,
                interviewType: this.state.interviewType,
                userInput: this.state.userInput,
                recruitingId: this.props.addData.recruitingId
            };

            this.props.addInterviewAction(this.props.token, activity);
            return;
        }

        const activity: IUpdateInterviewForApi = {
            id: this.props.initialActivity.id,
            employeeId: this.state.employee?.id || 0,
            dateTime: moment(this.state.dateTime).format("DD.MM.Y H:mm:ss"),
            feedbackCompanyDate: this.state.feedbackCompanyDate ? moment(this.state.feedbackCompanyDate).format("DD.MM.Y H:mm:ss") : undefined,
            feedbackCandidateDate: this.state.feedbackCandidateDate ? moment(this.state.feedbackCandidateDate).format("DD.MM.Y H:mm:ss") : undefined,
            companyContactId: this.state.companyContact?.id,
            interviewType: this.state.interviewType,
            feedbackCompany: this.state.feedbackCompany,
            feedbackCandidate: this.state.feedbackCandidate,
            userInput: this.state.userInput,
            interviewCancelled: this.state.interviewCancelled,
            dateOfAbort: this.state.dateOfAbort ? moment(this.state.dateOfAbort).format("DD.MM.Y H:mm:ss") : undefined,
            abortedBy: this.state.abortedBy,
            abortComment: this.state.abortComment,
            declineReason: this.state.declineReason,
            candidateFollowupDate: this.state.candidateFollowupDate ? moment(this.state.candidateFollowupDate).format("DD.MM.Y H:mm:ss") : undefined,
            contractOffer: this.state.contractOffer,
            recruitingAbort: this.state.recruitingAbort,
            offeredContractBeginningDate: this.state.offeredContractBeginningDate ? moment(this.state.offeredContractBeginningDate).format('DD.MM.Y H:mm:ss') : undefined,
            offeredContractSalary: this.state.offeredContractSalary,
        };

        this.props.updateInterviewAction(this.props.token, activity, () => {
            if (this.state.recruitingAbort) {
                this.props.setRecruitingPageReloadRecruitingAction();
            }
        });
        return;
    }

    render() {
        const feedbackPossible = (Moment(this.state.dateTime)).isBefore(Moment());
        const feedbackNotPossibleHint = feedbackPossible ? undefined : 'Feedback erst nach dem Termin möglich';
        const contractOfferForm = <>
            <h4 style={{margin: 5}}>{translate('pages.recruiting.contractOfferInformation')}</h4>
            <PaperRow>
                <CustomDatePicker
                    label={'Geplanter Vertragsbeginn'}
                    flex
                    value={this.state.offeredContractBeginningDate}
                    required
                    onChange={(value) => this.setState({
                        offeredContractBeginningDate: value
                    })}
                    error={Boolean(this.props.validationErrors.offeredContractBeginningDate)}
                    helperText={this.props.validationErrors.offeredContractBeginningDate}
                />
                <CustomNumberInput
                    suffix={'k p.a.'}
                    label={'Gebotenes Jahresgehalt'}
                    value={this.state.offeredContractSalary}
                    onChange={(value) => this.setState({
                        offeredContractSalary: value
                    })}
                    error={Boolean(this.props.validationErrors.offeredContractSalary)}
                    helperText={this.props.validationErrors.offeredContractSalary}
                />
            </PaperRow>
            </>;

        const abortForm = <>
            <h4 style={{margin: 5}}>{translate('pages.recruiting.abortInformation')}</h4>
            <PaperRow>
                <CustomDatePicker
                    label={translate('pages.recruiting.dateOfAbort')}
                    value={this.state.dateOfAbort}
                    disableFuture={true}
                    required
                    onChange={(value) => this.setState({
                        dateOfAbort: value
                    })}
                    error={Boolean(this.props.validationErrors.dateOfAbort)}
                    helperText={this.props.validationErrors.dateOfAbort}
                />
                <RecruitingAbortedByDropDown
                    value={this.state.abortedBy}
                    onChange={(event) => this.setState({
                        abortedBy: event.target.value
                    })}
                    error={Boolean(this.props.validationErrors.abortedBy)}
                    helperText={this.props.validationErrors.abortedBy}
                />
            </PaperRow>
            <PaperRow>
                <RecruitingDeclineReasonDropDown
                    onChange={(reason) => this.setState({
                        declineReason: reason
                    })}
                    abortedBy={this.state.abortedBy}
                    value={this.state.declineReason}
                    error={Boolean(this.props.validationErrors.declineReason)}
                    helperText={this.props.validationErrors.declineReason}
                />
                {Boolean(this.props.initialActivity && this.props.initialActivity.recruiting?.status !== ERecruitingStatus.STATUS_ABORT) &&
                    <CustomDatePicker
                        label={'Wiedervorlage am für Kandidat erstellen (optional)'}
                        flex
                        value={this.state.candidateFollowupDate}
                        onChange={(value) => this.setState({
                            candidateFollowupDate: value
                        })}
                        error={Boolean(this.props.validationErrors.candidateFollowupDate)}
                        helperText={this.props.validationErrors.candidateFollowupDate}
                    />
                }
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    multiline
                    required
                    label={translate('pages.recruiting.abortComment')}
                    value={this.state.abortComment}
                    onChange={(value) => this.setState({
                        abortComment: value
                    })}
                    error={Boolean(this.props.validationErrors.abortComment)}
                    helperText={this.props.validationErrors.abortComment}
                    rows={7}
                />
            </PaperRow>
        </>;

        return (
            <>
                <PaperRow>
                    <div className={"flexContainerColumn flex1"}>
                        <div className={"flex1 alignVerticalCenter gap10"}>
                            <CustomDateTimePicker
                                flex
                                label={'Interview Datum & Uhrzeit'}
                                value={this.state.dateTime}
                                onChange={(value) => this.setState({
                                    dateTime: value
                                })}
                            />
                            {this.props.initialActivity &&
                            <CustomCheckboxInput
                                checked={this.state.interviewCancelled}
                                onChange={() => this.setState((state) => ({
                                    interviewCancelled: !state.interviewCancelled
                                }))}
                                label={<>Termin hat <br/>nicht statt gefunden</>}
                            />
                            }
                        </div>
                        <InterviewTypeDropDown
                            value={this.state.interviewType}
                            onChange={(event) => this.setState({
                                interviewType: event.target.value
                            })}
                            error={Boolean(this.props.validationErrors.interviewType)}
                            helperText={this.props.validationErrors.interviewType}
                        />
                        <LegacyEmployeeAutocompleteDropDown
                            label={"Koordiniert von Mitarbeiter"}
                            value={this.state.employee}
                            onChange={(event, value) => this.setState({
                                employee: value
                            })}
                            error={Boolean(this.props.validationErrors.employeeId)}
                            helperText={this.props.validationErrors.employeeId}
                        />
                        <CompanyContactAutocomplete
                            value={this.state.companyContact}
                            onChange={(value ) => this.setState({
                                companyContact: value
                            })}
                            label={translate('pages.recruiting.interviewContactPerson')}
                            onAdd={() => {
                                if (this.props.companyId) {
                                    this.props.setUiCompanyContactAddDataAction({
                                        companyId: this.props.companyId
                                    });
                                }
                            }}
                            companyId={this.props.companyId}
                        />
                    </div>
                    <CustomTextField
                        multiline
                        rows={12}
                        label={translate('misc.internalComment')}
                        value={this.state.userInput}
                        onChange={(value) => this.setState({
                            userInput: value
                        })}
                        error={Boolean(this.props.validationErrors.userInput)}
                        helperText={this.props.validationErrors.userInput}
                    />
                </PaperRow>
                {this.props.initialActivity && !this.state.interviewCancelled &&
                    <>
                        <Divider style={{margin: 5, backgroundColor: 'var(--brainz-darker)'}} />
                        <PaperRow>
                            <div className={"flexContainerColumn flex1"}>
                                <CustomDatePicker
                                    flex
                                    label={translate('pages.recruiting.feedbackCompanyDate')}
                                    value={this.state.feedbackCompanyDate}
                                    onChange={(value) => this.setState({
                                        feedbackCompanyDate: value
                                    })}
                                />
                                <CustomTextField
                                    multiline
                                    disabled={!feedbackPossible}
                                    rows={6}
                                    label={translate('pages.recruiting.feedbackCompany')}
                                    value={this.state.feedbackCompany}
                                    onChange={(value) => this.setState({
                                        feedbackCompany: value
                                    })}
                                    FormHelperTextProps={{
                                        style: !feedbackPossible ? {
                                            color: COLOR_TEXT
                                        }: {}
                                    }}
                                    error={Boolean(this.props.validationErrors.feedbackCompany)}
                                    helperText={feedbackNotPossibleHint || this.props.validationErrors.feedbackCompany}
                                />
                            </div>
                            <div className={"flexContainerColumn flex1"}>
                                <CustomDatePicker
                                    flex
                                    label={translate('pages.recruiting.feedbackCandidateDate')}
                                    value={this.state.feedbackCandidateDate}
                                    onChange={(value) => this.setState({
                                        feedbackCandidateDate: value
                                    })}
                                />
                                <CustomTextField
                                    multiline
                                    disabled={!feedbackPossible}
                                    rows={6}
                                    label={translate('pages.recruiting.feedbackCandidate')}
                                    value={this.state.feedbackCandidate}
                                    onChange={(value) => this.setState({
                                        feedbackCandidate: value
                                    })}
                                    FormHelperTextProps={{
                                        style: !feedbackPossible ? {
                                            color: COLOR_TEXT
                                        }: {}
                                    }}
                                    error={Boolean(this.props.validationErrors.feedbackCandidate)}
                                    helperText={feedbackNotPossibleHint || this.props.validationErrors.feedbackCandidate}
                                />
                            </div>
                        </PaperRow>
                        <Divider style={{margin: 5, backgroundColor: 'var(--brainz-darker)'}} />
                        <PaperRow>
                            <CustomCheckboxInput
                                checked={this.state.contractOffer}
                                onChange={() => this.setState((state) => ({
                                    contractOffer: !state.contractOffer
                                }))}
                                label={<b>Kandidat hat Angebot erhalten</b>}
                            />
                            <CustomCheckboxInput
                                checked={this.state.recruitingAbort}
                                onChange={() => this.setState((state) => ({
                                    recruitingAbort: !state.recruitingAbort
                                }))}
                                label={<b>Prozess abbrechen</b>}
                            />
                        </PaperRow>
                        {this.state.contractOffer &&
                        <>
                            {contractOfferForm}
                        </>
                        }
                        {this.state.recruitingAbort &&
                            <>
                                {abortForm}
                            </>
                        }
                    </>
                }
                <CustomDialogActions
                    onClose={this.props.onClose}
                    buttons={[{
                        void: () => {this.props.setUiActivityToDeleteAction(this.props.initialActivity?.id)},
                        hidden: Boolean(!this.props.initialActivity),
                        label: translate('misc.buttons.delete')
                    }, {
                        void: this.save,
                        label: translate('misc.buttons.saveAndClose')
                    }]}
                />
            </>
        );
    }
}

const mapState = (store: IStore) => {
    const initialActivity = store.entities.activities.byId[store.ui.editActivityId];
    let companyId = 0;
    if (store.ui.addActivityData.add && store.ui.addActivityData.companyId) {
        companyId = store.ui.addActivityData.companyId;
    }
    if (!store.ui.addActivityData.add && initialActivity && initialActivity.company) {
        companyId = initialActivity.company.id;
    }

    return ({
        token: getToken(store),
        validationErrors: getValidationErrorsFromStore(store),
        loggedInEmployee: getLoggedInEmployeeFromStore(store),
        initialActivity,
        addData: store.ui.addActivityData,
        companyId: companyId
    });
}

const mapDispatch = {
    updateInterviewAction,
    addInterviewAction,
    setUiCompanyContactAddDataAction,
    setUiActivityToDeleteAction,
    setRecruitingPageReloadRecruitingAction
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(InterviewForm);

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { ICandidateAddress, ICountry } from '../../../../../definitions/entities.definition';
import { EIcons, IconFactory } from '../../../../../components/Icons/IconFactory';
import CandidateAddressItemView from './components/CandidateAddressItemView';
import CustomEditableValue from '../../../../../components/CustomInput/CustomEditableValue';
import CustomTextField from '../../../../../components/CustomInput/CustomTextField';
import { translate } from '../../../../../translation/translate.utils';
import CountryAutocomplete
    from '../../../../../components/CustomInput/CustomAutocomplete/components/CountryAutocomplete';
import { setCandidatePageAddAddressToFormAction } from '../../../../../redux/candidatePage/candidatePage.actions';
import { ClientApi } from '../../../../../requests/ClientApi';
import { postCandidateAddressRouteConfig } from '../../../../../requests/routes';
import { registerError } from '../../../../../redux/error/error.actions';
import { getSuggestionAction } from '../../../../../redux/entities/entities.actions';

interface IProps {
    candidateId?: number;
    records: ICandidateAddress[];
    suggestionId?: number;
}

const CandidateAddressListView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const candidateId = props.candidateId;
    const addresses = props.records;
    const suggestionId = props.suggestionId;

    const [city, setCity] = useState('');
    const [street, setStreet] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [comment, setComment] = useState('');
    const [country, setCountry] = useState<number|ICountry>(55);

    const onAdd = (address: ICandidateAddress) => {
        if (!candidateId) {
            dispatch(setCandidatePageAddAddressToFormAction(address));
            return Promise.resolve();
        }

        return ClientApi.request(postCandidateAddressRouteConfig, {
            token,
            candidateId,
            id: address.id,
            address: address.address,
            city: address.city,
            zipCode: address.zipCode,
            countryId: address.country ? address.country.id : undefined,
            comment: address.comment,
            default: address.default
        }).then((address: ICandidateAddress) => {
            setCity('');
            setStreet('');
            setZipCode('');
            setComment('');
            setCountry(55);

            if (suggestionId) {
                dispatch(getSuggestionAction(token, suggestionId));
                return;
            }

            dispatch(setCandidatePageAddAddressToFormAction(address));
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht aktualisiert werden'));
        });
    };

    return <>
        <div className={"flexContainerColumn"} style={{overflow: 'auto', maxHeight: 300}}>
            {addresses.map((address, index) => {
                return <CandidateAddressItemView
                    key={address.id}
                    index={index}
                    candidateId={candidateId}
                    suggestionId={suggestionId}
                    addresses={addresses}
                    address={address}
                />
            })}
        </div>
        <CustomEditableValue
            theme={'light'}
            emptyValue={
                <div className={"flexContainerRow alignVerticalCenter"}>
                    {IconFactory.getIconComponent(EIcons.Add)}
                    Adresse hinzufügen
                </div>
            }
            input={<>
                <div className={"flexContainerColumn"}>
                    <div className={suggestionId ? "flexContainerColumn flexWrap flex1 gap5" : "flexContainerRow flexWrap flex1 gap5"}>
                        <div className={"flexContainerRow flex1 gap5"}>
                            <CustomTextField
                                required
                                size={'small'}
                                autoFocus
                                value={zipCode}
                                onChange={(value) => setZipCode(value)}
                                label={translate('misc.zipCode')}
                                width={100}
                            />
                            <CustomTextField
                                required
                                size={'small'}
                                label={translate('misc.city')}
                                value={city}
                                onChange={(value) => setCity(value)}
                            />
                        </div>
                        <CountryAutocomplete
                            value={country}
                            size={'small'}
                            onChange={(country) => setCountry(country)}
                        />
                        <CustomTextField
                            value={street}
                            size={'small'}
                            onChange={(value) => setStreet(value)}
                            label={translate('misc.address')}
                        />
                    </div>
                    <div className={"flexContainerRow flex1 gap10"}>
                        <CustomTextField
                            value={comment}
                            size={'small'}
                            multiline
                            rows={5}
                            onChange={(value) => setComment(value)}
                            label={translate('misc.comment')}
                        />
                    </div>
                </div>
            </>}
            onSave={() => onAdd({
                id: 0,
                country,
                default: addresses.length === 0,
                address: street,
                zipCode,
                city,
                comment
            })}
        />
    </>
}

export default CandidateAddressListView;

import { IRouteConfig } from '../definitions/requests.definitions';

export class ClientApi {
    static request = async (routeConfig: IRouteConfig, parameter?: any) => {
        const url = ClientApi.buildUrl(routeConfig, parameter);

        let init = {
            method: routeConfig.method,
        };

        if (parameter.token) {
            const headers = new Headers({
                Authorization: parameter?.token,
            });
            init = { ...init, headers } as any;
        }

        if (routeConfig.method === "POST" || routeConfig.method === "PUT" || routeConfig.method === "DELETE") {
            if (!routeConfig.url.includes("token")) {
                delete parameter.token;
            }

            init = {
                ...init,
                // @ts-ignore-next-line
                body: JSON.stringify(parameter),
            };
        }

        const result = await fetch(url, init);
        if (result.status !== 200) {
            // todo @Daniel: hier können alle deine verschiedenen Fehlerfälle definiert werden logicException, etc...
            // zb prüfe auf den fehler code, der geschmissen wird, wenn der token ungültig ist und dann autoLogout
            const responseBody = await result.json();

            if (result.status === 401) {
                return Promise.reject('Unautzorized');
            }

            if (result.status === 409) {
                return Promise.reject(responseBody);
            }

            if (responseBody.logicException) {
                return Promise.reject(responseBody.logicException);
            }

            if (responseBody.validationErrors) {
                return Promise.reject('validationErrors: ' + JSON.stringify(responseBody.validationErrors));
            }
        }

        return Promise.resolve(result.json());
    };

    static buildUrl = (routeConfig: IRouteConfig, parameter?: any) => {
        const defaultUrl = routeConfig.url;
        if (!routeConfig.optionalParameter && !routeConfig.parameter) {
            return defaultUrl;
        }

        let url = defaultUrl;
        if (routeConfig.parameter) {
            routeConfig.parameter.forEach((parameterForConfig) => {
                const parameterValue = parameter[parameterForConfig];
                const isValueArray = typeof parameterValue === "object";

                if (parameterValue === undefined || parameterValue === null) {
                    throw Error(
                        `Parameter for route: ${routeConfig.url} not found. Parameter: ${parameterForConfig} was configured, but ClientApi received: ${parameterValue}`
                    );
                }

                if (isValueArray) {
                    url = url.replace(`%${parameterForConfig}%`, JSON.stringify(parameterValue));
                    return;
                }

                url = url.replace(`%${parameterForConfig}%`, parameterValue);
            });
        } else {
            url = url + "?";
        }

        if (routeConfig.optionalParameter) {
            let optionalParameterString = "";

            routeConfig.optionalParameter.forEach((parameterForConfig) => {
                const optionalValue = parameter[parameterForConfig];
                if (optionalValue === undefined || optionalValue === null) {
                    return;
                }

                const isValueArray = optionalValue.constructor.name === "Array";
                if (isValueArray && optionalValue.length === 0) {
                    return;
                }

                const isValueObject = optionalValue.constructor.name === "Object";
                if (isValueArray || isValueObject) {
                    optionalParameterString = optionalParameterString + `&${parameterForConfig}=${JSON.stringify(optionalValue)}`;
                    return;
                }

                if (optionalValue !== "") {
                    optionalParameterString = optionalParameterString + `&${parameterForConfig}=${optionalValue}`;
                }
            });

            if (optionalParameterString) {
                url = url + optionalParameterString;
            }
        }

        return url;
    };
}

import React, { ReactNode } from 'react';
import { IMailParticipant } from '../../../../definitions/entities.definition';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import { COLOR_BLUE, COLOR_GREEN, COLOR_ORANGE } from '../../../../theme/theme';

interface IProps {
    mailAddress: IMailParticipant;
}

const getIcon = (mailAddress: IMailParticipant): ReactNode => {
    if (mailAddress.isInternalMailAddress) {
        return IconFactory.getIconComponent(EIcons.Person);
    }

    switch (mailAddress.entityType) {
        case 'ENTITY_CANDIDATE':
            return IconFactory.getIconComponent(EIcons.Person, {color: COLOR_GREEN});
        case 'ENTITY_SUGGESTION':
            return IconFactory.getIconComponent(EIcons.Person, {color: COLOR_ORANGE});
        case 'ENTITY_EMPLOYEE':
            return IconFactory.getIconComponent(EIcons.Person);
        case 'ENTITY_COMPANY_CONTACT':
            return IconFactory.getIconComponent(EIcons.Person, {color: COLOR_BLUE});
        case 'ENTITY_COMPANY':
            return IconFactory.getIconComponent(EIcons.Apartment, {color: COLOR_BLUE});
    }

    return IconFactory.getIconComponent(EIcons.HelpOutline);
}

const MailParticipantRenderer: React.FC<IProps> = (props) => {
    const mailAddress = props.mailAddress;

    return <>
        <div className={"flexContainerRow alignVerticalCenter gap5"} style={{userSelect: 'text' }}>
            {getIcon(mailAddress)}
            <span>{mailAddress.mailAddress}</span>
        </div>
    </>;
};

export default MailParticipantRenderer;

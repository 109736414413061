import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    EEntities,
    IEmployee,
    IWebVacancy,
    IWebVacancyListModel,
    TBrainzEntity,
} from '../../definitions/entities.definition';
import { getDefaultCompanyColumn, INFINITE_SCROLL_INTERVAL } from '../../utils/components.utils';
import { ECellAlign, ESortingOptions, ICustomTableColumnConfig } from '../../definitions/components.definitions';
import CustomTable from '../../components/CustomTable/CustomTable';
import { IStore } from '../../definitions/redux/store.definitions';
import { translate } from '../../translation/translate.utils';
import { getToken } from '../../selectors/app.selectors';
import { getWebVacancyListAction, updateWebVacancyAction } from '../../redux/entities/entities.actions';
import { getWebVacancyListFromStore, getWebVacancyListRequestObject } from '../../selectors/webVacancy.selectors';
import {
    setWebVacancyPageFilterAction,
    setWebVacancyPageSortingOptionAction,
} from '../../redux/webVacancyPage/webVacancyPage.actions';
import EmployeeCellRenderer from '../../components/CustomCellRenderer/EmployeeCellRenderer';
import DateDiffCellRenderer from '../../components/CustomCellRenderer/DateDiffCellRenderer';
import { EIcons } from '../../components/Icons/IconFactory';
import { COLOR_RED } from '../../theme/theme';
import { setUiEditView } from '../../redux/ui/ui.actions';
import { EEntityView } from '../../definitions/ui.definitions';
import WebVacancyTitleCellRenderer from '../../components/CustomCellRenderer/WebVacancyTitleCellRenderer';
import { getFormattedDateString } from '../../utils/date.utils';

const WebVacancyList: React.FC = () => {
    const dispatch = useDispatch();
    const webVacancies = useSelector(getWebVacancyListFromStore);
    const loading = useSelector((store: IStore) => store.ui.webVacanciesLoading);
    const filter = useSelector((store: IStore) => store.webVacancyPage.filter);
    const totalRecords = useSelector((store: IStore) => store.entities.webVacancy.total);
    const requestObject = useSelector(getWebVacancyListRequestObject);
    const token = useSelector(getToken);

    const columnConfig: ICustomTableColumnConfig = {
        title: {
            header: translate('pages.vacancy.properties.title') + ' / ' + translate('pages.webVacancy.locations'),
            property: "title",
            cellRenderer: (title: string, entity) => {
                const webVacancy = entity as IWebVacancyListModel;
                return <WebVacancyTitleCellRenderer webVacancy={webVacancy} />
            }
        },
        company: getDefaultCompanyColumn(),
        companyRating: {
            header: translate('pages.company.properties.rating'),
            property: "company.rating",
            width: 50,
            align: ECellAlign.center,
            cellRenderer: (value: number) => {
                if (value === 1) {
                    return (<>A</>);
                }
                if (value === 2) {
                    return (<>B</>);
                }
                if (value === 3) {
                    return (<>C</>);
                }

                return (<>{translate('misc.noInformation')}</>);
            }
        },
        responsibleEmployee: {
            header: translate('misc.employeeShort'),
            property: "company.responsibleEmployee",
            width: 60,
            align: ECellAlign.center,
            cellRenderer: (employee: IEmployee) => {
                return <EmployeeCellRenderer employee={employee} />;
            },
        },
        onlineSince: {
            header: <>{translate('pages.webVacancy.onlineSince')}<br/>Erst-Veröffentlichung</>,
            property: "onlineSince",
            width: 160,
            align: ECellAlign.right,
            cellRenderer: (onlineSince: string, entity) => {
                const entityCast = entity as IWebVacancyListModel;
                const dateOfPublication = entityCast.dateOfPublication;
                return <div className={"flexColumn flexAutoGrow"}>
                    <DateDiffCellRenderer date={onlineSince}  beforeText={"seit"} />
                    {getFormattedDateString(onlineSince) !== getFormattedDateString(dateOfPublication) &&
                        <div className={"formContent alignHorizontalRight"}>
                            {getFormattedDateString(dateOfPublication)}
                        </div>
                    }
                </div>
            },
        },
    };

    return (
        <>
            <CustomTable
                config={{
                    color: COLOR_RED,
                    entity: EEntities.WebVacancy,
                    onRowDoubleClick: (employeeId: number, entity: TBrainzEntity) => {
                        const webVacancy = entity as IWebVacancy;
                        if (webVacancy.vacancies.length === 1) {
                            dispatch(setUiEditView(EEntityView.vacancy, webVacancy.vacancies[0].id));
                            return;
                        }
                        dispatch(setUiEditView(EEntityView.webVacancy, webVacancy.id));
                    },
                    columnConfig,
                    sortedEntries: webVacancies,
                    loading: loading,
                    listActionButtons: [{
                        action: (id) => {
                            dispatch(updateWebVacancyAction(token, id, undefined, false));
                        },
                        translationKey: "pages.webVacancy.display",
                        icon: EIcons.PanTool,
                        condition: (entity) => (entity as IWebVacancyListModel).hide
                    }, {
                        action: (id) => {
                            dispatch(updateWebVacancyAction(token, id, undefined, true));
                        },
                        translationKey: "pages.webVacancy.hide",
                        icon: EIcons.PanTool,
                        condition: (entity) => !(entity as IWebVacancyListModel).hide
                    }],
                    scrollCallback: () => {
                        if (filter.limit && filter.limit < totalRecords) {
                            dispatch(setWebVacancyPageFilterAction({
                                start: 0,
                                limit: filter.limit + INFINITE_SCROLL_INTERVAL,
                            }));
                        }
                    },
                    tableHeaderCallbacks: {
                        setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                            dispatch(setWebVacancyPageSortingOptionAction(propertyToSort, sortOption)),
                    },
                    onReload: () => {
                        dispatch(getWebVacancyListAction(token, requestObject));
                    },
                    count: webVacancies.length,
                    total: totalRecords
                }}
            />
        </>
    );
}

export default WebVacancyList;

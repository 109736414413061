import React from 'react';
import RecruitingCellRenderer from '../../../../components/CustomCellRenderer/RecruitingCellRenderer';
import CompanyContactCellRenderer from '../../../../components/CustomCellRenderer/CompanyContactCellRenderer';
import CandidateCellRenderer from '../../../../components/CustomCellRenderer/CandidateCellRenderer';
import CompanyCellRenderer from '../../../../components/CustomCellRenderer/CompanyCellRenderer';
import SuggestionCellRenderer from '../../../../components/CustomCellRenderer/SuggestionCellRenderer';
import VacancyCellRenderer from '../../../../components/CustomCellRenderer/VacancyCellRenderer';
import { IMailRelation } from '../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMailRelationAction } from '../../../../redux/entities/entities.actions';
import { getToken } from '../../../../selectors/app.selectors';

interface IProps {
    relation: IMailRelation
}

const MailElementRelation: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const relation = props.relation;
    const token = useSelector(getToken);

    let onDelete = undefined;

    if (relation.mailProperty !== 'from' && relation.mailProperty !== 'to' && relation.mailProperty !== 'cc') {
        onDelete = () => {
            dispatch(deleteMailRelationAction(token, relation.id));
        };
    }

    if (relation.recruiting) {
        return <RecruitingCellRenderer recruiting={relation.recruiting} showCandidateName onDelete={onDelete}/>
    }
    if (relation.companyContact) {
        return <CompanyContactCellRenderer companyContact={relation.companyContact} onDelete={onDelete}/>
    }
    if (relation.candidate) {
        return <CandidateCellRenderer candidate={relation.candidate} onDelete={onDelete}/>
    }
    if (relation.company) {
        return <CompanyCellRenderer company={relation.company} onDelete={onDelete}/>
    }
    if (relation.vacancy) {
        return <VacancyCellRenderer vacancy={relation.vacancy} onDelete={onDelete}/>
    }
    if (relation.suggestion) {
        return <SuggestionCellRenderer recruitingSuggestion={relation.suggestion} onDelete={onDelete}/>
    }

    return null;
}

export default MailElementRelation;

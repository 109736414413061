import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import StorageController from './components/StorageController/StorageController';
import App from './App';

if (process.env.NODE_ENV === "development") {
    let totalDuration = 0;

    //@ts-ignore
    const onRender = (id, phase, actualDuration, baseDuration, startTime, commitTime) => {
        totalDuration += actualDuration;
        //console.log(totalDuration);
    }

    ReactDOM.render(
        <Provider store={store}>
            <App />
            <StorageController/>
        </Provider>,
        document.getElementById("root")
    );
}

if (process.env.NODE_ENV !== "development") {
    ReactDOM.render(
        <Provider store={store}>
            <App />
            <StorageController/>
        </Provider>,
        document.getElementById("root")
    );
}


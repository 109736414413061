import React from 'react';
import {
    EActivityAction,
    EActivityType,
    EFeedbackType,
    EInterviewType,
    ERecruitingStatus,
    ESuggestionAction,
    IActivity,
} from '../../definitions/entities.definition';
import Nl2brCellRenderer from './Nl2brCellRenderer';
import { translate } from '../../translation/translate.utils';
import { getActivityActionDescription } from '../../utils/activity.utils';

interface IProps {
    activity: IActivity;
}

const ActivityDescriptionCellRenderer: React.FC<IProps> = (props) => {
    if (props.activity.action === EActivityAction.addSuggestionAction) {
        const userInput = props.activity.userInput ? <Nl2brCellRenderer value={translate('pages.activity.properties.userInputWithName', [props.activity.employee.fullName]) + ": " + props.activity.userInput} /> : undefined;

        switch (props.activity.suggestionAction) {
            case ESuggestionAction.ACTION_BOOKED_JOBCENTER:
                return <div className={"flexContainerColumn"}>Aufgebucht{userInput}</div>;
            case ESuggestionAction.ACTION_CALL:
                return <div className={"flexContainerColumn"}>Anruf ausgehend{userInput}</div>;
            case ESuggestionAction.ACTION_CALL_RECEIVED:
                return <div className={"flexContainerColumn"}>Anruf eingehend{userInput}</div>;
            case ESuggestionAction.ACTION_CALL_NOT_REACHED:
                return 'Nicht erreicht';
            case ESuggestionAction.ACTION_NOT_REACHABLE_UNTIL:
                return 'Nicht erreichbarkeit dokumentiert';
            case ESuggestionAction.ACTION_ANSWER_BY_CANDIDATE:
                return <div className={"flexContainerColumn"}>Favorit hat geschrieben{userInput}</div>;
            case ESuggestionAction.ACTION_CALL_COORDINATION:
                return 'Call-Koordinierung';
            case ESuggestionAction.ACTION_CALL_DATE_SET:
                return 'Call-Termin festgesetzt';
            case ESuggestionAction.ACTION_CALL_DATE_CHANGE:
                return 'Call-Termin verschoben';
            case ESuggestionAction.ACTION_LETTER_SEND:
                return props.activity.employee.fullName + ' hat einen Brief an den Favoriten abgeschickt.';
            case ESuggestionAction.ACTION_MESSAGE_MAIL:
                return <div className={"flexContainerColumn"}>Nachricht gesendet (Mail){userInput}</div>;
            case ESuggestionAction.ACTION_MESSAGE_MOBILE:
                return <div className={"flexContainerColumn"}>Nachricht gesendet (Mobil){userInput}</div>;
            case ESuggestionAction.ACTION_MESSAGE_PORTAL:
                return <div className={"flexContainerColumn"}>Nachricht gesendet (Portal){userInput}</div>;
            case ESuggestionAction.ACTION_MESSAGE_RECEIVED_MAIL:
                return <div className={"flexContainerColumn"}>Nachricht empfangen (Mail){userInput}</div>;
            case ESuggestionAction.ACTION_MESSAGE_RECEIVED_MOBILE:
                return <div className={"flexContainerColumn"}>Nachricht empfangen (Mobil){userInput}</div>;
            case ESuggestionAction.ACTION_MESSAGE_RECEIVED_PORTAL:
                return <div className={"flexContainerColumn"}>Nachricht empfangen (Portal){userInput}</div>;
        }
    }

    if (props.activity.action === EActivityAction.mailboxMail) {
        return 'Betreff: ' + props.activity.mail.subject;
    }

    if (props.activity.type === EActivityType.phoneNotReached) {
        return 'Telefonisch - nicht erreicht';
    }

    if (props.activity.type === EActivityType.feedback) {
        switch (props.activity.feedbackType) {
            case EFeedbackType.default:
                return <b>Regelmäßiges Feedback</b>;
            case EFeedbackType.trial_end:
                return <b>Feedback zum Ende der Probezeit</b>;
            case EFeedbackType.start:
                return <b>Feedback zum Start</b>;
        }
    }

    if (props.activity.type === EActivityType.interview || props.activity.type === EActivityType.candidateInterview) {
        let appendInformation = '' ;
        if (props.activity.type === EActivityType.interview &&
            props.activity.recruiting?.status === ERecruitingStatus.STATUS_INTERVIEW &&
            !props.activity.feedbackCompany && !props.activity.feedbackCandidate &&
            props.activity.interviewCancelled !== true
        ) {
            appendInformation = '(Feedback fehlt)';
        }
        if (props.activity.type === EActivityType.candidateInterview &&
            !props.activity.feedbackCandidate &&
            props.activity.interviewCancelled !== true
        ) {
            appendInformation = '(Feedback fehlt)';
        }

        if (props.activity.type === EActivityType.interview && props.activity.interviewCancelled) {
            appendInformation = '(Termin ist ausgefallen)';
        }

        const feedbackInformation = (
            <>
                {props.activity.feedbackCompany &&
                <Nl2brCellRenderer value={translate('pages.recruiting.feedbackCompany') + ": " + props.activity.feedbackCompany} />
                }
                {props.activity.feedbackCandidate &&
                <Nl2brCellRenderer value={translate('pages.recruiting.feedbackCandidate') + ": " + props.activity.feedbackCandidate} />
                }
            </>
        )
        switch (props.activity.interviewType) {
            case EInterviewType.online:
                return (
                    <>
                        <b>Interview - Online {appendInformation}</b>
                        {feedbackInformation}
                    </>
                );
            case EInterviewType.trial:
                return (
                    <>
                        <b>Probe-Arbeitstag {appendInformation}</b>
                        {feedbackInformation}
                    </>
                );
            case EInterviewType.personal:
                return (
                    <>
                        <b>Persönliches Vorstellungsgespräch {appendInformation}</b>
                        {feedbackInformation}
                    </>
                );
            case EInterviewType.phone:
                return (
                    <>
                        <b>Interview - Telefon {appendInformation}</b>
                        {feedbackInformation}
                    </>
                );
        }
    }

    if (props.activity.type === EActivityType.systemInfo) {
        return (
            <div className={"flexContainerColumn"}>
                <Nl2brCellRenderer value={getActivityActionDescription(props.activity)} />
                {props.activity.userInput && <Nl2brCellRenderer value={translate('pages.activity.properties.userInputWithName', [props.activity.employee.fullName]) + ": " + props.activity.userInput} />}
            </div>
        );
    }

    if (props.activity.type === EActivityType.systemMail) {
        switch (props.activity.action) {
            case EActivityAction.sendApplicationReceivedMail:
                return (<>
                    Bestätigungsmail Bewerbungseingang
                </>);
            case EActivityAction.sendApplicationDeclinedMail:
                return (<>
                    Absagemail auf Bewerbung
                </>);
            case EActivityAction.sendContractCongratulationMail:
                return (<>
                    Glückwunsch E-Mail nach erfolgreicher Vermittlung
                </>);
            case EActivityAction.sendBirthdayMail:
                return (<>
                    Geburtstags E-Mail
                </>);
        }
    }

    if (props.activity.userInput) {
        return <Nl2brCellRenderer value={props.activity.userInput} />;
    }

    return null;
}

export default ActivityDescriptionCellRenderer;

import {
    ISetArchivePageFastSearchAction,
    ISetArchivePageFilterAction,
    ISetArchivePageSortingOptionAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import { IArchivePageState, preloadedStateArchivePage } from '../../definitions/archivePage.definitions';
import {
    ARCHIVE_PAGE_SET_FAST_SEARCH,
    ARCHIVE_PAGE_SET_FILTER,
    ARCHIVE_PAGE_SET_SORTING_OPTION,
} from './archivePage.actions';

const archivePageReducer = (state = preloadedStateArchivePage, action: TReducerAction): IArchivePageState => {
    switch (action.type) {
        case ARCHIVE_PAGE_SET_SORTING_OPTION: {
            const actionCast = action as ISetArchivePageSortingOptionAction;
            return {
                ...state,
                sortingVacancyList: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case ARCHIVE_PAGE_SET_FAST_SEARCH: {
            const actionCast = action as ISetArchivePageFastSearchAction;

            return {
                ...state,
                filterVacancyList: {
                    ...state.filterVacancyList,
                    fastSearch: actionCast.payload
                },
            };
        }
        case ARCHIVE_PAGE_SET_FILTER: {
            const actionCast = action as ISetArchivePageFilterAction;

            return {
                ...state,
                filterVacancyList: {
                    ...state.filterVacancyList,
                    ...actionCast.payload
                },
            };
        }
    }

    return state;
};

export default archivePageReducer;

import React from 'react';
import { getRecruitingsForCompanyContactAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { getCompanyPageRequestObjectForRecruitingsInContactEditView } from '../../../../../selectors/recruiting.selectors';
import RecruitingList from '../../../../Shared/RecruitingList/RecruitingList';
import { EEntityView } from '../../../../../definitions/ui.definitions';
import { useCompanyContactRecruitingListHook } from './CompanyContactRecruitingList.hooks';

interface IProps {
    companyContactId: number;
}

const CompanyContactRecruitingList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const recruitingRequestObject = useSelector(getCompanyPageRequestObjectForRecruitingsInContactEditView);
    const companyContactId = props.companyContactId;

    const { loading, recruitings, total} = useCompanyContactRecruitingListHook(companyContactId);

    return <>
        <RecruitingList
            recruitings={recruitings}
            total={total}
            loading={loading}
            parentView={EEntityView.company}
            onReload={() => {
                dispatch(getRecruitingsForCompanyContactAction(token, companyContactId, recruitingRequestObject));
            }}
        />
    </>
}

export default CompanyContactRecruitingList;

import React, { memo, ReactNode, useState } from 'react';
import { EEntities, TBrainzEntity } from '../../definitions/entities.definition';
import CustomTableRow from './CustomTableRow';
import {
    ICustomTableColumnConfig,
    IListActionButtonConfig,
    TListAction,
} from '../../definitions/components.definitions';
import { createCustomTableCellsHook } from '../../utils/components.utils';
import { EIcons } from '../Icons/IconFactory';
import CustomClickableIcon from '../CustomInput/CustomClickableIcon';

interface IProps {
    listActionButtons?: IListActionButtonConfig[];
    listActionHeading?: (entity: TBrainzEntity) => string;
    columnConfig: ICustomTableColumnConfig;
    sortedEntries: TBrainzEntity[];
    onDoubleClick?: TListAction;
    onClick?: TListAction;
    entity: EEntities;
    header: string;
    denyVerticalCenter?: boolean;
    groupHeaderExtraInformation?: (entities: TBrainzEntity[]) => ReactNode;
}

const CustomTableBodyGroupedRenderGroup: React.FC<IProps> = (props) => {
    const [expanded, setExpanded] = useState(true);
    const icon = expanded ? EIcons.ExpandLess : EIcons.ExpandMore;
    const toggleExpand = () => setExpanded(!expanded);

    return <>
        <div
            className={"flexContainerColumn"}
            style={{backgroundColor: 'var(--brainz-darkest)', marginRight: 5}}
        >
            <div className={"flexContainerRow"} style={{cursor: 'pointer'}} onClick={toggleExpand}>
                <CustomClickableIcon onClick={toggleExpand} icon={icon}/>
                <h4 style={{margin: 5, flex: 1}}>
                    {props.header} ({props.sortedEntries.length})
                </h4>
                {props.groupHeaderExtraInformation &&
                    <h4 style={{margin: 5}}>{props.groupHeaderExtraInformation(props.sortedEntries)}</h4>
                }
            </div>
            {expanded &&
            <div className={"flexContainerColumn padding5"}>
                {props.sortedEntries.map((entry: TBrainzEntity, index) => {
                    const cells = createCustomTableCellsHook(
                        props.denyVerticalCenter || false,
                        props.entity,
                        props.columnConfig,
                        entry
                    );

                    return (
                        <CustomTableRow
                            index={index}
                            entity={props.entity}
                            onDoubleClick={props.onDoubleClick}
                            onClick={props.onClick}
                            entry={entry}
                            key={index}
                            coloring={false}
                            listActionButtons={props.listActionButtons}
                            listActionHeading={props.listActionHeading}
                        >
                            {cells}
                        </CustomTableRow>
                    );
                })}
            </div>
            }

            <div className={"flexContainerColumn"} style={{backgroundColor: 'var(--brainz-darker)', height: 5, fontSize: 1}}>&nbsp;</div>
        </div>
    </>;
}

export default memo(CustomTableBodyGroupedRenderGroup);

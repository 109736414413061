import React, { ReactNode, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import { TBrainzEntity } from '../../../definitions/entities.definition';
import { IRouteConfig } from '../../../definitions/requests.definitions';
import { useLivesearchHooks } from './CustomLivesearch.hooks';
import { getOptionLabel } from './Autocomplete.utils';

interface IProps {
    id: string;

    value: null|TBrainzEntity|number;
    onChange: (value: null|TBrainzEntity) => void;
    getOptionLabel: (option: TBrainzEntity) => string;

    routeConfig: IRouteConfig;
    routeParams: object;

    loadAll?: boolean;
    entityRouteConfig: IRouteConfig;
    entityRouteParam?: string; //Default is id

    //autocomplete params
    label: string;
    disabled?: boolean;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: ReactNode;
    required?: boolean;
    startAdornment?: React.ReactNode;
    size?: 'small'|'medium';
    clearOnSelect?: boolean;
}

const CustomLivesearch: React.FC<IProps> = (props) => {
    const [searchValue, setSearchValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const value = props.value || null;
    const loadAll = props.loadAll || false;
    const routeConfig = props.routeConfig;
    const routeParams = props.routeParams;
    const entityRouteConfig = props.entityRouteConfig;
    const entityRouteParam = props.entityRouteParam || 'id';
    const label = props.label;
    const startAdornment = props.startAdornment;
    const clearOnSelect = props.clearOnSelect || false;

    const valueIsEntity: boolean = value !== null && value.constructor && value.constructor.name === 'Object';
    const [entityValue, setEntityValue] = useState<TBrainzEntity|null>(valueIsEntity ? value as null|TBrainzEntity : null);

    const getOptionLabelInner = (record: TBrainzEntity) => getOptionLabel(record, props.getOptionLabel);

    const { calculatedEntityValue, records, loading } = useLivesearchHooks(
        open,
        loadAll,
        value,
        searchValue,
        routeParams,
        routeConfig,
        entityRouteConfig,
        entityRouteParam,
        getOptionLabelInner
    );

    useEffect(() => {
        if (!valueIsEntity) {
            setEntityValue(calculatedEntityValue);
            if (calculatedEntityValue) {
                props.onChange(calculatedEntityValue);
            }
        }
    }, [calculatedEntityValue]);

    return <>
        <Autocomplete
            id={props.id}
            className={"flex"}
            loading={loading}
            disabled={props.disabled}
            noOptionsText={"Geben sie einen Suchbegriff ein"}
            filterOptions={(options) => [...options]}
            inputValue={searchValue}
            onInputChange={(event, value) => setSearchValue(value)}
            disableClearable={props.required}

            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}

            options={records.length > 0 ? records : (entityValue !== null) ? [entityValue] : []}
            getOptionSelected={(value, option) => value.id === option.id }
            //@ts-ignore
            value={entityValue || value}

            getOptionLabel={getOptionLabelInner}
            onChange={(event, value) => {
                props.onChange(value);

                if (clearOnSelect) {
                    setSearchValue('');
                    setEntityValue(null);
                    return;
                }

                setEntityValue(value);
            }}

            renderInput={(params) => <>
                <TextField
                    {...params}
                    onMouseDown={() => {
                        if (open) {
                            setOpen(false);
                        }
                    }}
                    size={props.size}
                    autoComplete={"random"} //workaround to really disable autocomplete.
                    variant={"outlined"}
                    autoFocus={props.autoFocus}
                    error={props.error}
                    helperText={props.helperText}
                    required={props.required}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: params.InputProps.startAdornment ? params.InputProps.startAdornment : startAdornment,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            </>
            }
        />
    </>
}

export default React.memo(CustomLivesearch);

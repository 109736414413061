import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomBackdropLoadingInsideDiv from '../../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import ChatPanel from '../../../Shared/Chat/ChatPanel';
import { getActivityChatListForCompanyContactAction } from '../../../../redux/entities/entities.actions';
import { getCompanyPageContactActivitiesRequestObject } from '../../../../selectors/activity.selectors';
import { getToken } from '../../../../selectors/app.selectors';
import FastSearchPanelActivityList from '../../../App/TopBar/FastSearchPanel/FastSearchPanelActivityList';
import { setUiActivityAddDataAction } from '../../../../redux/ui/ui.actions';
import { EActivityModule } from '../../../../definitions/entities.definition';
import { setCompanyPageContactActivityFilterAction } from '../../../../redux/companyPage/companyPage.actions';

interface IProps {
    companyContactId: number;
}

const CompanyContactActivityChatPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const companyContactId = props.companyContactId;

    const filter = useSelector((store: IStore) => store.companyPage.contactActivityFilter);
    const sorting = useSelector((store: IStore) => store.companyPage.contactActivitySorting);
    const loading = useSelector((store: IStore) => store.ui.activitiesLoading);
    const chat = useSelector((store: IStore) => store.entities.activityChat.byCompanyContact[companyContactId]);
    const requestObject = useSelector(getCompanyPageContactActivitiesRequestObject);
    const [initialLoaded, setInitialLoaded] = useState(false);
    const total = useSelector((store: IStore) => store.entities.activityChat.byCompanyContact[companyContactId]?.total);
    const reload = useSelector((store: IStore) => store.ui.reloadActivityGrids);

    const loadChat = () => {
        if (loading) {
            return;
        }
        dispatch(getActivityChatListForCompanyContactAction(token, companyContactId, requestObject));
        setInitialLoaded(true);
    }

    useEffect(() => {
        if (reload && !loading) {
            dispatch(getActivityChatListForCompanyContactAction(token, companyContactId, requestObject));
        }
    }, [reload]);

    useEffect(() => {
        if (!initialLoaded) {
            return;
        }
        loadChat();
    }, [JSON.stringify(filter), JSON.stringify(sorting)]);

    useEffect(() => {
        loadChat();
    }, []);

    if (!chat) {
        return <><CustomBackdropLoadingInsideDiv open={true}/></>
    }

    return <>
        <CustomBackdropLoadingInsideDiv open={loading}/>
        <div className={"padding5"}>
            <FastSearchPanelActivityList
                fastSearch={''}
                setFastSearch={(fastSearch) => dispatch(setCompanyPageContactActivityFilterAction({fastSearch}))}
                addAction={() => dispatch(setUiActivityAddDataAction({
                    add: true,
                    module: EActivityModule.companyContact,
                    contactId: companyContactId
                }))}
            />
        </div>
        <ChatPanel
            chat={chat}
            total={total}
            onReload={() => dispatch(getActivityChatListForCompanyContactAction(token, companyContactId, requestObject))}
        />
    </>;
};

export default CompanyContactActivityChatPanel;

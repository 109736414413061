import { IStore } from '../definitions/redux/store.definitions';
import { IProcessAggregation, IProcessGroup } from '../definitions/entities.definition';
import _ from 'underscore';

export const groupProcessesByStatus = (processes: IProcessAggregation[]): IProcessGroup => {
    return _.groupBy(processes, function (recruiting) {
        return recruiting.status;
    });
}

export const getProcessesForCandidate = (store: IStore, candidateId: number) => {
    return store.entities.processAggregation.byCandidate[candidateId] || [];
};

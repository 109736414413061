import React, { useState } from 'react';
import {
    ESuggestionProgressStatus,
    ESuggestionStatus,
    ISuggestion,
} from '../../../definitions/entities.definition';
import { EIcons } from '../../../components/Icons/IconFactory';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { translate } from '../../../translation/translate.utils';
import RenderSuggestionListItem from './RenderSuggestionListItem';
import CustomDragDropContext from '../../../components/CustomDragAndDrop/CustomDragDropContext';
import { DropResult } from 'react-beautiful-dnd';
import { EEntityView } from '../../../definitions/ui.definitions';
import ProgressStatusCellRenderer from '../../../components/CustomCellRenderer/ProgressStatusCellRenderer';

interface IProps {
    groupIdentifier: string;
    suggestions: ISuggestion[];
    parentView: EEntityView;
    onDragEnd?: (result: DropResult) => void;
    hideGroupHeader?: boolean;
    groupBy?: 'status'|'progressStatus';
}

const RenderSuggestionListGroup: React.FC<IProps> = (props) => {
    const groupIdentifier = props.groupIdentifier;
    const suggestions = props.suggestions;
    const parentView = props.parentView;
    const [expanded, setExpanded] = useState(true);
    const icon = expanded ? EIcons.ExpandLess : EIcons.ExpandMore;
    const toggleExpand = () => setExpanded(!expanded);
    const hideGroupHeader = props.hideGroupHeader || false;
    const groupBy = props.groupBy || 'status';

    const collapseExpandPanel = <>
        <div
            className={"flexContainerRow"}
            onClick={toggleExpand}
            style={{cursor: 'pointer', backgroundColor: 'var(--brainz-darkest)'}}
        >
            <h4 style={{margin: 5, flex: 1}}>
                {groupBy === 'status' &&
                    <>{translate('pages.recruiting.suggestionStatusValues.' + groupIdentifier)} ({suggestions.length})</>
                }
                {groupBy === 'progressStatus' &&
                    <div className={"flexContainerRow alignVerticalCenter"}>
                        <ProgressStatusCellRenderer status={parseInt(groupIdentifier) as ESuggestionProgressStatus}/>
                        <div>&nbsp;({suggestions.length})</div>
                    </div>
                }
            </h4>
            <CustomClickableIcon onClick={toggleExpand} icon={icon}/>
        </div>
    </>;

    if (!expanded) {
        return <>{collapseExpandPanel}</>;
    }

    if (groupIdentifier === ESuggestionStatus.OPEN && parentView === EEntityView.vacancy && props.onDragEnd) {
        const rankingOptions = suggestions.map((suggestion): {id: number, rating: number, ranking: number, fullName: string} => {
            return {
                id: suggestion.id,
                rating: suggestion.rating,
                ranking: suggestion.ranking,
                fullName: suggestion.fullName
            }
        });

        return <>
            {!hideGroupHeader && collapseExpandPanel}
            <CustomDragDropContext
                onDragEnd={props.onDragEnd}
                items={suggestions}
                renderItem={(suggestion) =>
                    <RenderSuggestionListItem
                        suggestion={suggestion as ISuggestion}
                        parentView={EEntityView.vacancy}
                        rankingOptions={suggestions.length > 2 ? rankingOptions : undefined}
                        displayStatusInformation={groupBy !== 'status'}
                    />
                }
                rootClassName={"overflow flex1 marginTop10 flexContainerColumn gap5"}
            />
        </>
    }

    return <>
        {!hideGroupHeader && collapseExpandPanel}
        {suggestions.map((suggestion) =>
            <RenderSuggestionListItem
                key={suggestion.id}
                parentView={parentView}
                suggestion={suggestion}
                displayStatusInformation={groupBy !== 'status' || parentView === EEntityView.candidate}
            />
        )}
    </>
}

export default RenderSuggestionListGroup;

import React, { useState } from 'react';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import { useSelector } from 'react-redux';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { getToken } from '../../selectors/app.selectors';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';
import { translate } from '../../translation/translate.utils';
import PaperRow from '../../components/CustomPaper/PaperRow';
import RecruitingDeclineReasonDropDown
    from '../../components/CustomInput/CustomDropDownInput/RecruitingDeclineReasonDropDown';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import { ERecruitingAbortedBy, ERecruitingDeclineReason } from '../../definitions/entities.definition';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import { ClientApi } from '../../requests/ClientApi';
import { putDeclineRecruitingRouteConfig } from '../../requests/routes.customer';
import CustomBackdropLoadingInsideDiv from '../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';

interface IProps {
    recruitingId: number;
    open: boolean;
    onClose: () => void;
    callback: () => void;
}

const DeclineRecruitingView: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const open = props.open;
    const recruitingId = props.recruitingId;

    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [declineReason, setDeclineReason] = useState<ERecruitingDeclineReason|null>();
    const [validationErrors, setValidationErrors] = useState<{[field: string]: string}>({});

    const onClose = () => {
        props.onClose();
    };

    const save = () => {
        setLoading(true);
        ClientApi.request(putDeclineRecruitingRouteConfig, {
            token,
            recruitingId,
            comment,
            declineReason,
        }).then((result) => {
            setLoading(false);
            props.callback();
            onClose();
        }).catch((result) => {
            if (result && result.includes("validationErrors: ")) {
                setValidationErrors(JSON.parse(result.replace("validationErrors: ", "")));
            }
            setLoading(false);
        });
    }

    if (!open) {
        return null;
    }

    return <>
        <CustomDialog maxWidth={"sm"} open={open} onClose={onClose}>
            <CustomBackdropLoadingInsideDiv open={loading} />
            <CustomDialogHeader string={"Kandidat ablehnen/absagen"} onClose={onClose} />
            <CustomDialogBody>
                <PaperRow>
                    <RecruitingDeclineReasonDropDown
                        onChange={(reason) => setDeclineReason(reason)}
                        abortedBy={ERecruitingAbortedBy.COMPANY}
                        value={declineReason}
                        error={Boolean(validationErrors.declineReason)}
                        helperText={validationErrors.declineReason}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        multiline
                        required
                        label={translate('pages.recruiting.abortComment')}
                        value={comment}
                        onChange={(value) => setComment(value)}
                        rows={7}
                        error={Boolean(validationErrors.comment)}
                        helperText={validationErrors.comment}
                    />
                </PaperRow>
            </CustomDialogBody>
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    void: () => save(),
                    label: translate('misc.buttons.save')
                }]}
            />
        </CustomDialog>
    </>;
};

export default DeclineRecruitingView;

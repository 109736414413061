import { Dialog, makeStyles } from '@material-ui/core';
import React from 'react';

interface IProps {
    open: boolean;
    onClose: VoidFunction;
    fullWidth?: boolean;
    fullScreen?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const useStyles = makeStyles(() => ({
    paperFullWidth: {
        flex: "1 1 auto",
        overflow: "hidden"
    },
    paper: {
        width: '100%'
    },
    container: {
        flexDirection: "column"
    }
}));

const CustomDialog: React.FC<IProps> = (props) => {
    const styles = useStyles();

    return <>
        <Dialog
            fullScreen={props.fullScreen}
            fullWidth={props.fullWidth}
            maxWidth={props.maxWidth || "xl"}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                    return;
                }
                props.onClose();
            }}
            open={props.open}
            disableEnforceFocus
            classes={{
                paper: props.fullWidth ? styles.paperFullWidth : styles.paper,
                container: styles.container
            }}
        >
            {props.children}
        </Dialog>
    </>;
};

export default CustomDialog;

import React, { useEffect, useState } from 'react';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../../components/CustomDialog/CustomDialogBody';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import { translate } from '../../../translation/translate.utils';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import {
    setApplicationPageDeclineAction,
    setApplicationPageEditAction,
    setApplicationPageReload,
} from '../../../redux/applicationPage/applicationPage.actions';
import { Dialog } from '@material-ui/core';
import { getToken } from '../../../selectors/app.selectors';
import { ClientApi } from '../../../requests/ClientApi';
import {
    getDeclineApplicationMailTemplateRouteConfig,
    putDeclineApplicationRouteConfig,
} from '../../../requests/routes';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import { registerError, registerSuccess } from '../../../redux/error/error.actions';
import moment from 'moment';
import CustomDateTimePicker from '../../../components/CustomInput/CustomDateTimePicker';
import CustomCheckboxInput from '../../../components/CustomInput/CustomCheckboxInput';
import DeclineApplicationMailVariantDropDown
    from '../../../components/CustomInput/CustomDropDownInput/DeclineApplicationMailVariantDropDown';

const DeclineApplicationView: React.FC = () => {
    const dispatch = useDispatch();
    const open = useSelector((store: IStore) => Boolean(store.applicationPage.applicationToDecline));
    const id = useSelector((store: IStore) => store.applicationPage.applicationToDecline);
    const token = useSelector(getToken);
    const [text, setText] = useState('');
    const [template, setTemplate] = useState('');

    const date = moment().add(1, 'day');
    date.set({hour:9,minute:0,second:0,millisecond:0})
    const [sendDeclineMailAt, setSendDeclineMailAt] = useState(date.format("Y-MM-DD H:mm:ss"));
    const [sendDeclineMail, setSendDeclineMail] = useState(true);
    const [variant, setVariant] = useState(0);
    const [useEnglish, setUseEnglish] = useState(false);
    const [useFormalTerm, setUseFormalTerm] = useState(false);
    const [initialLoaded, setInitialLoaded] = useState(false);

    const loadMailTemplates = () => {
        ClientApi.request(getDeclineApplicationMailTemplateRouteConfig, initialLoaded ? {
            token,
            applicationId: id,
            variant,
            useFormalTerm,
            languageId: useEnglish ? 38 : 33
        } : {
            token,
            applicationId: id
        }).then((result: {
            template: string,
            text: string,
            languageId: number,
            useFormalTerm: boolean,
            variant: number
        }) => {
            setText(result.text);
            setTemplate(result.template)

            if (!initialLoaded) {
                setVariant(result.variant);
                setUseEnglish(result.languageId === 38);
                setUseFormalTerm(result.useFormalTerm);
            }
            setInitialLoaded(true);
        }).catch((error) => {
            if (error !== 'Candidate has no E-Mail Address') {
                dispatch(registerError(error));
                return;
            }

            dispatch(registerError('Kandidat hat keine E-Mail Adresse hinterlegt'));
            onClose();
            return;
        });
    }

    useEffect(() => {
        if (!open) {
            return;
        }
        loadMailTemplates();
    }, [dispatch, id]);

    useEffect(() => {
        if (!open || !initialLoaded) {
            return;
        }
        loadMailTemplates();
    }, [variant, useFormalTerm, useEnglish]);

    const onSave = () => {
        ClientApi.request(putDeclineApplicationRouteConfig, {
            token,
            applicationId: id,
            mailText: sendDeclineMail ? text : null,
            sendDeclineMailAt: sendDeclineMail ? sendDeclineMailAt : null,
            sendDeclineMail
        }).then((result) => {
            dispatch(setApplicationPageEditAction(0));
            dispatch(setApplicationPageReload());
            dispatch(registerSuccess());
            onClose();
        });
    }

    const onClose = () => {
        dispatch(setApplicationPageDeclineAction(0));
        setUseEnglish(false);
        setInitialLoaded(false);
        setUseFormalTerm(false);
        setVariant(0);
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
            <CustomDialogHeader
                translationString={"pages.application.decline"}
                onClose={() => onClose()}
            />
            <CustomDialogBody>
                <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                    <PaperRow>
                        <CustomCheckboxInput
                            label={"Absage per E-Mail verschicken"}
                            checked={sendDeclineMail}
                            onChange={() => setSendDeclineMail(!sendDeclineMail)}
                        />
                        { sendDeclineMail &&
                            <CustomDateTimePicker
                                label={'E-Mail versenden am/um'}
                                value={new Date(sendDeclineMailAt)}
                                onChange={(value) =>
                                    setSendDeclineMailAt(moment(value).format("Y-MM-DD H:mm:ss"))
                                }
                            />
                        }
                    </PaperRow>
                    { sendDeclineMail &&
                        <>
                            <PaperRow>
                                <CustomCheckboxInput
                                    label={"Formele Ansprache (Sie) nutzen"}
                                    checked={useFormalTerm}
                                    onChange={() => setUseFormalTerm(!useFormalTerm)}
                                />
                                <CustomCheckboxInput
                                    label={"Englisches Anschreiben nutzen"}
                                    checked={useEnglish}
                                    onChange={() => setUseEnglish(!useEnglish)}
                                />
                                <DeclineApplicationMailVariantDropDown
                                    onChange={(event) => setVariant(event.target.value)}
                                    value={variant}
                                />
                            </PaperRow>
                            <PaperRow>
                                <CustomTextField
                                    multiline
                                    rows={15}
                                    value={text}
                                    onChange={(value) => setText(value)}
                                />
                            </PaperRow>
                            <div className="mailContent" dangerouslySetInnerHTML={{__html: template.replace('{template}', text)}}></div>
                        </>
                    }
                    </div>
            </CustomDialogBody>
            <CustomDialogActions
                onClose={() => onClose()}
                buttons={[{
                    void: () => {
                        onSave()
                    },
                    label: translate('misc.buttons.save')
                }]}
            />
        </Dialog>
    );
}

export default DeclineApplicationView;

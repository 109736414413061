export type TMailboxChannel = 'debug'|'new';

export interface IMailingFilter {
    fastSearch?: string;
    folderId?: number;
    channel?: TMailboxChannel;
}

export const mailingFilterInitialState: IMailingFilter = {
    fastSearch: ''
}

export interface IMailingState {
    filter: IMailingFilter;
    selectedMailboxId?: number;
    paging: {
        start: number;
        limit: number;
    },
    loading: boolean,
    showMessageOfMailId: number
}

export const preloadedMailingState: IMailingState = {
    filter: mailingFilterInitialState,
    paging: {
        start: 0,
        limit: 20
    },
    loading: false,
    showMessageOfMailId: 0
};

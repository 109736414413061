import React, { useState } from 'react';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { addActivityAction, updateActivityAction } from '../../../redux/entities/entities.actions';
import { getToken, getValidationErrorsFromStore } from '../../../selectors/app.selectors';
import {
    EActivityType,
    ERecruitingAbortedBy,
    ERecruitingDeclineReason,
    ERecruitingStatus,
    IActivityForApi,
} from '../../../definitions/entities.definition';
import LegacyEmployeeAutocompleteDropDown
    from '../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import { translate } from '../../../translation/translate.utils';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import ActivityTypeDropDown from '../../../components/CustomInput/CustomDropDownInput/ActivityTypeDropDown';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomDateTimePicker from '../../../components/CustomInput/CustomDateTimePicker';
import moment from 'moment';
import CompanyContactAutocompleteDropDown
    from '../../../components/CustomInput/CustomAutocomplete/Legacy/CompanyContactAutocompleteDropDown';
import { setUiActivityToDeleteAction, setUiCompanyContactAddDataAction } from '../../../redux/ui/ui.actions';
import { getActivityActionDescription } from '../../../utils/activity.utils';
import { createPortal } from 'react-dom';
import CustomCheckboxInput from '../../../components/CustomInput/CustomCheckboxInput';
import CustomDatePicker from '../../../components/CustomInput/CustomDatePicker';
import RecruitingAbortedByDropDown
    from '../../../components/CustomInput/CustomDropDownInput/RecruitingAbortedByDropDown';
import RecruitingDeclineReasonDropDown
    from '../../../components/CustomInput/CustomDropDownInput/RecruitingDeclineReasonDropDown';
import { setRecruitingPageReloadRecruitingAction } from '../../../redux/recruitingPage/recruitingPage.actions';

interface IProps {
    onClose: () => void;
}

const ActivityForm: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const validationErrors = useSelector(getValidationErrorsFromStore);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const addActivityData = useSelector((store: IStore) => store.ui.addActivityData);

    const initialActivity = useSelector((store: IStore) => store.entities.activities.byId[store.ui.editActivityId]);
    let companyId = 0;
    if (addActivityData.add && addActivityData.companyId) {
        companyId = addActivityData.companyId;
    }
    if (!addActivityData.add && initialActivity && initialActivity.company) {
        companyId = initialActivity.company.id;
    }
    const displayContactDropdown = Boolean(companyId);

    const [userInput, setUserInput] = useState(initialActivity?.userInput || '');
    const [type, setType] = useState(initialActivity?.type || EActivityType.phone);
    const [employee, setEmployee] = useState(initialActivity?.employee || loggedInEmployee);
    const [companyContact, setCompanyContact] = useState(initialActivity?.companyContact || undefined);
    const [dateTime, setDateTime] = useState<Date|undefined>(initialActivity ? new Date(initialActivity?.dateTime) : new Date());
    const actionString = initialActivity ? getActivityActionDescription(initialActivity) : '';
    const [recruitingAbort, setRecruitingAbort] = useState(initialActivity?.recruitingAbort || false);
    const [dateOfAbort, setDateOfAbort] = useState<Date>((initialActivity?.recruiting?.dateOfAbort) ? new Date(initialActivity?.recruiting?.dateOfAbort) : new Date());
    const [abortedBy, setAbortedBy] = useState<ERecruitingAbortedBy|undefined>(initialActivity?.recruiting?.abortedBy);
    const [declineReason, setDeclineReason] = useState<ERecruitingDeclineReason|undefined>(initialActivity?.recruiting?.declineReason);
    const [candidateFollowupDate, setCandidateFollowupDate] = useState<Date|undefined>((initialActivity?.candidate?.followupDate) ? new Date(initialActivity.candidate.followupDate) : undefined);
    const [abortComment, setAbortComment] = useState(initialActivity?.recruiting?.abortComment);

    const save = () => {
        const activity: IActivityForApi = {
            userInput,
            type,
            employeeId: employee?.id || 0,
            dateTime: moment(dateTime).format("DD.MM.Y H:mm:ss"),
            companyContactId: companyContact?.id,
            dateOfAbort: dateOfAbort ? moment(dateOfAbort).format("DD.MM.Y H:mm:ss") : undefined,
            abortedBy: abortedBy,
            abortComment: abortComment,
            declineReason: declineReason,
            candidateFollowupDate: candidateFollowupDate ? moment(candidateFollowupDate).format("DD.MM.Y H:mm:ss") : undefined,
            recruitingAbort: recruitingAbort,
        };

        if (addActivityData.add) {
            activity.module = addActivityData.module;
            activity.candidateId = addActivityData.candidateId;
            activity.companyId = addActivityData.companyId;
            activity.vacancyId = addActivityData.vacancyId;
            activity.recruitingId = addActivityData.recruitingId;
            activity.recruitingSuggestionId = addActivityData.suggestionId;
            activity.companyContactId = addActivityData.contactId;
            activity.todoId = addActivityData.todoId;
            activity.applicationId = addActivityData.applicationId;

            dispatch(addActivityAction(token, activity, () => {
                if (recruitingAbort) {
                    dispatch(setRecruitingPageReloadRecruitingAction());
                }
            }));
            return;
        }

        activity.id = initialActivity.id;
        dispatch(updateActivityAction(token, activity, () => {
            if (recruitingAbort) {
                dispatch(setRecruitingPageReloadRecruitingAction());
            }
        }));
        return;
    }


    const getAbortForm = () => {
        const displayAbortRecruiting = initialActivity?.recruiting || addActivityData.recruitingId || addActivityData.recruiting;

        if (!displayAbortRecruiting) {
            return null;
        }

        return <>
            <PaperRow>
                <CustomCheckboxInput
                    checked={recruitingAbort}
                    onChange={() => setRecruitingAbort(!recruitingAbort)}
                    label={<b>Prozess abbrechen</b>}
                />
            </PaperRow>
            {recruitingAbort &&
            <>
                <h4 style={{margin: 5}}>{translate('pages.recruiting.abortInformation')}</h4>
                <PaperRow>
                    <CustomDatePicker
                        label={translate('pages.recruiting.dateOfAbort')}
                        value={dateOfAbort}
                        disableFuture={true}
                        required
                        onChange={(value) => setDateOfAbort(value)}
                        error={Boolean(validationErrors.dateOfAbort)}
                        helperText={validationErrors.dateOfAbort}
                    />
                    <RecruitingAbortedByDropDown
                        value={abortedBy}
                        onChange={(event) => setAbortedBy(event.target.value)}
                        error={Boolean(validationErrors.abortedBy)}
                        helperText={validationErrors.abortedBy}
                    />
                </PaperRow>
                <PaperRow>
                    <RecruitingDeclineReasonDropDown
                        onChange={(reason) => setDeclineReason(reason)}
                        abortedBy={abortedBy}
                        value={declineReason}
                        error={Boolean(validationErrors.declineReason)}
                        helperText={validationErrors.declineReason}
                    />
                    {Boolean(initialActivity && initialActivity.recruiting?.status !== ERecruitingStatus.STATUS_ABORT) &&
                    <CustomDatePicker
                        label={'Wiedervorlage am für Kandidat erstellen (optional)'}
                        flex
                        value={candidateFollowupDate}
                        onChange={(value) => setCandidateFollowupDate(value)}
                        error={Boolean(validationErrors.candidateFollowupDate)}
                        helperText={validationErrors.candidateFollowupDate}
                    />
                    }
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        multiline
                        required
                        label={translate('pages.recruiting.abortComment')}
                        value={abortComment}
                        onChange={(value) => setAbortComment(value)}
                        error={Boolean(validationErrors.abortComment)}
                        helperText={validationErrors.abortComment}
                        rows={7}
                    />
                </PaperRow>
            </>
            }
        </>;
    }

    return <>
        <div>
            <PaperRow>
                <CustomDateTimePicker
                    label={translate('pages.activity.properties.dateTime')}
                    value={dateTime}
                    onChange={(value) => setDateTime(value)}
                />
                <ActivityTypeDropDown
                    value={type}
                    disabled={initialActivity && (initialActivity.type === EActivityType.systemInfo || initialActivity.type === EActivityType.systemMail)}
                    disableSystemInfo={true}
                    onChange={(event) => setType(event.target.value)}
                    error={Boolean(validationErrors.type)}
                    helperText={validationErrors.type}
                />
                <LegacyEmployeeAutocompleteDropDown
                    value={employee}
                    onChange={(event, value) => setEmployee(value)}
                    error={Boolean(validationErrors.employeeId)}
                    helperText={validationErrors.employeeId}
                />
                {
                    displayContactDropdown && (
                        <CompanyContactAutocompleteDropDown
                            value={companyContact}
                            onChange={(event, value) => setCompanyContact(value)}
                            onAdd={() => {
                                if (companyId) {
                                    dispatch(setUiCompanyContactAddDataAction({
                                        companyId: companyId
                                    }));
                                }
                            }}
                            companyId={companyId}
                        />
                    )
                }
            </PaperRow>
            {initialActivity?.type === EActivityType.systemInfo && (
                <PaperRow>
                    <CustomTextField
                        disabled={true}
                        label={translate('misc.action')}
                        value={actionString}
                    />
                </PaperRow>
            )}
            <PaperRow>
                <CustomTextField
                    multiline
                    rows={10}
                    label={translate('pages.activity.properties.userInput')}
                    value={userInput}
                    onChange={(value) => setUserInput(value)}
                    error={Boolean(validationErrors.userInput)}
                    helperText={validationErrors.userInput}
                />
            </PaperRow>
            {getAbortForm()}
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    void: () => {dispatch(setUiActivityToDeleteAction(initialActivity?.id))},
                    hidden: Boolean(!initialActivity),
                    label: translate('misc.buttons.delete')
                }, {
                    void: save,
                    label: translate('misc.buttons.saveAndClose')
                }]}
            />
        </div>
    </>;
}

//@ts-ignore
export const FunctionalIFrameComponent = ({children, ...props}) => {
    const [contentRef, setContentRef] = useState(null)
    //@ts-ignore
    const mountNode = contentRef?.contentWindow?.document?.body

    return (
        //@ts-ignore
        <iframe {...props} ref={setContentRef} style={{flex:1, height: '500px', border: 'none', background: 'white'}}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}


export default ActivityForm;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setWebVacancyEditViewActiveTabAction } from '../../../redux/webVacancyPage/webVacancyPage.actions';
import { translate } from '../../../translation/translate.utils';
import {
    getActivityListForWebVacancyAction,
    getCandidateListOfWebVacancySearchAction,
    getWebVacancyAction,
} from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';
import { getWebVacancyPageActivitiesRequestObject } from '../../../selectors/activity.selectors';
import { getWebVacancyCandidateSearchListRequestObject } from '../../../selectors/candidate.selectors';
import { EWebVacancyEditViewTabs } from '../../../definitions/webVacancyPage.definitions';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { themeRed } from '../../../theme/theme';
import WebVacancyDataPanel from './WebVacancyDataPanel';
import WebVacancyActivityPanel from './WebVacancyActivityPanel';
import CustomTabContainer from '../../../components/CustomTabContainer/CustomTabContainer';
import { ITab } from '../../../definitions/components.definitions';

interface IProps {
    id: number;
}

const WebVacancyView: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const webVacancyId = props.id;
    const webVacancy = useSelector((store: IStore) => store.entities.webVacancy.byId[webVacancyId]);
    const activitySorting = useSelector((store: IStore) => store.webVacancyPage.activitySorting);
    const activityFastSearch = useSelector((store: IStore) => store.webVacancyPage.activityFastSearch);
    const activityRequestObject = useSelector(getWebVacancyPageActivitiesRequestObject);
    const candidateSearchRequestObject = useSelector(getWebVacancyCandidateSearchListRequestObject);
    const candidateSearchFilter = useSelector((store: IStore) => store.webVacancyPage.filterCandidateList);
    const candidateSearchSorting = useSelector((store: IStore) => store.webVacancyPage.sortingCandidateList);
    const activeTab = useSelector((store: IStore) => store.webVacancyPage.activeTabWebVacancyEditView);

    const dispatch = useDispatch();

    const loadActivities = () => {
        dispatch(getActivityListForWebVacancyAction(token, webVacancyId, activityRequestObject));
    }

    useEffect(() => {
        if (webVacancyId > 0 && activeTab === EWebVacancyEditViewTabs.activityList) {
            loadActivities();
        }
    }, [activitySorting, activeTab, activityFastSearch, webVacancyId])

    useEffect(() => {
        if (webVacancyId > 0) {
            dispatch(getWebVacancyAction(token, webVacancyId))
        }
    }, [webVacancyId]);

    useEffect(() => {
        if (webVacancyId > 0 && activeTab === EWebVacancyEditViewTabs.candidateSearch) {
            dispatch(getCandidateListOfWebVacancySearchAction(
                token,
                candidateSearchRequestObject
            ));
        }
    }, [dispatch, activeTab, candidateSearchFilter, candidateSearchSorting]);

    if (webVacancyId > 0 && (webVacancy === undefined || webVacancyId !== webVacancy.id)) {
        return <>
            <CustomBackdropLoadingInsideDiv open />
        </>;
    }

    const tabs: ITab[] = [{
        label: translate('misc.dataTabTitle'),
        component: <div className={"padding5"}>
            <WebVacancyDataPanel webVacancyId={webVacancyId} />
        </div> ,
    }, {
        label: translate('misc.activities'),
        component: <WebVacancyActivityPanel webVacancyId={webVacancyId}/>,
    }];

    return <>
        <div className={'flexContainerColumn flex padding5'} style={{width: 900}}>
            <CustomTabContainer
                roundBorder
                onTabChange={(tab) => dispatch(setWebVacancyEditViewActiveTabAction(tab))}
                color={themeRed.palette.primary.main}
                tabs={tabs}
            />
        </div>
    </>;
};

export default WebVacancyView;

import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import CustomDialogHeader from '../../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../../../components/CustomDialog/CustomDialogBody';
import CustomTypography from '../../../../components/CustomTypography/CustomTypography';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import { translate } from '../../../../translation/translate.utils';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';

interface IProps {
    newResponsibleName?: string;
    onClose: () => void;
    onSave: (responsibleChangedComment: string) => void;
    open: boolean
}

const SuggestionChangeResponsibleView: React.FC<IProps> = (props) => {
    const [comment, setComment] = useState('');

    const onSave = () => {
        props.onClose();
        props.onSave(comment);
    };

    return (
        <Dialog open={props.open} maxWidth={"sm"} fullWidth onClose={props.onClose} style={{zIndex: 9999 }}>
            <CustomDialogHeader string={translate('pages.recruiting.changeSuggestionResponsibleDialog.title')} onClose={props.onClose} />
            <CustomDialogBody>
                <CustomTypography value={translate('pages.recruiting.changeSuggestionResponsibleDialog.body', [
                    props.newResponsibleName || ''
                ])} />
                <PaperRow>
                    <CustomTextField
                        label={translate('pages.activity.properties.userInput')}
                        value={comment}
                        multiline={true}
                        onChange={(value) => setComment(value)}
                        rows={6}/>
                </PaperRow>
            </CustomDialogBody>
            <CustomDialogActions
                buttons={[{
                    void: onSave,
                    label: translate('pages.recruiting.changeSuggestionResponsibleDialog.save')
                }, {
                    void: props.onClose,
                    label: translate('pages.recruiting.changeSuggestionResponsibleDialog.abort')
                }]}
            />
        </Dialog>
    );
};

export default SuggestionChangeResponsibleView;

import React, { useState } from 'react';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import { translate } from '../../../../translation/translate.utils';
import { DropzoneDialogBase, FileObject } from 'material-ui-dropzone';
import IconButton from '@material-ui/core/IconButton';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import { useDispatch, useSelector } from 'react-redux';
import { addRecruitingDocumentAction } from '../../../../redux/entities/entities.actions';
import { ERecruitingDocumentType, IRecruitingDocumentForApi } from '../../../../definitions/entities.definition';
import { getToken } from '../../../../selectors/app.selectors';
import RecruitingDocumentTypeDropDown
    from '../../../../components/CustomInput/CustomDropDownInput/RecruitingDocumentTypeDropDown';
import { Button } from '@material-ui/core';

interface IProps {
    recruitingId: number;
}

const RecruitingDocumentUpload: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const recruitingId = props.recruitingId;

    const [open, setOpen] = React.useState(false);
    const [fileObjects, setFileObjects] = React.useState([]);

    const [type, setType] = useState(ERecruitingDocumentType.CONTRACT);

    const onSave = () => {
        const documents: IRecruitingDocumentForApi[] = [];

        fileObjects.forEach((fileObject: FileObject) => {
            documents.push({
                fileContent: fileObject.data,
                type,
                displayFileName: fileObject.file.name,
                recruitingId,
            });
        });

        dispatch(addRecruitingDocumentAction(token, documents));
        setOpen(false);
        setFileObjects([]);
        return;
    };

    const dialogTitle = () => (
        <>
            <span>Upload file</span>
            <IconButton style={{ right: "12px", top: "8px", position: "absolute" }} onClick={() => setOpen(false)}>
                {IconFactory.getIconComponent(EIcons.Close)}
            </IconButton>
            <div>
                <PaperRow>
                    <RecruitingDocumentTypeDropDown value={type} onChange={(event) => setType(event.target.value)} />
                </PaperRow>
            </div>
        </>
    );

    return (
        <>
            <div className={'flexAutoGrow alignVerticalCenter padding5 gap10'}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(true)}
                    startIcon={IconFactory.getIconComponent(EIcons.Add)}
                >
                    {translate('pages.companyDocument.add')}
                </Button>
            </div>
            <DropzoneDialogBase
                fullWidth={true}
                maxWidth={"lg"}
                dialogTitle={dialogTitle()}
                acceptedFiles={["image/*", "application/pdf", "application/word"]}
                fileObjects={fileObjects}
                cancelButtonText={translate('misc.buttons.close')}
                submitButtonText={translate('misc.buttons.save')}
                previewChipProps={{ color: "primary" }}
                maxFileSize={25000000}
                filesLimit={10}
                open={open}
                onAdd={(newFileObjs) => {
                    // @ts-ignore-next-line
                    setFileObjects([].concat(fileObjects, newFileObjs));
                }}
                onDelete={(deleteFileObj) => {
                    setFileObjects(fileObjects.filter((fileObject: FileObject) => fileObject.file !== deleteFileObj.file));
                }}
                onClose={() => setOpen(false)}
                onSave={() => onSave()}
                useChipsForPreview={true}
                showPreviews={true}
                showFileNamesInPreview={true}
            />
        </>
    );
};

export default RecruitingDocumentUpload;

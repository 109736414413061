import {
    IInitPinnedViewsAction,
    IRemovePinnedViewAction,
    ISetActivityAddDataAction,
    ISetActivityToDeleteAction,
    ISetActivityToEditAction,
    ISetClickedRowAction,
    ISetCloseCurrentEditViewAction,
    ISetCloseViewAction,
    ISetCompanyContactAddDataAction,
    ISetCompanyLocationAddDataAction,
    ISetCompanyLocationToEditAction,
    ISetEditViewAction,
    ISetEntityToCreateDialogAction,
    ISetExpandCollapseApplicationListItemsAction,
    ISetExpandCollapseProcessListItemsAction,
    ISetExpandCollapseRecruitingListItemsAction,
    ISetExpandCollapseSuggestionListItemsAction,
    ISetFilterPanelClosed,
    ISetFilterPanelOpen,
    ISetForceExitFastEditAction,
    ISetPinViewAction,
    ISetRenewPasswordAction,
    ISetRightDrawerCloseAction,
    ISetRightDrawerOpenAction,
    ISetSearchCandidateAction,
    ISetUIActivityListViewFilterAction,
    ISetUIActivityListViewFilterMenuAction,
    ISetUIActivityListViewSortingOptionAction,
    ISetUICloseActivityListViewAction,
    ISetUiCongratulationViewOpenAction,
    ISetUiCurrentRouteAction,
    ISetUiEditCellAction,
    ISetUiEncouragementViewOpenAction,
    ISetUiLogoutDialogOpenAction,
    ISetUiLogOutExpireDialogAction,
    IToggleTopDrawerAction,
} from '../../definitions/redux/actions.definition';
import { ERoutes } from '../../definitions/app.definitions';
import { EEntities } from '../../definitions/entities.definition';
import {
    EEntityView,
    ERightDrawerViews,
    IActivityAddData,
    ICompanyContactAddData,
    ICompanyLocationAddData,
    IEditViewState,
} from '../../definitions/ui.definitions';
import { ESortingOptions } from '../../definitions/components.definitions';
import { IActivityFilter, IActivityFilterMenu } from '../../definitions/activityPage.definitions';

export const UI_INITIAL_LOADED = 'UI_INITIAL_LOADED';
export const UI_SET_FORCE_EXIT_FAST_EDIT = 'UI_SET_FORCE_EXIT_FAST_EDIT';
export const UI_SET_RIGHT_DRAWER_VIEW_OPEN = 'UI_SET_RIGHT_DRAWER_VIEW_OPEN';
export const UI_CLOSE_RIGHT_DRAWER_VIEW = 'UI_CLOSE_RIGHT_DRAWER_VIEW';
export const UI_SET_COMPANY_PAGE_NEW_COMPANY_DIALOG = 'UI_SET_COMPANY_PAGE_NEW_COMPANY_DIALOG';
export const UI_SET_CURRENT_ROUTE = 'UI_SET_CURRENT_ROUTE';
export const UI_SET_LOGOUT_DIALOG_OPEN = 'UI_SET_LOGOUT_DIALOG_OPEN';
export const UI_SET_LOGOUT_EXPIRE_DIALOG = 'UI_SET_LOGOUT_EXPIRE_DIALOG';
export const UI_SET_ENTITY_TO_CREATE_DIALOG = 'UI_SET_ENTITY_TO_CREATE_DIALOG';
export const UI_SET_CLICKED_ROW = 'UI_SET_CLICKED_ROW';
export const UI_SET_RENEW_PASSWORD = 'UI_SET_RENEW_PASSWORD';
export const UI_SET_ACTIVITY_ADD_DATA = 'UI_SET_ACTIVITY_ADD_DATA';
export const UI_SET_ACTIVITY_TO_EDIT = 'UI_SET_ACTIVITY_TO_EDIT';
export const UI_SET_COMPANY_CONTACT_ADD_DATA = 'UI_SET_COMPANY_CONTACT_ADD_DATA';
export const UI_SET_COMPANY_LOCATION_ADD_DATA = 'UI_SET_COMPANY_LOCATION_ADD_DATA';
export const UI_SET_COMPANY_LOCATION_TO_EDIT = 'UI_SET_COMPANY_LOCATION_TO_EDIT';
export const UI_SET_ACTIVITY_TO_DELETE = 'UI_SET_ACTIVITY_TO_DELETE';
export const UI_SET_CONGRATULATION_VIEW_OPEN = 'UI_SET_CONGRATULATION_VIEW_OPEN';
export const UI_SET_ENCOURAGEMENT_VIEW_OPEN = 'UI_SET_ENCOURAGEMENT_VIEW_OPEN';
export const UI_SET_EDIT_CELL = 'UI_SET_EDIT_CELL';
export const UI_SET_ACTIVITY_LIST_VIEW_OPEN = "UI_SET_ACTIVITY_LIST_VIEW_OPEN";
export const UI_SET_ACTIVITY_LIST_VIEW_FILTER = "UI_SET_ACTIVITY_LIST_VIEW_FILTER";
export const UI_SET_ACTIVITY_LIST_VIEW_FILTER_MENU = "UI_SET_ACTIVITY_LIST_VIEW_FILTER_MENU";
export const UI_SET_ACTIVITY_LIST_RESET_FILTER = "UI_SET_ACTIVITY_LIST_RESET_FILTER";
export const UI_CLOSE_ACTIVITY_LIST_VIEW = "UI_CLOSE_ACTIVITY_LIST_VIEW";
export const UI_SET_ACTIVITY_LIST_VIEW_SORTING_OPTION = "UI_SET_ACTIVITY_LIST_VIEW_SORTING_OPTION";
export const UI_SET_FILTER_PANEL_OPEN = "UI_SET_FILTER_PANEL_OPEN";
export const UI_SET_FILTER_PANEL_CLOSED = "UI_SET_FILTER_PANEL_OPENUI_SET_FILTER_PANEL_CLOSED";
export const UI_SET_EDIT_VIEW = "UI_SET_EDIT_VIEW";
export const UI_SET_PIN_VIEW = "UI_SET_PIN_VIEW";
export const UI_INIT_PINNED_VIEWS = "UI_INIT_PINNED_VIEWS";
export const UI_SET_CLOSE_VIEW= "UI_SET_CLOSE_VIEW";
export const UI_SET_CLOSE_CURRENT_EDIT_VIEW= "UI_SET_CLOSE_CURRENT_EDIT_VIEW";
export const UI_REMOVE_PINNED_VIEW= "UI_REMOVE_PIN_VIEW";
export const UI_TOGGLE_TOP_DRAWER = "UI_TOGGLE_TOP_DRAWER";
export const UI_CLOSE_TOP_DRAWER = "UI_CLOSE_TOP_DRAWER";
export const UI_SET_SEARCH_CANDIDATE_VIEW = "UI_SET_SEARCH_CANDIDATE_VIEW";
export const UI_SET_SEARCH_VACANCY_VIEW = "UI_SET_SEARCH_VACANCY_VIEW";
export const UI_SET_EXPAND_COLLAPSE_SUGGESTION_LIST_ITEMS = "UI_SET_EXPAND_COLLAPSE_SUGGESTION_LIST_ITEMS";
export const UI_SET_EXPAND_COLLAPSE_RECRUITING_LIST_ITEMS = "UI_SET_EXPAND_COLLAPSE_RECRUITING_LIST_ITEMS";
export const UI_SET_EXPAND_COLLAPSE_APPLICATION_LIST_ITEMS = "UI_SET_EXPAND_COLLAPSE_APPLICATION_LIST_ITEMS";
export const UI_SET_EXPAND_COLLAPSE_PROCESS_LIST_ITEMS = "UI_SET_EXPAND_COLLAPSE_PROCESS_LIST_ITEMS";
export const UI_SET_SHOW_ONLY_MINE = "UI_SET_SHOW_ONLY_MINE";
export const UI_SET_SPLIT_CANDIDATE_VIEW = "UI_SET_SPLIT_CANDIDATE_VIEW";

export const setUiSplitCandidateView = (split: boolean) => ({
    type: UI_SET_SPLIT_CANDIDATE_VIEW,
    payload: split
});

export const setUiShowOnlyMine = (showOnlyMine: boolean) => ({
    type: UI_SET_SHOW_ONLY_MINE,
    payload: showOnlyMine
});

export const setUiExpandCollapseSuggestionListItem = (toggleOrExpand: 'collapse'|'expand'): ISetExpandCollapseSuggestionListItemsAction => ({
    type: UI_SET_EXPAND_COLLAPSE_SUGGESTION_LIST_ITEMS,
    payload: toggleOrExpand
});

export const setUiExpandCollapseRecruitingListItem = (toggleOrExpand: 'collapse'|'expand'): ISetExpandCollapseRecruitingListItemsAction => ({
    type: UI_SET_EXPAND_COLLAPSE_RECRUITING_LIST_ITEMS,
    payload: toggleOrExpand
});

export const setUiExpandCollapseProcessListItem = (toggleOrExpand: 'collapse'|'expand'): ISetExpandCollapseProcessListItemsAction => ({
    type: UI_SET_EXPAND_COLLAPSE_PROCESS_LIST_ITEMS,
    payload: toggleOrExpand
});

export const setUiExpandCollapseApplicationListItem = (toggleOrExpand: 'collapse'|'expand'): ISetExpandCollapseApplicationListItemsAction => ({
    type: UI_SET_EXPAND_COLLAPSE_APPLICATION_LIST_ITEMS,
    payload: toggleOrExpand
});

export const setUiSearchCandidateView = (vacancyId?: number, webVacancyId?: number): ISetSearchCandidateAction => ({
    type: UI_SET_SEARCH_CANDIDATE_VIEW,
    payload: {
        vacancyId,
        webVacancyId
    }
});

export const setUiSearchVacancyView = (candidateId: number) => ({
    type: UI_SET_SEARCH_VACANCY_VIEW,
    payload: candidateId
});

export const setUiCloseTopDrawer = (): IToggleTopDrawerAction => ({
    type: UI_CLOSE_TOP_DRAWER
});

export const setUiToggleTopDrawer = (): IToggleTopDrawerAction => ({
    type: UI_TOGGLE_TOP_DRAWER
});

export const setUiEditView = (view: EEntityView, entityId: number, initialTab?: number): ISetEditViewAction => ({
    type: UI_SET_EDIT_VIEW,
    payload: {
        view,
        entityId,
        initialTab
    }
});

export const setUiCloseCurrentEditView = (): ISetCloseCurrentEditViewAction => ({
    type: UI_SET_CLOSE_CURRENT_EDIT_VIEW,
});

export const setUiRemovePinnedView = (view: EEntityView, entityId: number): IRemovePinnedViewAction => ({
    type: UI_REMOVE_PINNED_VIEW,
    payload: {
        view,
        entityId
    }
});

export const setUiCloseView = (view: EEntityView, entityId: number): ISetCloseViewAction => ({
    type: UI_SET_CLOSE_VIEW,
    payload: {
        view,
        entityId
    }
});

export const initPinnedViews = (views: IEditViewState[]): IInitPinnedViewsAction => ({
    type: UI_INIT_PINNED_VIEWS,
    payload: views
});

export const setUiPinView = (view: EEntityView, entityId: number, title: string): ISetPinViewAction => ({
    type: UI_SET_PIN_VIEW,
    payload: {
        view,
        entityId,
        title
    }
});

export const setUiFilterPanelOpen = (entity: EEntities): ISetFilterPanelOpen => ({
    type: UI_SET_FILTER_PANEL_OPEN,
    payload: entity,
});

export const setUiFilterPanelClosed = (entity: EEntities): ISetFilterPanelClosed => ({
    type: UI_SET_FILTER_PANEL_CLOSED,
    payload: entity,
});

export const setUIActivityListViewFilterAction = (filter: IActivityFilter): ISetUIActivityListViewFilterAction => ({
    type: UI_SET_ACTIVITY_LIST_VIEW_FILTER,
    payload: filter,
});

export const setUIActivityListViewOpenAction = (filter: IActivityFilter): ISetUIActivityListViewFilterAction => ({
    type: UI_SET_ACTIVITY_LIST_VIEW_OPEN,
    payload: filter,
});

export const setUIActivityListViewFilterMenuAction = (filterMenu: IActivityFilterMenu): ISetUIActivityListViewFilterMenuAction => ({
    type: UI_SET_ACTIVITY_LIST_VIEW_FILTER_MENU,
    payload: filterMenu,
});

export const setUIActivityListViewResetFilterAction = () => ({
    type: UI_SET_ACTIVITY_LIST_RESET_FILTER
})

export const setUICloseSuggestionListViewAction = (): ISetUICloseActivityListViewAction => ({
    type: UI_CLOSE_ACTIVITY_LIST_VIEW,
});

export const setUIActivityListViewSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetUIActivityListViewSortingOptionAction => ({
    type: UI_SET_ACTIVITY_LIST_VIEW_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setUiEditCell = (cellId?: string): ISetUiEditCellAction => ({
    type: UI_SET_EDIT_CELL,
    payload: cellId,
});

export const setUiActivityAddDataAction = (addData: IActivityAddData): ISetActivityAddDataAction => ({
    type: UI_SET_ACTIVITY_ADD_DATA,
    payload: addData
});
export const setUiActivityToDeleteAction = (activityId: number): ISetActivityToDeleteAction => ({
    type: UI_SET_ACTIVITY_TO_DELETE,
    payload: activityId
});

export const setUiActivityToEditAction = (activityId: number): ISetActivityToEditAction => ({
    type: UI_SET_ACTIVITY_TO_EDIT,
    payload: activityId
});

export const setUiCompanyContactAddDataAction = (addData: ICompanyContactAddData): ISetCompanyContactAddDataAction => ({
    type: UI_SET_COMPANY_CONTACT_ADD_DATA,
    payload: addData
});

export const setUiCompanyLocationAddDataAction = (addData: ICompanyLocationAddData): ISetCompanyLocationAddDataAction => ({
    type: UI_SET_COMPANY_LOCATION_ADD_DATA,
    payload: addData
});

export const setUiCompanyLocationToEditAction = (companyContactId: number): ISetCompanyLocationToEditAction => ({
    type: UI_SET_COMPANY_LOCATION_TO_EDIT,
    payload: companyContactId
});

export const setForceExitFastEditAction = (exit: boolean): ISetForceExitFastEditAction => ({
    type: UI_SET_FORCE_EXIT_FAST_EDIT,
    payload: exit,
});

export const setUiRightDrawerOpenAction = (view: ERightDrawerViews): ISetRightDrawerOpenAction => ({
    type: UI_SET_RIGHT_DRAWER_VIEW_OPEN,
    payload: view,
});

export const closeUiRightDrawerAction = (view: ERightDrawerViews): ISetRightDrawerCloseAction => ({
    type: UI_CLOSE_RIGHT_DRAWER_VIEW,
    payload: view,
});

export const setUiCurrentRouteAction = (route: ERoutes): ISetUiCurrentRouteAction => ({
    type: UI_SET_CURRENT_ROUTE,
    payload: route,
});

export const setUiLogOutDialogOpenAction = (open: boolean): ISetUiLogoutDialogOpenAction => ({
    type: UI_SET_LOGOUT_DIALOG_OPEN,
    payload: open,
});

export const setUiCongratulationViewOpenAction = (open: boolean): ISetUiCongratulationViewOpenAction => ({
    type: UI_SET_CONGRATULATION_VIEW_OPEN,
    payload: open,
});

export const setUiEncouragementOpenAction = (open: boolean): ISetUiEncouragementViewOpenAction => ({
    type: UI_SET_ENCOURAGEMENT_VIEW_OPEN,
    payload: open,
});

export const setUiLogOutExpireDialog = (): ISetUiLogOutExpireDialogAction => ({
    type: UI_SET_LOGOUT_EXPIRE_DIALOG,
});

export const setCreateNewEntityDialogAction = (entityToCreate: undefined | EEntities): ISetEntityToCreateDialogAction => ({
    type: UI_SET_ENTITY_TO_CREATE_DIALOG,
    payload: entityToCreate,
});

export const setCustomTableClickedRow = (index: number, entity: EEntities): ISetClickedRowAction => ({
    type: UI_SET_CLICKED_ROW,
    payload: {
        entity,
        index
    },
});

export const setRenewPassword = (): ISetRenewPasswordAction => ({
    type: UI_SET_RENEW_PASSWORD,
});

import { useEffect, useState } from 'react';
import { getRecruitingsForCompanyContactAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import {
    getCompanyPageRequestObjectForRecruitingsInContactEditView,
    getRecruitingsForCompanyContact,
} from '../../../../../selectors/recruiting.selectors';

export const useCompanyContactRecruitingListHook = (companyContactId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.companyContactRecruitingsLoading);

    const [initialLoading, setInitialLoading] = useState(true);
    const requestObject = useSelector(getCompanyPageRequestObjectForRecruitingsInContactEditView);
    const forceReload = useSelector((store: IStore) => store.companyPage.reloadRecruitingsOfCompany);
    const filter = useSelector((store: IStore) => store.companyPage.filterContactRecruitingList);
    const recruitings = useSelector((store: IStore) => getRecruitingsForCompanyContact(store, companyContactId));

    useEffect(() => {
        if (!loading) {
            dispatch(getRecruitingsForCompanyContactAction(token, companyContactId, requestObject));
            setInitialLoading(false);
        }
    }, [forceReload]);

    useEffect(() => {
        if (!loading && !initialLoading) {
            dispatch(getRecruitingsForCompanyContactAction(token, companyContactId, requestObject));
        }
    }, [JSON.stringify(filter)]);

    return {loading, recruitings, total: recruitings.length};
}

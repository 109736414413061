import React from 'react';
import { IProcessAggregation } from '../../../definitions/entities.definition';
import { groupProcessesByStatus } from '../../../selectors/process.selectors';
import CustomTableFooter from '../../../components/CustomTable/CustomTableFooter';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import RenderProcessListGroup from './RenderProcessListGroup';

interface IProps {
    total: number;
    processes: IProcessAggregation[];
    loading: boolean;
    onReload: () => void;
}

const ProcessList: React.FC<IProps> = (props) => {
    const total = props.total;
    const processes = props.processes;
    const processGroups = groupProcessesByStatus(processes);
    const loading = props.loading;

    return <>
        <div className={"flexColumn flex1"}>
            <div className={"overflow flex1 marginTop10 flexContainerColumn gap5"}>
                <CustomBackdropLoadingInsideDiv open={loading} />
                {total === 0 &&
                    <>Keine Daten vorhanden</>
                }
                {Object.keys(processGroups).map((status) => {
                    const process = processGroups[status];
                    return <>
                        <RenderProcessListGroup
                            key={status}
                            status={status}
                            process={process}
                        />
                    </>
                })}
            </div>
            <CustomTableFooter
                style={{height: 35}}
                text={'Vorgänge: ' + total}
                onReload={props.onReload}
            />
        </div>
    </>
}

export default React.memo(ProcessList);

import React from 'react';
import { createStyles } from '@material-ui/core/styles';
import { AppBar, makeStyles, Toolbar, Typography } from '@material-ui/core';
import AccountMenuItem from '../../Pages/App/TopBar/MenuItem/AccountMenuItem';
import RecruitingPageTopBar from '../RecruitingPage/components/RecruitingPageTopBar';

export const useAppBarStyles = makeStyles((theme) =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.background.paper
        },
        toolbar: {
            paddingRight: '10px !important'
        },
        root: {
            justifyContent: "space-between",
            flex: 1
        },
    })
);

const TopBar: React.FC = () => {
    const styles = useAppBarStyles();

    return <>
        <AppBar id={"HeaderBar"} position="fixed" className={styles.appBar}>
            <Toolbar className={styles.toolbar}>
                <div className={styles.root + " flexContainerRow"}>
                    <div className={"flexContainerRow alignVerticalCenter flex1"}>
                        <Typography style={{ color: 'var(--brainz-color-app-bar-heading)' }} variant="h6" noWrap>
                            Prozessübersicht
                        </Typography>
                    </div>
                    <RecruitingPageTopBar />
                    <div className={'flexContainerRow alignVerticalCenter flex1 alignHorizontalRight'}>
                        <AccountMenuItem/>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    </>;

};

export default React.memo(TopBar);

import React from 'react';
import { ESuggestionProgressStatus } from '../../definitions/entities.definition';
import { Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../Icons/IconFactory';

interface IProps {
    status: ESuggestionProgressStatus;
    showAsTooltip?: boolean;
}

const ProgressStatusCellRenderer: React.FC<IProps> = (props) => {
    const showAsTooltip = props.showAsTooltip || false;
    let color = '';
    let title = '';
    switch (props.status) {
        case ESuggestionProgressStatus.GRAY:
            title = 'Kontakt-Versuch ausstehend';
            color = 'gray';
            break;
        case ESuggestionProgressStatus.GREEN:
            title = 'Call hat stattgefunden';
            color = 'green';
            break;
        case ESuggestionProgressStatus.YELLOW:
            title = 'Im Austausch / Call-Koordinierung';
            color = 'yellow';
            break;
        case ESuggestionProgressStatus.RED:
            title = 'Kontakt-Versuch gestartet';
            color = 'red';
            break;
    }

    if (showAsTooltip) {
        return <>
            <Tooltip placement={"right"} title={title}>
                <div className={"flexContainerRow alignVerticalCenter"}>{IconFactory.getIconComponent(EIcons.Dot, {color})}</div>
            </Tooltip>
        </>;
    }

    return <div className={"flexContainerRow alignVerticalCenter"}>
        {IconFactory.getIconComponent(EIcons.Dot, {color})} {title}
    </div>

};

export default ProgressStatusCellRenderer;

import React from 'react';
import { EEntities } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { EFilterPanelType } from '../../../../definitions/filter.definitions';
import { useDispatch, useSelector } from 'react-redux';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { setWebVacancyPageFastSearchValueAction } from '../../../../redux/webVacancyPage/webVacancyPage.actions';
import { EIcons } from '../../../../components/Icons/IconFactory';

const FastSearchPanelWebVacancyPage: React.FC = () => {
    const dispatch = useDispatch();
    const fastSearchWebVacancy = useSelector((store: IStore) => store.webVacancyPage.filter.fastSearch);

    const configuration: IAppBarConfig = {
        entity: EEntities.WebVacancy,
        value: fastSearchWebVacancy ?? '',
        emptyValue: translate('pages.webVacancy.fastSearchEmptyText'),
        fastSearchCallback: (value: string) => dispatch(setWebVacancyPageFastSearchValueAction(value)),
        filterPanelType: EFilterPanelType.sidePanel,
        actionButtons: [{
            action: () => window.open('http://static.92.183.75.5.clients.your-server.de/monitoring'),
            icon: EIcons.Policy,
            tooltip: "Monitoring öffnen"
        }]
    };

    return <>
        <CustomFastSearchPanel appBarConfig={configuration}/>
    </>
}

export default FastSearchPanelWebVacancyPage;
